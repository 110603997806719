import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCashierGrowth = () => {
  const [transTotal, setTransTotal] = useState(0);
  const { transactions } = useSelector((state) => state.cashierTransaction);
  const [lineData, setLineData] = useState({ labels: [], data: [] });
  const [customers_, setCustomers_] = useState({});

  const chartData = {
    labels: lineData.labels,
    data: lineData.data,
  };

  const graphData = lineData?.labels?.map((x, i) => ({ month: lineData.labels[i], value: lineData.data[i] }));

  useEffect(() => {
    let total = transactions?.data?.filter((tnx) => tnx.paymentstatus !== 'pending').reduce((cumm, curr) => (cumm += curr.accountbalance), 0);
    setTransTotal(total);

    if (transactions?.data && transactions?.data.length) {
      setCustomers_(
        transactions?.data
          .map((c) => {
            return {
              ...c,
              date: dayjs(c.createdon).format('MMM'),
            };
          })
          .reverse()
          .reduce((cumm, curr) => {
            cumm[curr.date] ? (cumm[curr.date] = cumm[curr.date] + 1) : (cumm[curr.date] = 1);
            return cumm;
          }, {}),
      );

      let lineDataArr = Object.values(customers_);
      let lineLabelsArr = Object.keys(customers_);

      setLineData({
        labels: lineLabelsArr.length > 1 ? lineLabelsArr : [dayjs(transactions?.data[0].createdon).subtract(1, 'month').format('MMM'), ...lineLabelsArr],
        data: lineDataArr.length > lineDataArr ? lineDataArr : [0, ...lineDataArr],
      });
      // uncomment this console.log, to see payload of each data
      // console.log({ customers_, lineDataArr, lineLabelsArr, chartData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions?.data]);

  return { customers: customers_, chartData, graphData, transTotal };
};
