import React, { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Named Export packages
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ListIcon from '../../assets/images/ListPlus.svg';

// default exports
import dayjs from 'dayjs';
import moment from 'moment';
import cameraIcon from '../../assets/images/camera.svg';
import defaultUser from '../../assets/images/defaultUser.png';
import emptyView from '../../assets/images/emptyView.svg';
import filter from '../../assets/images/filter.svg';
import infoCircle from '../../assets/images/infoCircle.svg';
import LogoutIcon from '../../assets/images/Logout.svg';
import MessageIcon from '../../assets/images/Message.svg';
import navigation from '../../assets/images/navigation.svg';
import ProfileImage from '../../assets/images/Profile.svg';
import { MERCHANT_MAPPING } from '../../constants/title';

// hooks
import { useNavigation } from '../../hooks/use_ navigation';
import useBillService, { useBillData, useCableData, useResetUtilUser, useUtiltiyData } from '../../hooks/use_bill';
import { useInput } from '../../hooks/use_input';
import { usePaginate } from '../../hooks/use_paginate';
import { useSetPin } from '../../hooks/use_set_pin';
import { useOutsideRef } from '../../hooks/useOutsideClick';

import useVerifySmartCardNumber, { useVerifyUtiliy } from '../../hooks/use_verify_smart_card';
import { DashboardHeader } from '../../pages/cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import { DeactivateCashpointPopup } from '../../pages/merchant/cashpoint';
import { InitialPinSetUp } from '../../pages/merchant/profile';
// import Settings from '../../pages/merchant/settings';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { CashpointInitiateChallengePopup, CashpointNewPopupDetails, VirtualAccountPopupDetails } from '../../pages/merchant/transaction';
import { AlignRight, GenericTabsPopup } from '../../pages/merchant/transfer';
import { ConfirmAirtimeOrData, ConfirmCableOrUtilty, PinPopup, SuccessPopup } from '../../pages/merchant/withdraw';
import { billSelector } from '../../redux/merchant/bill.features';
import { openQR } from '../../redux/merchant/merchant_actions';
import { utilitySelector } from '../../redux/merchant/utility.features';
import {
  bonusTransactionsService,
  buyAirtime,
  buyCable,
  buyData,
  buyUtility,
  fetchMemberPayouts,
  fetchMerchantCustomersCashpoints,
  fetchMerchantPayouts,
  getInboundTransactionsCount,
  getTransactionChallenge,
  getVirtualAccountInfo,
  printStatement,
  repushWebhook,
} from '../../services/apiSevices';
import colors from '../../utils/color/colors';
import { formatNumber } from '../../utils/formatNumber';
import { formatDay, formatYear, isDesktop, totalTransactionByDate } from '../../utils/helper';
import AppButton from '../app_button/app_button';
// default exported components
import CircleAvatar from '../circle_avatar/circle_avatar';
import { FormDropdown } from '../form_dropdown/form_dropdown';
import { FormInput } from '../form_input/form_input';
// named exports for components
import {
  resetCardPaymentCount,
  resetInboundTransfer,
  resetTotalTransferCount,
  setCardPaymentCount,
  setInboundPaymentCount,
  setTransferTotalCount,
} from '../../redux/merchant/virtual_account.features';
import { CashpointMobileTransactionItem, cashpointTotalTransactionByDate, MobileCashierTransactionTable, VirtualAccFailedWebhookItem } from '../cashier_widgets/cashier_widget';
import { CustomInputFile } from '../image_upload/image_upload';
import { Spacer } from '../layout/layout';
import LineChart from '../line_chart/line_chart';
import { generateChartData, getChartOptions } from '../line_chart/utils';
import { MobileDashboardNav } from '../navigation/navigation';
import { LogoutPopup } from '../popup/popup';
import TourWrapper from '../tour_wrapper/tour_wrapper';
import { useVirtualAccountPaginate } from './hooks/use_paginate';
import './merchant_widgets.css';

export const FallbackUI = () => {
  return (
    <div className="d-flex align-center no-mobile j-center full-width full-height">
      <LoadingOutlined style={{ fontSize: '1em' }} size="large" />
    </div>
  );
};

export function MerchantHeader({ BOTTOM_CARD_TEXT, links, navKeys, beforeNavWidget }) {
  const [show, setShow] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const { onNavMenuClick, navOpen, onBodyClick } = useNavigation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (location.pathname !== '/admin/merchant') {
      setShow(false);
    }
  }, [location.pathname]);

  const onLogoutClick = () => {
    setShowLogout(true);
  };

  return (
    <DashboardHeader titleMap={MERCHANT_MAPPING} backgroundColor="white">
      <div className="d-flex no-mobile j-center align-center p-relative">
        <Spacer width={10} />
        <TourWrapper
          title="Profile Settings"
          contentClass="tour-triangle-right"
          className="white-bg"
          content="Control your personal information, preferences, security and log out."
          index={1}
          style={{ right: '20px' }}
        >
          <UserIcon onClick={onClick} />
        </TourWrapper>
        <Spacer height={50} />
        {show && (
          <div style={{ padding: '20px   ', top: '60px', right: '0px', width: '200px', borderRadius: '5px' }} className="p-absolute primary-bg-color drop-shadow">
            <div className="d-flex align-center c-pointer">
              <img src={ProfileImage} alt="" />
              <Spacer width={20} />
              <button
                onClick={() => {
                  openQR(dispatch);
                  setShow(!show);
                  history.push('/admin/merchant');
                }}
                className="unstyle-button c-pointer fw400 font-size-17 text-color"
              >
                Bank Details
              </button>
            </div>
            <Spacer height={20} />
            <div
              onClick={() =>
                history.push({
                  pathname: '/admin/merchant/settings',
                  state: { tab: 'Support' },
                })
              }
              className="d-flex align-center c-pointer"
            >
              <img src={MessageIcon} alt="" />
              <Spacer width={20} />
              <span className="fw400 font-size-17 text-color">Support</span>
            </div>
            <Spacer height={20} />
            <div onClick={onLogoutClick} className="d-flex align-center c-pointer">
              <img src={LogoutIcon} alt="" />
              <Spacer width={20} />
              <span className="fw400 font-size-17 text-color">Log Out</span>
            </div>
          </div>
        )}
      </div>
      <div className="no-desktop-but-mobile">
        <button className="unstyle-button c-pointer" onClick={onNavMenuClick}>
          <img src={navigation} className="navigation-icon" alt="navigation button" />
        </button>
        {navOpen !== null && (
          <MobileDashboardNav
            onClick={onBodyClick}
            className={navOpen ? 'nav-open' : 'nav-close'}
            BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT}
            links={links}
            navKeys={navKeys}
            beforeNavWidget={beforeNavWidget}
          />
        )}
      </div>
      {showLogout && <LogoutPopup setShow={setShow} onClose={() => setShowLogout(false)} />}
    </DashboardHeader>
  );
}

export const UserIcon = ({ onClick }) => {
  return (
    <button onClick={onClick} className="unstyle-button c-pointer d-flex align-center">
      <CircleAvatar img={defaultUser} size={35} />
      <Spacer width={5} />
      <FontAwesomeIcon className="user-icon-angle fw200" icon={faAngleDown} />
    </button>
  );
};

export function Support() {
  const history = useHistory();
  return (
    <div className="d-flex j-end" style={{ padding: '0px 40px', position: 'fixed', bottom: '5px', right: '2px' }}>
      <div>
        <Spacer height={30} />
        <button
          onClick={() =>
            history.push({
              state: { tab: 'Support' },
              pathname: '/admin/merchant/settings',
            })
          }
          className="unstyle-button p-small c-pointer vpay-support-button"
        >
          <div className="d-flex align-center j-center">
            <img src={infoCircle} alt="informartion icon" />
            <Spacer width={5} />
            <span className="text-white">Support</span>
          </div>
        </button>
      </div>
    </div>
  );
}
export function RegistrationContainer({ children }) {
  return <div className="reg-container margin-auto">{children}</div>;
}

export function TwoRowText({ title, description }) {
  return (
    <div className="two-row-text">
      <p className="text-capitalize font-size-17 faded-text-color fw300">{title}</p>
      <Spacer height={5} />
      <p className="text-color font-size-18 half-width fw400">{description}</p>
    </div>
  );
}

export function CircleAdd() {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="14.2663" cy="14" rx="14.2663" ry="14" fill="#FF1C26" fillOpacity="0.05" />
      <path d="M14.2666 9.33203V18.6654" stroke="#FF1C26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.51074 14H19.0216" stroke="#FF1C26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export const InfoCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47779 4.47779 0 10 0C15.5229 0 20 4.47745 20 10C20 15.5225 15.5229 20 10 20C4.47779 20 0 15.5222 0 10ZM18.5 10C18.5 5.30586 14.6944 1.5 10 1.5C5.30621 1.5 1.5 5.30621 1.5 10C1.5 14.6938 5.30621 18.5 10 18.5C14.6944 18.5 18.5 14.6941 18.5 10ZM9.9951 5.4541C10.3748 5.4541 10.6886 5.73625 10.7383 6.10233L10.7451 6.2041V10.6231C10.7451 11.0373 10.4093 11.3731 9.9951 11.3731C9.6154 11.3731 9.30161 11.0909 9.25195 10.7249L9.2451 10.6231V6.2041C9.2451 5.78989 9.58089 5.4541 9.9951 5.4541ZM10.7551 13.7959C10.7551 13.3817 10.4193 13.0459 10.0051 13.0459L9.89333 13.0527C9.52725 13.1024 9.2451 13.4162 9.2451 13.7959C9.2451 14.2101 9.58089 14.5459 9.9951 14.5459L10.1069 14.5391C10.4729 14.4894 10.7551 14.1756 10.7551 13.7959Z"
      fill="#AE207C"
    />
  </svg>
);

export const DocumentTextInfo = ({ text }) => (
  <div className="d-flex align-center" style={{ backgroundColor: 'rgba(174, 32, 124, 0.06)', padding: '8px', borderRadius: '5px' }}>
    <InfoCircle />
    <Spacer width={10} />
    <p className="accent-color fw400 font-size-15">{text}</p>
  </div>
);

export const UploadDoneContainer = ({ DocumentName, timeStamp }) => (
  <div className="document-upload full-width d-flex align-center j-space-between">
    <div className="d-flex align-center">
      <svg width="49" height="40" viewBox="0 0 49 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.9235 0.0366526C31.7967 0.0125777 31.6662 0 31.5327 0C31.3993 0 31.2687 0.0125777 31.142 0.0366526H13.1724C6.02501 0.0366526 0 6.05159 0 13.3881V45.3939C0 53.1369 5.74955 59.2164 13.1724 59.2164H36.1264C43.183 59.2164 49 52.8312 49 45.3939V17.9995C49 17.4223 48.7848 16.8674 48.3994 16.4511L33.8419 0.723885C33.4355 0.284833 32.8741 0.0366526 32.2874 0.0366526H31.9235ZM29.3776 4.50381L13.1731 4.5062C8.37006 4.5062 4.31105 8.55842 4.31105 13.3863V45.3922C4.31105 50.6846 8.14943 54.7432 13.1731 54.7432H36.1271C40.747 54.7432 44.6904 50.4147 44.6904 45.3922L44.6896 20.8158L42.1703 20.8247C41.212 20.8235 40.1221 20.8214 38.9104 20.8186C33.8233 20.8074 29.6728 16.6736 29.3926 11.4687L29.3776 10.9071V4.50381ZM42.3304 16.3467L38.9198 16.3472C36.028 16.3409 33.6879 13.9076 33.6879 10.9071V7.0101L42.3304 16.3467Z"
          fill="#200E32"
          fillOpacity="0.5"
        />
      </svg>
      <Spacer width={10} />
      <div className="d-flex flex-vertical align-baseline">
        <p className="fw500 text-color font-size-15">{DocumentName ?? 'Successfully Uploaded'}</p>
        <Spacer height={5} />
        <p className="fw400 success-color font-size-13">{timeStamp ?? 'Added July 31st, 2021'}</p>
      </div>
    </div>
  </div>
);

export const ComplianceItemWrapper = ({ children, checker, DocumentName, timeStamp, onClick }) => {
  return <div>{checker === false || checker === undefined ? children : <UploadDoneContainer onClick={onClick} timeStamp={timeStamp} DocumentName={DocumentName} />}</div>;
};

export const BvnOrNinInput = ({ name, onChange, value, placeholder, onClick, loading, flag }) => {
  return (
    <div className="p-relative">
      <FormInput type="number" name={name} onChange={onChange} value={value} placeholder={placeholder} className="important-full-width" minValue="0" />
      <div className="d-flex justify-end full-width">
        {!flag && <AppButton onClick={onClick} isActive={true} isBusy={loading} className="upload-button action-color-bg primary-color fw500" name="Submit" />}
      </div>
    </div>
  );
};

export function DocumentUpload({ clickable, text, fileProps }) {
  return (
    <CustomInputFile fileProps={fileProps} className="full-width document-upload" clickable={clickable}>
      <div className="d-flex align-center j-space-between full-width">
        <p className="text-color fw500">{text}</p>
        <CircleAdd />
      </div>
    </CustomInputFile>
  );
}

export const DottedDocumentUpload = ({ clickable, fileProps, disabled, name, text }) => {
  return (
    <div className="dotted-upload">
      <CustomInputFile className="full-height j-center full-width" fileProps={fileProps} clickable={clickable} name={name} disabled={disabled}>
        <div className="d-flex align-center">
          <>
            <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3305 0.0151598C13.2778 0.00520151 13.2235 0 13.1681 0C13.1126 0 13.0584 0.00520151 13.0057 0.0151598H5.5008C2.51604 0.0151598 0 2.527 0 5.59072V18.9564C0 22.1898 2.40101 24.7286 5.5008 24.7286H15.0864C18.0332 24.7286 20.4624 22.0622 20.4624 18.9564V7.51646C20.4624 7.27541 20.3725 7.0437 20.2116 6.86983L14.1324 0.302148C13.9627 0.1188 13.7282 0.0151598 13.4832 0.0151598H13.3305ZM12.2681 1.88081L5.50109 1.88181C3.49534 1.88181 1.80029 3.57402 1.80029 5.59013V18.9558C1.80029 21.1659 3.4032 22.8608 5.50109 22.8608H15.0867C17.0159 22.8608 18.6627 21.0532 18.6627 18.9558L18.6624 8.69269L17.6103 8.69641C17.2102 8.69591 16.755 8.69503 16.249 8.69383C14.1246 8.68918 12.3914 6.96288 12.2744 4.78934L12.2681 4.5548V1.88081ZM17.6772 6.8264L16.2529 6.82661C15.0453 6.82396 14.0681 5.8078 14.0681 4.5548V2.92746L17.6772 6.8264ZM8.86865 11.5211V17.4442L8.87687 17.5709C8.93646 18.0266 9.31302 18.3778 9.76865 18.3778C10.2657 18.3778 10.6687 17.9598 10.6687 17.4442V11.5234L12.5366 13.4692L12.6373 13.5599C12.9891 13.8318 13.4892 13.8028 13.8094 13.472C14.1616 13.1082 14.1628 12.5171 13.8121 12.1517L10.4267 8.62506C10.3418 8.5307 10.2383 8.45463 10.1222 8.40314C9.79331 8.25705 9.3976 8.32362 9.12967 8.60283L5.72407 12.1518L5.63715 12.2567C5.37653 12.6228 5.40672 13.1414 5.72697 13.4721L5.82811 13.5623C6.18105 13.8327 6.68097 13.8013 6.99976 13.4691L8.86865 11.5211Z"
                fill="#AE207C"
              />
            </svg>
          </>
          <Spacer width={10} />
          <span className="accent-color fw400 font-size-13">
            <strong>{text || 'Select File'}</strong>
          </span>
        </div>
      </CustomInputFile>
    </div>
  );
};

export function MerchantContentWrapper({ className, hasTopSpacing, children, beforeSidePadding }) {
  return (
    <>
      {beforeSidePadding && beforeSidePadding}
      <div className={(className || '') + ' full-width content-wrapper'}>
        {hasTopSpacing && <Spacer height={20} />}
        {children}
      </div>
    </>
  );
}

export function Filter({ value, setValues, setPageNumber, needPrint }) {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const dateFilterRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  function setShowDateFilterFalse() {
    setShowDateFilter(false);
  }

  useOutsideRef(dateFilterRef, setShowDateFilterFalse);

  const onToggle = () => {
    setShowDateFilter((prev) => !prev);
  };

  const handleReload = () => {
    setValues({
      from: '',
      to: '',
    });
    setCurrentIndex(null);
  };

  return (
    <>
      <div className="d-flex filter-text-wrapper primary-bg-color p10 pl-40 pr-40">
        <div className="d-flex align-center c-pointer" onClick={onToggle}>
          <img src={filter} alt="filter" />
          <Spacer width={7} />
          <span className="text-color fw500">
            Filter By: <span className="action-color">Date</span>
          </span>
          <Spacer width={10} />
          <span className="caret-down fw400 text-capitalize">{value ?? <CaretDownOutlined className={showDateFilter ? 'downArrow' : ''} />}</span>
        </div>
        <div className="pl-20">
          <button className="btn-refresh c-pointer d-flex align-center column-gap-half" onClick={handleReload}>
            <img src="/assets/images/rotate.svg" alt="rotate icon" />
            <span className="action-color fw400 font-size-12">Clear filter</span>
          </button>
        </div>
      </div>
      {showDateFilter && (
        <DateFilter
          setValues={setValues}
          ref={dateFilterRef}
          setShowDateFilter={setShowDateFilter}
          setPageNumber={setPageNumber}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          needPrint={needPrint}
        />
      )}
    </>
  );
}

export function FilterAction({ className, filterValue, values, onFilterChange }) {
  return (
    <div className={(className || '') + ' d-flex align-center'}>
      <Filter values={values} value={filterValue || 'status'} onChange={onFilterChange} />
      <Spacer width={20} />
    </div>
  );
}

export function FilterNavigation({ children, border }) {
  return (
    <div className={`d-flex justify-end filter-nav ${border && 'grey-border'}`}>
      {children && (
        <>
          <Spacer width={20} />
          {children}
        </>
      )}
    </div>
  );
}

export function AddCustomInbound({ setShowCustomInbound }) {
  return (
    <div className="d-flex c-pointer primary-bg-color p10 pl-40 pr-40" onClick={() => setShowCustomInbound(true)}>
      <img src={ListIcon} alt="list" />
      <Spacer width={5} />
      <p className="fw500 text-vpay-purple">Add New Inbound </p>
    </div>
  );
}

export function CashpointNavigation({ onClick }) {
  return (
    <FilterNavigation border>
      <TourWrapper
        className="white-bg"
        contentClass="tour-triangle-right"
        title="Add Cashpoint"
        content="Create a new cashpoint for your business and recieve payments from all your different cashiers."
        index={3}
        style={{ right: '20px' }}
      >
        <AppButton className="action-color-2 button-nav-padding text-white" onClick={onClick} isActive name="Add Cashpoint" />
      </TourWrapper>
    </FilterNavigation>
  );
}

export function VirtualAccountNavigation() {
  return <FilterNavigation border></FilterNavigation>;
}

export function EmptyView({ title, description }) {
  const { textColor } = colors();
  return (
    <div className="d-flex half-vh flex-vertical align-center text-center j-center">
      <img src={emptyView} alt={description} />
      <Spacer height={10} />
      <p className="text-capitalize text-color fw400">{title}</p>
      <Spacer height={10} />
      <p className="fw300 font-size-smaller" style={{ color: textColor.withOpacity(0.5) }}>
        {description}
      </p>
    </div>
  );
}

export function MerchantGraphHeader({ children }) {
  return <div className="graph-header">{children}</div>;
}
export function MerchantGraph({ data, children, currencyType }) {
  const dataMonths = data.map((item) => item?.month);
  const dataValue = data.map((item) => item.value);

  const generatedChartData = generateChartData(dataMonths, dataValue, 'Transaction');

  const chartOptions = getChartOptions(currencyType);

  return (
    <div className="d-flex flex-vertical full-height full-width">
      {children}
      <div className="flex-one">
        <LineChart chartData={generatedChartData} chartOptions={chartOptions} />
      </div>
    </div>
  );
}

export const ValidatedPopups = ({ popups, defaultPosition }) => {
  const [position, setPosition] = useState(defaultPosition ?? 0);
  if (position < 0 || position > popups.length - 1) return null;
  return popups[position](position, setPosition);
};

export const BillsPopup = ({ onClose, firstTabChild, secondTabChild, onNext, firstTitle, secondTitle, firstText, secondText }) => {
  return (
    <GenericTabsPopup
      defaultPosition={1}
      onClose={onClose}
      firstTitle={firstTitle || firstText}
      secondTitle={secondTitle || secondText}
      firstText={firstText}
      secondText={secondText}
      firstComponent={(activeTab) => <firstTabChild.type {...firstTabChild.props} tab={activeTab} onNext={() => onNext(activeTab)} />}
      secondComponent={(activeTab) => <secondTabChild.type {...secondTabChild.props} tab={activeTab} onNext={() => onNext(activeTab)} />}
    />
  );
};

export const PayBillsAggregatedPop = ({ onClose }) => {
  const { providers, packages } = useSelector(billSelector);
  const [provider, setProvider] = useState('');
  const [packageValue, setPackage] = useState('');
  const [data, setData] = useState({});
  const { shouldSetPin, onSetPin } = useSetPin();

  useBillService(useDispatch(), providers);
  useBillData(useDispatch(), providers.find((p) => p.name === provider)?.network_id);

  const merchant = useSelector((state) => state.user.currentMerchant['businessid']);
  const phoneProps = useInput('', 'tel', null, null, true);
  const amountProps = useInput('', 'text', null, null, true);

  const onProviderChange = (e) => {
    setProvider(e);
    setPackage('');
  };

  const getAirtimeBody = () => {
    const { network_id, phone, amount } = data;
    return {
      network_id,
      phone,
      amount,
      merchant,
    };
  };

  const getDataBody = () => {
    const { variation_id, phone } = data;
    return {
      variation_id,
      phone,
      merchant,
    };
  };

  const onServiceBuy = () => {
    const { plan } = data;
    if (plan === 'Airtime') return buyAirtime(getAirtimeBody());
    else return buyData(getDataBody());
  };

  const validateAirtime = () => !!provider && !!phoneProps.value && !!amountProps.value;

  const validateData = () => !!provider && !!phoneProps.value && !!packageValue;

  const onNext = (tab, action) => {
    if (tab === 1) {
      if (!validateAirtime()) return;
    } else {
      if (!validateData()) return;
    }
    const currentPackage = packages.find((p) => p.name === packageValue);
    const extendedData = {
      plan: tab === 1 ? 'Airtime' : packageValue,
      amount: tab === 1 ? amountProps.value : currentPackage?.amount,
      variation_id: currentPackage?.variation_id,
    };
    setData({ ...formData(), ...extendedData, amount: extendedData?.amount || 0 });
    action();
  };

  const formData = () => {
    const currentNetwork = providers.find((p) => p.name === provider);
    return {
      network: currentNetwork?.name,
      network_id: currentNetwork?.network_id,
      phone: phoneProps.value,
    };
  };

  return (
    <ValidatedPopups
      onPopupClose={onClose}
      data={data}
      popups={[
        (position, onPositionClick) => (
          <BillsPopup
            firstText="Airtime"
            secondText="Data"
            onNext={(activeTab) => onNext(activeTab, () => onPositionClick(position + 1))}
            onClose={onClose}
            firstTabChild={<AirtimeDisplay onChange={onProviderChange} defaultValue={provider} providers={providers} phoneProps={phoneProps} amountProps={amountProps} />}
            secondTabChild={
              <DataDisplay
                onProviderChange={onProviderChange}
                providerValue={provider}
                phoneProps={phoneProps}
                onPackageChange={(e) => setPackage(e)}
                packageValue={packageValue}
                providers={providers}
                amountProps={amountProps}
                packages={packages}
              />
            }
          />
        ),
        (position, onPositionClick) => <ConfirmAirtimeOrData data={data} onClose={onClose} goBack={() => onPositionClick(position - 1)} onConfirmClick={() => onPositionClick(position + 1)} />,
        (position, onPositionClick) =>
          shouldSetPin ? <InitialPinSetUp setShouldSetPin={onSetPin} onClose={onClose} /> : <PinPopup onClose={onClose} goForward={() => onPositionClick(position + 1)} apiCall={onServiceBuy} />,
        () => <SuccessPopup type={data.plan === 'Airtime' ? 'Airtime' : 'Data'} bottomText="Your request has been processed successfully" onClose={onClose} />,
      ]}
    />
  );
};

export const PayUtilitiesAggregatedPop = ({ onClose }) => {
  const { cablePlans, utilityPlans, loading, cableUser, utilityUser } = useSelector(utilitySelector);
  const [plan, setPlan] = useState('');
  const dispatch = useDispatch();
  const uniquePlans = [...new Set(cablePlans?.map(({ service_id }) => service_id.toUpperCase()))];
  const [cableVariation, setCableVariation] = useState('');
  const [utilty, setUtility] = useState('');
  const [utilityVariation, setUtilityVariation] = useState('');
  const [token, setToken] = useState();
  const { shouldSetPin, onSetPin } = useSetPin();
  const onPinForward = (token, action) => {
    setToken(token);
    action();
  };

  const [data, setData] = useState({});

  useCableData(dispatch, cablePlans);
  useUtiltiyData(dispatch, utilityPlans);

  const merchant = useSelector((state) => state.user.currentMerchant['businessid']);
  const phoneProps = useInput('', 'tel', null, null, true);
  const amountProps = useInput('', 'text', null, null, true);
  const meterProps = useInput('', 'text', null, null, true);
  const cardProps = useInput('', 'text', null, null, true);
  const { value: smartcardNumber } = cardProps;
  const { value: meterNumber } = meterProps;

  const onCableChange = (e) => {
    setPlan(e);
    setCableVariation('');
  };
  const onUtilityChange = (e) => {
    setUtility(e);
    setUtilityVariation('');
  };

  const getCableBody = () => {
    const { variation_id, smartcard_number, service_id, phone } = data;
    return {
      variation_id,
      phone,
      smartcard_number,
      service_id,
      merchant,
    };
  };

  const getUtilityBody = () => {
    const { variation_id, meter_number, service_id, phone, amount } = data;
    return {
      variation_id,
      phone,
      meter_number,
      service_id,
      amount,
      merchant,
    };
  };

  const VData = useMemo(
    () => utilityPlans.find((p) => p.name === utilty),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [utilty],
  );
  const CData = useMemo(
    () => cablePlans.filter((p) => p.service_id === plan.toLowerCase())?.find((v) => v.name === cableVariation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plan, cableVariation],
  );

  const cableData = {
    type: 'cable',
    variation_id: CData?.variation_id,
    smartcard_number: smartcardNumber,
    service_id: CData?.service_id,
  };

  const utilsData = {
    type: 'electricity',
    variation_id: utilityVariation,
    meter_number: meterNumber,
    service_id: VData?.service_id,
  };

  useResetUtilUser(dispatch, 'cable');
  useResetUtilUser(dispatch, 'util');
  useVerifySmartCardNumber(smartcardNumber, cableData);
  useVerifyUtiliy(meterNumber, utilsData);

  const onServiceBuy = () => {
    const { plan } = data;
    if (plan === cableVariation) return buyCable(getCableBody());
    else return buyUtility(getUtilityBody());
  };

  const validateCable = () => !!plan && !!phoneProps.value && !!cardProps.value && !!cableVariation && !!cableUser;

  const validateUtility = () => !!utilty && !!phoneProps.value && !!meterProps.value && !!amountProps.value && !!utilityVariation && !!utilityUser;

  const onNext = (tab, action) => {
    if (tab === 1) {
      if (!validateCable()) return;
    } else {
      if (!validateUtility()) return;
    }
    const currentCablePlan = CData;
    const currentUtilityPlan = VData;
    const extendedData = {
      plan: tab === 1 ? cableVariation : utilityVariation,
      amount: tab === 1 ? currentCablePlan?.amount : amountProps.value,
      variation_id: tab === 1 ? currentCablePlan?.variation_id : utilityVariation,
      service_id: tab === 1 ? currentCablePlan?.service_id : currentUtilityPlan?.service_id,
      meter_number: meterProps.value,
      phone: phoneProps.value,
      provider: tab === 1 ? plan : utilty,
      smartcard_number: cardProps.value,
    };
    setData({ ...extendedData, amount: extendedData?.amount || 0 });
    action();
  };

  return (
    <ValidatedPopups
      onPopupClose={onClose}
      popups={[
        (position, onPositionClick) => (
          <BillsPopup
            firstText="Cable"
            secondText="Utility"
            onNext={(activeTab) => onNext(activeTab, () => onPositionClick(position + 1))}
            onClose={onClose}
            firstTabChild={
              <CableDisplay
                isBusy={loading?.isBusy && loading.actionFor === 'verify'}
                cableUser={cableUser}
                uniquePlans={uniquePlans}
                onVariationChange={(e) => setCableVariation(e)}
                onChange={onCableChange}
                variation={cableVariation}
                plans={cablePlans}
                plan={plan}
                phoneProps={phoneProps}
                cardProps={cardProps}
              />
            }
            secondTabChild={
              <UtilityDisplay
                isBusy={loading?.isBusy && loading.actionFor === 'verify'}
                meterName={utilityUser}
                onChange={onUtilityChange}
                service={utilty}
                services={utilityPlans}
                variation={utilityVariation}
                onVariationChange={(e) => setUtilityVariation(e)}
                meterProps={meterProps}
                phoneProps={phoneProps}
                amountProps={amountProps}
              />
            }
          />
        ),
        (position, onPositionClick) => <ConfirmCableOrUtilty data={data} onClose={onClose} goBack={() => onPositionClick(position - 1)} onConfirmClick={() => onPositionClick(position + 1)} />,
        (position, onPositionClick) =>
          shouldSetPin ? (
            <InitialPinSetUp setShouldSetPin={onSetPin} onClose={onClose} />
          ) : (
            <PinPopup onClose={onClose} goForward={(token) => onPinForward(token, () => onPositionClick(position + 1))} apiCall={onServiceBuy} />
          ),
        () => (
          <SuccessPopup
            type={(plan === cableVariation ? 'Cable' : 'Utility') + ' Subscription'}
            bottomText={
              <>
                Your request has been processed successfully
                <br />
                {token} || ''{' '}
              </>
            }
            onClose={onClose}
          />
        ),
      ]}
    />
  );
};

const AirtimeDisplay = ({ providers, phoneProps, amountProps, onNext, onChange, defaultValue }) => {
  return (
    <div className="p-relative">
      <FormDropdown defaultValue={defaultValue} onChange={onChange} className="important-full-width" label="Network" options={providers.map(({ name }) => name)} />
      <FormInput name="phoneNumber" {...phoneProps} label="Phone Number" className="important-full-width" />
      <FormInput name="amount" {...amountProps} label="Amount" className="important-full-width" />
      <AlignRight>
        <AppButton isActive onClick={onNext} className="font-size-16 b-tn-padding withdraw-button fw400 primary-color" name="Continue" />
      </AlignRight>
    </div>
  );
};

const DataDisplay = ({ providers, phoneProps, packages, onNext, onProviderChange, onPackageChange, providerValue, packageValue }) => {
  const amount = packages.find((p) => p.name === packageValue)?.amount || '';
  return (
    <div className="p-relative">
      <FormDropdown defaultValue={providerValue} onChange={onProviderChange} className="important-full-width" label="Network" options={providers.map(({ name }) => name)} />
      <FormInput name="phoneNumber" {...phoneProps} label="Phone Number" className="important-full-width" />
      <FormDropdown defaultValue={packageValue} onChange={onPackageChange} className="important-full-width" label="Data Plans" options={packages.map(({ name }) => name)} />
      <FormInput name="amount" value={amount} onChange={() => {}} readOnly label="Amount" className="important-full-width" />
      <AlignRight>
        <AppButton isActive onClick={onNext} className="font-size-16 b-tn-padding withdraw-button fw400 primary-color" name="Continue" />
      </AlignRight>
    </div>
  );
};

const CableDisplay = ({ plans, plan, uniquePlans, isBusy, cableUser, onVariationChange, variation, onChange, phoneProps, cardProps, onNext }) => {
  const variations = plans?.filter((p) => p.service_id === plan?.toLowerCase()) ?? [];
  const amount = variations.find((v) => v.name === variation)?.amount || '';

  return (
    <div className="p-relative">
      <FormDropdown defaultValue={plan} className="important-full-width" label="Service" onChange={onChange} options={uniquePlans} />
      <FormDropdown defaultValue={variation} onChange={onVariationChange} className="important-full-width" label="Plan" options={variations.map(({ name }) => name)} />
      <FormInput name="amount" value={amount} onChange={() => {}} readOnly label="Amount" className="important-full-width" />

      <FormInput name="smartCard" {...cardProps} label="Smart Card Number" className="important-full-width" />
      {isBusy ? (
        <LoadingOutlined />
      ) : (
        cableUser && (
          <>
            <span className="important-full-width nav-item-color fw400 ">{cableUser}</span>
            <Spacer height={20} />
          </>
        )
      )}
      <FormInput name="phoneNumber" {...phoneProps} label="Phone Number" className="important-full-width" />
      <AlignRight>
        <AppButton isActive onClick={onNext} className="font-size-16 b-tn-padding withdraw-button fw400 primary-color" name="Continue" />
      </AlignRight>
    </div>
  );
};

const UtilityDisplay = ({ services, service, onChange, onVariationChange, phoneProps, amountProps, isBusy, meterProps, variation, onNext, meterName }) => {
  return (
    <div className="p-relative">
      <FormDropdown defaultValue={service} onChange={onChange} className="important-full-width" label="Provider" options={services.map(({ name }) => name)} />
      <FormDropdown defaultValue={variation} onChange={onVariationChange} className="important-full-width" label="Plan" options={['prepaid', 'postpaid']} />
      <FormInput name="meterNumber" {...meterProps} label="Meter Number" className="important-full-width" />
      {isBusy ? (
        <LoadingOutlined />
      ) : (
        meterName && (
          <>
            <span className="important-full-width nav-item-color fw400 ">{meterName}</span>
            <Spacer height={20} />
          </>
        )
      )}
      <FormInput name="amount" {...amountProps} label="Amount" className="important-full-width" />
      <FormInput name="phoneNumber" {...phoneProps} label="Phone Number" className="important-full-width" />
      <AlignRight>
        <AppButton isActive onClick={onNext} className="font-size-16 b-tn-padding withdraw-button fw400 primary-color" name="Continue" />
      </AlignRight>
    </div>
  );
};

export const DesktopCashierTransactionList = React.forwardRef(({ noClick, setShow, setCurrentRecord, t }, ref) => {
  const onClick = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  return (
    <div ref={ref} onClick={!noClick ? onClick : () => {}} className="trans-item-wrapper">
      <div>
        <p className="font-size-13 fw400">{`₦${formatNumber(t.orderamount)}`}</p>
      </div>
      <div>
        <p className="font-size-13 fw400 name">
          {t.name}
          <br />
          {t?.merchanttransactiondata[0]?.reversed && <span className="action-color">[Reversed]</span>}
        </p>
      </div>
      <div>
        <p className="font-size-13 fw400">{t.accountnumber || 'Not Available'}</p>
      </div>
      <div>
        <p className="font-size-13 fw400">{formatDay(t.createdon)}</p>
      </div>
      <div>
        <p
          className="d-flex j-center align-center font-size-13 fw400"
          style={{
            color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
            textTransform: 'capitalize',
            width: '100px',
            padding: '3px 20px!important',
            border: `1px solid ${t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6'}`,
            borderRadius: '25px',
          }}
        >
          {t.paymentstatus}
        </p>
      </div>
    </div>
  );
});

export const TransactionTable = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const { cashpointid } = useSelector((state) => state.user.user);
  const [currentRecord, setCurrentRecord] = useState(null);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointid, pageNumber, { period: 'year' });
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointid]);

  const { data, lastElementRef, loading } = usePaginate(cashpointid, pageNumber, setPageNumber, payouts);

  const transactionHeader = ['Amount', 'Customer', 'Destination', 'Date/Time', 'Status'];

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(cashpointid, currentRecord.merchanttransactiondata[0]._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  // Total Transaction By Date

  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <section>
      <MobileCashierTransactionTable setCurrentRecord={setCurrentRecord} setShow={setShow} />
      {data && data.length > 0 ? (
        <>
          <div className="cashier-trans no-mobile table-padding primary-bg-color full-width border-radius5 full-height overflow-auto">
            <div className="d-flex trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((customer, i) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return <DesktopCashierTransactionList key={i} t={customer} ref={data.length === i + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />;
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <div key={i}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <DesktopCashierTransactionList t={customer} ref={data.length === i + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />
                        </div>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {shouldCashpointModalOpen && <DeactivateCashpointPopup className="no-mobile" />}
          {show && (
            <CashpointNewPopupDetails
              onClose={() => setShow(false)}
              totalAmount={`₦${formatNumber(currentRecord.orderamount)}`}
              status={currentRecord.paymentstatus}
              date={currentRecord.createdon}
              customer={currentRecord.name}
              id={currentRecord._id}
              merchantId={currentRecord?.merchanttransactiondata[0]?._id}
              type={currentRecord?.merchanttransactiondata[0]?.type}
              txnRef={currentRecord?.merchanttransactiondata[0]?.transactionref}
              description={currentRecord?.merchanttransactiondata[0]?.narrative}
              reversalexpireson={currentRecord?.merchanttransactiondata[0]?.reversalexpireson}
              createdon={currentRecord?.merchanttransactiondata[0]?.createdon}
              isLoading={isLoading}
              handleInitiateChallenge={handleInitiateChallenge}
            />
          )}
          {showChallengePopup && (
            <CashpointInitiateChallengePopup
              publickey={cashpointid}
              id={currentRecord.merchanttransactiondata[0]._id}
              onClose={() => {
                setShowChallengePopup(false);
                setShow(true);
              }}
              label={challengeDetails?.message}
              type={challengeDetails?.challenge_type}
            />
          )}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

export const DesktopMerchantCashpointTransactionList = React.forwardRef(({ noClick, setShow, setCurrentRecord, t }, ref) => {
  const onClick = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  return (
    <div ref={ref} onClick={!noClick ? onClick : () => {}} className="trans-item-wrapper">
      <div>
        <p className="font-size-13 fw400">{`₦${formatNumber(t.orderamount)}`}</p>
      </div>
      <div>
        <p className="font-size-13 fw400 name">
          {t.name}
          <br />
          {t?.merchanttransactiondata[0]?.reversed && <span className="action-color">[Reversed]</span>}
        </p>
      </div>
      <div>
        <p className="font-size-13 fw400">{t.orderdescription || 'Not Available'}</p>
      </div>
      <div>
        <p className="font-size-13 fw400">{formatDay(t.createdon)}</p>
      </div>
      <div>
        <p
          className="d-flex j-center align-center font-size-13 fw400"
          style={{
            color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
            textTransform: 'capitalize',
            width: '100px',
            padding: '3px 20px!important',
            border: `1px solid ${t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6'}`,
            borderRadius: '25px',
          }}
        >
          {t.paymentstatus}
        </p>
      </div>
    </div>
  );
});

export const DesktopMerchantVirtualTransactionList = React.forwardRef(({ noClick, setCurrentRecord, t, isRowClickable, currentRecord, setShow }, ref) => {
  const [loading, setLoading] = useState(false);
  const [fadeout, setFadeout] = useState(false);

  const onClick = async () => {
    setCurrentRecord && setCurrentRecord(t);
    // setShow && setShow(true);
    if (!currentRecord?.merchant || currentRecord.merchanttransactiondata[0].length < 1) {
      return;
    }
    try {
      setLoading(true);
      const res = await repushWebhook(currentRecord?.merchant, currentRecord.merchanttransactiondata[0]?._id);
      // console.log(currentRecord?.merchant, currentRecord.merchanttransactiondata[0]?._id, " holla")
      if (res?.data?.status) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Webhook Pushed Successfully');
        setCurrentRecord(null);
        setFadeout(true);
      }
    } catch (error) {
      console.log(error, ' an error occurred');
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const showPopup = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  if (isRowClickable === false) {
    return (
      <div ref={ref} className={`virtual-item-wrapper fade-in ${fadeout ? 'item-fadeout' : ''}`}>
        <div>
          <p className="font-size-13 fw400">{`₦${formatNumber(t.orderamount)}`}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 name">{t.name}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">{t.orderdescription || 'Not Available'}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">{formatDay(t.createdon)}</p>
        </div>
        <div>
          <p
            className="d-flex j-center align-center font-size-13 fw400"
            style={{
              color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
              textTransform: 'capitalize',
              width: '100px',
              padding: '3px 20px!important',
              border: `1px solid ${t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6'}`,
              borderRadius: '25px',
            }}
          >
            {t.paymentstatus}
          </p>
        </div>
        <div onClick={() => onClick()}>
          <p
            className="d-flex j-center align-center font-size-13 fw400"
            style={{
              color: t?.merchanttransactiondata[0]?.issenttowebhook === true ? '#47A08B' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? '#fff' : '#47A08B',
              textTransform: 'capitalize',
              width: '100px',
              padding: '3px 20px !important',
              border: `1px solid ${t?.merchanttransactiondata[0]?.issenttowebhook === true ? '#47A08B' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? 'var(--color-action)' : '#47A08B'}`,
              borderRadius: '25px',
              background: `${t?.merchanttransactiondata[0]?.issenttowebhook === false && 'var(--color-action)'}`,
              cursor: `pointer`,
            }}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} spin={true} />}
            {t?.merchanttransactiondata[0]?.issenttowebhook === true ? 'Sent' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? 'Push' : 'Sent'}
          </p>
        </div>
      </div>
    );
  } else if (isRowClickable === true) {
    return (
      <div ref={ref} onClick={!noClick ? showPopup : () => {}} className="trans-item-wrapper">
        <div>
          <p className="font-size-13 fw400">{`₦${formatNumber(t.orderamount)}`}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 name">{t.name}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">{t.orderdescription || 'Not Available'}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">{formatDay(t.createdon)}</p>
        </div>
        <div>
          <p
            className="d-flex j-center align-center font-size-13 fw400"
            style={{
              color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
              textTransform: 'capitalize',
              width: '100px',
              padding: '3px 20px!important',
              border: `1px solid ${t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6'}`,
              borderRadius: '25px',
            }}
          >
            {t.paymentstatus}
          </p>
        </div>
      </div>
    );
  }
});

export const MerchantCashpointTransactionTable = ({ cashpointID, values }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);
  const [currentRecord, setCurrentRecord] = useState(null);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointID, pageNumber, values.from, values.to);
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointID, values]);

  const { data, lastElementRef, loading } = usePaginate(cashpointID, pageNumber, setPageNumber, payouts, values);

  const transactionHeader = ['Amount', 'Customer', 'Description', 'Date/Time', 'Status'];

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(publickey, currentRecord.merchanttransactiondata[0]._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className="desktop-merchant-cashpoint-trans no-mobile table-padding primary-bg-color full-width border-radius5 full-height overflow-auto">
            <div className="d-flex trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((customer, i) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return <DesktopMerchantCashpointTransactionList key={i} t={customer} ref={data.length === i + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />;
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <Fragment key={i}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <DesktopMerchantCashpointTransactionList t={customer} ref={data.length === i + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {shouldCashpointModalOpen && <DeactivateCashpointPopup className="no-mobile" />}
          {show && (
            <CashpointNewPopupDetails
              onClose={() => setShow(false)}
              totalAmount={`₦${formatNumber(currentRecord.orderamount)}`}
              status={currentRecord.paymentstatus}
              date={currentRecord.createdon}
              customer={currentRecord.name}
              id={currentRecord._id}
              merchantId={currentRecord?.merchanttransactiondata[0]?._id}
              type={currentRecord?.merchanttransactiondata[0]?.type}
              txnRef={currentRecord?.merchanttransactiondata[0]?.transactionref}
              description={currentRecord?.merchanttransactiondata[0]?.narrative}
              reversalexpireson={currentRecord?.merchanttransactiondata[0]?.reversalexpireson}
              createdon={currentRecord?.merchanttransactiondata[0]?.createdon}
              isLoading={isLoading}
              handleInitiateChallenge={handleInitiateChallenge}
            />
          )}
          {showChallengePopup && (
            <CashpointInitiateChallengePopup
              publickey={publickey}
              id={currentRecord.merchanttransactiondata[0]._id}
              onClose={() => {
                setShowChallengePopup(false);
                setShow(true);
              }}
              label={challengeDetails?.message}
              type={challengeDetails?.challenge_type}
            />
          )}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

export const MobileMerchantCashpointTransactionTable = ({ cashpointID, values }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointID, pageNumber, values.from, values.to);
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointID]);

  const { data, lastElementRef, loading } = usePaginate(cashpointID, pageNumber, setPageNumber, payouts, values);

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(publickey, currentRecord.merchanttransactiondata[0]._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <div className="no-desktop-but-mobile">
      {data &&
        data.map((t, idx) => {
          if (uniqueTransactionDates.has(formatYear(t.createdon))) {
            return (
              <CashpointMobileTransactionItem
                key={idx}
                t={t}
                ref={data.length === idx + 1 ? lastElementRef : null}
                setCurrentRecord={setCurrentRecord}
                setShow={setShow}
                topLeft={t.name && t.name}
                bottomLeft={t.createdon && formatDay(t.createdon)}
                topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
                bottomRight={t.paymentstatus && t.paymentstatus}
                styles={{
                  color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                  textTransform: 'capitalize',
                }}
              />
            );
          } else {
            listDateInfo.current.add(formatYear(t.createdon));
            uniqueTransactionDates.add(formatYear(t.createdon));
            return (
              <div key={idx}>
                <DateRow debit="" credit={DATE_MAP[formatYear(t.createdon)]?.totalCredit} date={dayjs(t.createdon).format('DD/MMM/YYYY')} />
                <CashpointMobileTransactionItem
                  t={t}
                  ref={data.length === idx + 1 ? lastElementRef : null}
                  setCurrentRecord={setCurrentRecord}
                  setShow={setShow}
                  topLeft={t.name && t.name}
                  bottomLeft={t.createdon && formatDay(t.createdon)}
                  topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
                  bottomRight={t.paymentstatus && t.paymentstatus}
                  styles={{
                    color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                    textTransform: 'capitalize',
                  }}
                />
              </div>
            );
          }
        })}
      {loading && <Spacer height={20} />}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
      {show && (
        <CashpointNewPopupDetails
          onClose={() => setShow(false)}
          totalAmount={`₦${formatNumber(currentRecord.orderamount)}`}
          status={currentRecord.paymentstatus}
          date={currentRecord.createdon}
          customer={currentRecord.name}
          id={currentRecord._id}
          merchantId={currentRecord?.merchanttransactiondata[0]?._id}
          type={currentRecord?.merchanttransactiondata[0]?.type}
          txnRef={currentRecord?.merchanttransactiondata[0]?.transactionref}
          description={currentRecord?.merchanttransactiondata[0]?.narrative}
          reversalexpireson={currentRecord?.merchanttransactiondata[0]?.reversalexpireson}
          createdon={currentRecord?.merchanttransactiondata[0]?.createdon}
          isLoading={isLoading}
          handleInitiateChallenge={handleInitiateChallenge}
        />
      )}
      {showChallengePopup && (
        <CashpointInitiateChallengePopup
          publickey={publickey}
          id={currentRecord.merchanttransactiondata[0]._id}
          onClose={() => {
            setShowChallengePopup(false);
            setShow(true);
          }}
          label={challengeDetails?.message}
          type={challengeDetails?.challenge_type}
        />
      )}
    </div>
  );
};

export const DateRow = ({ date, credit, debit }) => (
  <div className={`date-row ${isDesktop() && 'desktop-date-row'}`}>
    <span className="fw500 date">{date}</span>
    <div className="d-flex align-center column-gap1">
      <span className="fw500 font-size-12 success-color">₦{formatNumber(credit)}</span>
      <span className="fw500 font-size-12 action-color">{debit !== '' && `₦${formatNumber(debit)}`}</span>
    </div>
  </div>
);

export const HistoryMobileTransactionContainer = ({ setCurrentRecord, setShow, shouldDisplay, noClick, isBonus, bonusType, emptyTitle, emptyDescription, actor, values }) => {
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};
  const [pageNumber, setPageNumber] = useState(1);

  const { businessid, publickey } = useSelector((state) => state.user.currentMerchant);

  const payouts = useCallback(async () => {
    if (!isBonus) {
      const res = await fetchMerchantPayouts(businessid, pageNumber, values.from, values.to);
      return res.data.data;
    } else {
      const res = await bonusTransactionsService(publickey, pageNumber, bonusType, actor);
      return res.data.data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, businessid, values]);

  const { data, lastElementRef, loading } = usePaginate(businessid, pageNumber, setPageNumber, payouts, values);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => totalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <div className={`${!shouldDisplay ? 'no-desktop-but-mobile' : ''}`}>
      {data &&
        data.map((t, idx) => {
          if (uniqueTransactionDates.has(formatYear(t.createdon))) {
            return (
              <MobileTransactionItem
                key={idx}
                t={t}
                noClick={noClick}
                ref={data.length === idx + 1 ? lastElementRef : null}
                setCurrentRecord={setCurrentRecord}
                setShow={setShow}
                topLeft={t.narrative && t?.narrative}
                bottomLeft={t.createdon && formatDay(t.createdon)}
                topRight={t.amount && `${t.type === 'credit' ? '+' : '-'} ₦${formatNumber(t.amount)}`}
                bottomRight={t.type && t.type}
                styles={{
                  color: t.type === 'credit' ? '#47A08B' : '#ED2B30',
                  textTransform: 'capitalize',
                }}
                repeatCustomerCount={t.repeat_customer_count}
              />
            );
          } else {
            listDateInfo.current.add(formatYear(t.createdon));
            uniqueTransactionDates.add(formatYear(t.createdon));
            return (
              <div key={idx}>
                <DateRow debit={DATE_MAP[formatYear(t.createdon)]?.totalDebit} credit={DATE_MAP[formatYear(t.createdon)]?.totalCredit} date={dayjs(t.createdon).format('DD/MMM/YYYY')} />
                <MobileTransactionItem
                  t={t}
                  noClick={noClick}
                  ref={data.length === idx + 1 ? lastElementRef : null}
                  setCurrentRecord={setCurrentRecord}
                  setShow={setShow}
                  topLeft={t.narrative && t?.narrative}
                  bottomLeft={t.createdon && formatDay(t.createdon)}
                  topRight={t.amount && `${t.type === 'credit' ? '+' : '-'} ₦${formatNumber(t.amount)}`}
                  bottomRight={t.type && t.type}
                  styles={{
                    color: t.type === 'credit' ? '#47A08B' : '#ED2B30',
                    textTransform: 'capitalize',
                  }}
                  repeatCustomerCount={t.repeat_customer_count}
                />
              </div>
            );
          }
        })}
      {loading && <Spacer height={20} />}
      {data.length < 1 && (
        <EmptyView
          title={emptyTitle ?? 'no transactions'}
          description={
            emptyDescription ?? (
              <>
                Your history page is empty.
                <br /> Make a new transaction and find all your transactions here.
              </>
            )
          }
        />
      )}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
    </div>
  );
};

export const PaginatedPayoutLists = ({ id, emptyTitle, emptyDescription }) => {
  let myDateSet = new Set();
  const [pageNumber, setPageNumber] = useState(1);

  const payouts = useCallback(async () => {
    const res = await fetchMemberPayouts(id, pageNumber);
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, id]);
  const { data, lastElementRef, loading } = usePaginate(id, pageNumber, setPageNumber, payouts);
  return (
    <div className="">
      {data &&
        data.map((t, idx) => {
          if (myDateSet.has(formatYear(t.createdon))) {
            return (
              <MobileTransactionItem
                key={idx}
                t={t}
                ref={data.length === idx + 1 ? lastElementRef : null}
                topLeft={t.narrative && t?.narrative}
                bottomLeft={t.createdon && formatDay(t.createdon)}
                topRight={t.amount && `${t.type === 'credit' ? '+' : '-'} ₦${formatNumber(t.amount)}`}
                bottomRight={t.type && t.type}
                styles={{
                  color: t.type === 'credit' ? '#47A08B' : '#ED2B30',
                  textTransform: 'capitalize',
                }}
              />
            );
          } else {
            myDateSet.add(formatYear(t.createdon));
            return (
              <div key={idx}>
                <div className="date-row fw500 ">{dayjs(t.createdon).format('DD/MMM/YYYY')}</div>
                <MobileTransactionItem
                  t={t}
                  ref={data.length === idx + 1 ? lastElementRef : null}
                  topLeft={t.narrative && t.narrative}
                  bottomLeft={t.createdon && formatDay(t.createdon)}
                  topRight={t.amount && `${t.type === 'credit' ? '+' : '-'} ₦${formatNumber(t.amount)}`}
                  bottomRight={t.type && t.type}
                  styles={{
                    color: t.type === 'credit' ? '#47A08B' : '#ED2B30',
                    textTransform: 'capitalize',
                  }}
                />
              </div>
            );
          }
        })}
      {loading && <Spacer height={20} />}
      {data.length < 1 && <EmptyView title={emptyTitle ?? 'no transactions'} description={emptyDescription ?? ''} />}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
    </div>
  );
};
// eslint-disable-next-line react/display-name
export const MobileTransactionItem = React.forwardRef(({ noClick, topLeft, bottomLeft, topRight, bottomRight, styles, setShow, setCurrentRecord, t, repeatCustomerCount }, ref) => {
  const failureCodes = useSelector((state) => state.merchant.failureCodes);

  const onClick = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  return (
    <div ref={ref} onClick={!noClick ? onClick : () => {}} className="trans-item-wrapper">
      <div className="d-flex">
        {t.issetselfie && (
          <div>
            <img src={cameraIcon} className="w-75" alt="an icon" />
          </div>
        )}
        <div className="trans-item ">
          <p className={`${t?.isqualifyforchallenge ? 'challenge-color' : ''} font-size-15 topLeft fw400`}>
            {t?.reversed && <span className="action-color">[Reversal]</span>} {topLeft}
          </p>
          {repeatCustomerCount > 0 && !t?.reversed && t.type.toLowerCase() === 'credit' && <p className="text-vpay-purple fw700 ">{`Returning Customer  (x${repeatCustomerCount})`}</p>}
          {repeatCustomerCount > 0 && !t?.reversed && t.type.toLowerCase() === 'debit' && <p className="text-vpay-purple fw700 ">{`Existing Beneficiary  (x${repeatCustomerCount})`}</p>}
          <p className="bottomLeft fw400">{bottomLeft}</p>
        </div>
      </div>
      <div className="trans-item align-end">
        <p className="font-size-15 fw400">{topRight}</p>
        <p className="bottomRight fw400" style={styles}>
          {bottomRight}
        </p>
        {failureCodes.includes(t?.status) && <p className="bottomRight failedTag text-center fw400">Failed</p>}
      </div>
    </div>
  );
});

export const DesktopHistoryTransactionItem = React.forwardRef(({ noClick, setShow, setCurrentRecord, t }, ref) => {
  const onClick = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  return (
    <>
      <div ref={ref} onClick={!noClick ? onClick : () => {}} className="trans-item-wrapper">
        <div>
          <p className="font-size-13 fw400">{`₦${formatNumber(t.amount)}`}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 narrative">
            {t.narrative}
            {t?.reversed && <br />}
            {t?.reversed && <span className="action-color ">[Reversed]</span>}
            <br />
            {t.repeat_customer_count > 0 && !t?.reversed && t.type.toLowerCase() === 'debit' && <p className="text-vpay-purple fw500 ">{`Existing Beneficiary  (x${t.repeat_customer_count})`}</p>}
            {t.repeat_customer_count > 0 && !t?.reversed && t.type.toLowerCase() === 'credit' && <p className="text-vpay-purple fw500 ">{`Returning Customer  (x${t.repeat_customer_count})`}</p>}
          </p>
        </div>
        <div>
          <p className="font-size-13 fw400 trans-ref">{t.transactionref}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">{formatDay(t.createdon)}</p>
        </div>
        <div>
          <p
            className="d-flex j-center align-center font-size-13 fw400"
            style={{
              color: t.type === 'credit' ? '#47A08B' : '#ED2B30',
              textTransform: 'capitalize',
              width: '100px',
              padding: '3px 20px!important',
              border: `1px solid ${t.type === 'credit' ? '#47A08B' : '#ED2B30'}`,
              borderRadius: '20px',
            }}
          >
            {t.type}
          </p>
        </div>
      </div>
    </>
  );
});

export const CustomDateRangePicker = ({ dateRange, dateChange }) => {
  const { RangePicker } = DatePicker;

  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf('day');
  }

  return (
    <div>
      <RangePicker value={dateRange} disabledDate={disabledDate} onChange={dateChange} format="YYYY-MM-DD" />
    </div>
  );
};

// eslint-disable-next-line react/display-name
// eslint-disable-next-line no-unused-vars
export const DateFilter = forwardRef(({ setValues, setPageNumber, currentIndex, setCurrentIndex, setShowDateFilter, needPrint }, ref) => {
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [dateValue, setDateValue] = useState('');
  const [check, setCheck] = useState(true);

  /* custom date range */
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [customDateRange, setCustomDateRange] = useState([null, null]);

  /* print statement */
  const [showPrintRange, setShowPrintRange] = useState(false);
  const [printDateRange, setPrintDateRange] = useState([null, null]);

  const onChange = async (e, index) => {
    setShowCustomDateRange(false);
    setDateValue(e.target.value);
    setCheck(true);
    setCurrentIndex(index);
    setPageNumber(1);

    let day = e.target.value;
    const today = dayjs().format('YYYY-MM-DD');

    const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

    switch (day) {
      case 'yesterday':
        setValues({
          from: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          to: today,
        });
        break;
      case '7-days':
        setValues({
          from: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          to: today,
        });
        break;
      case '28-days':
        setValues({
          from: dayjs().subtract(28, 'day').format('YYYY-MM-DD'),
          to: today,
        });
        break;
      case 'this-month':
        setValues({
          from: dayjs().startOf('month').format('YYYY-MM-DD'),
          to: today,
        });
        break;
      case 'last-month':
        setValues({
          from: lastMonthStart,
          to: lastMonthEnd,
        });
        break;
      case 'custom-date-range':
        setCustomDateRange([null, null]);
        setPrintDateRange([null, null]);
        setValues({
          from: customDateRange[0]?.format('YYYY-MM-DD'),
          to: customDateRange[1]?.format('YYYY-MM-DD'),
        });
        break;
      case 'print-statement':
        setPrintDateRange([null, null]);
        setCustomDateRange([null, null]);
        break;
      default:
        setValues({
          from: '',
          to: '',
        });
    }
  };

  const customDateRangeChange = (d) => {
    let updatedCustomDate = {
      ...customDateRange,
      d,
    };
    setCustomDateRange(updatedCustomDate.d);
    setValues({
      from: updatedCustomDate?.d[0]?.format('YYYY-MM-DD'),
      to: updatedCustomDate?.d[1]?.format('YYYY-MM-DD'),
    });
    setShowCustomDateRange(false);
    setTimeout(() => {
      setShowDateFilter(false);
    }, 1000);
  };

  const printDateRangeChange = async (p) => {
    let updatedPrintDate = {
      ...printDateRange,
      p,
    };
    setPrintDateRange(updatedPrintDate.p);
    setShowPrintRange(false);

    if (printDateRange !== undefined) {
      try {
        const data = {
          type: 'mail',
          all_time: false,
          single_date: null,
          start_date: updatedPrintDate?.p[0]?.format('YYYY-MM-DD'),
          end_date: updatedPrintDate?.p[1]?.format('YYYY-MM-DD'),
        };
        const res = await printStatement(merchantID, data, key);
        if (res.status === 200) {
          openNotificationWithIcon('success', '', res.data.message);
          setShowDateFilter(false);
        }
        return res.data.message;
      } catch (error) {
        return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (dateValue === 'custom-date-range') {
      setShowPrintRange(false);
      setShowCustomDateRange(true);
    } else if (dateValue === 'print-statement') {
      setShowCustomDateRange(false);
      setShowPrintRange(true);
    } else {
      setShowCustomDateRange(false);
      setShowPrintRange(false);
    }
  }, [dateValue]);

  let data = [
    {
      label: 'Yesterday',
      htmlFor: 'yesterday',
      id: 'yesterday',
      checked: { check },
      value: 'yesterday',
    },
    {
      label: 'Last 7 days',
      htmlFor: '7-days',
      id: '7-days',
      checked: { check },
      value: '7-days',
    },
    {
      label: 'Last 28 days',
      htmlFor: '28-days',
      id: '28-days',
      checked: { check },
      value: '28-days',
    },
    {
      label: 'This month',
      htmlFor: 'this-month',
      id: 'this-month',
      checked: { check },
      value: 'this-month',
    },
    {
      label: 'Last month',
      htmlFor: 'last-month',
      id: 'last-month',
      checked: { check },
      value: 'last-month',
    },
    {
      label: 'Custom date range',
      htmlFor: 'custom-date-range',
      id: 'custom-date-range',
      checked: { check },
      value: 'custom-date-range',
    },
    {
      label: 'Print statement',
      htmlFor: 'print-statement',
      id: 'print-statement',
      checked: { check },
      value: 'print-statement',
    },
  ];
  if (needPrint === false) {
    data = data.filter((item) => item.label !== 'Print statement');
  }

  return (
    <div className="p-relative">
      <div className="cashpoint-item-wrapper width-275 rounded-md primary-bg-color d-flex flex-vertical ml-40 px-0">
        {data.map((datum, index) => (
          <div className={`${currentIndex === index ? 'checked' : ''} d-flex align-center j-space-between mb-20 px-20 py-10 app-radio`} key={index}>
            <label htmlFor={datum.htmlFor} className="input-label fw200 radio-label" style={{ color: '#200e32' }}>
              {datum.label}
            </label>
            <input type="radio" id={datum.id} name="date-filter" value={datum.value} checked={currentIndex === index} onChange={(e) => onChange(e, index)} />
            <span className="checkmark"></span>
          </div>
        ))}
      </div>
      {showCustomDateRange && (
        <div className="p-absolute custom-calendar-wrapper" style={{ left: '20rem', top: '12.5rem', zIndex: 1 }}>
          <CustomDateRangePicker dateRange={customDateRange} dateChange={customDateRangeChange} />
        </div>
      )}
      {showPrintRange && (
        <div className="p-absolute custom-calendar-wrapper" style={{ left: '20rem', top: '12.5rem', zIndex: 1 }}>
          <CustomDateRangePicker dateRange={printDateRange} dateChange={printDateRangeChange} />
        </div>
      )}
    </div>
  );
});

export const VirtualAccountTransactionTable = ({ cashpointID, values, transactionHeader, isRowClickable }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);
  const [currentRecord, setCurrentRecord] = useState(null);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [reload, setReload] = useState(1);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointID, pageNumber, values.from, values.to);
    if (isRowClickable === false) {
      return res.data.data.filter((tnx) => {
        return tnx.merchanttransactiondata[0].issenttowebhook === false;
      });
    }
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointID, values, reload]);

  const { data, lastElementRef, loading } = usePaginate(cashpointID, pageNumber, setPageNumber, payouts, values, reload);

  //const transactionHeader = ['Amount', 'Customer', 'Description', 'Date/Time', 'Status', 'Webhook Status'];

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(publickey, currentRecord.merchanttransactiondata[0]._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className="desktop-merchant-cashpoint-trans no-mobile table-padding primary-bg-color full-width border-radius5 full-height overflow-auto fade-in">
            <div className="d-flex trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((customer, i) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return (
                        <DesktopMerchantVirtualTransactionList
                          key={i}
                          t={customer}
                          ref={data.length === i + 1 ? lastElementRef : null}
                          setShow={setShow}
                          setCurrentRecord={setCurrentRecord}
                          isRowClickable={isRowClickable}
                          currentRecord={currentRecord}
                          setReload={setReload}
                        />
                      );
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <Fragment key={i}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <DesktopMerchantVirtualTransactionList
                            t={customer}
                            ref={data.length === i + 1 ? lastElementRef : null}
                            setShow={setShow}
                            setCurrentRecord={setCurrentRecord}
                            isRowClickable={isRowClickable}
                            currentRecord={currentRecord}
                            setReload={setReload}
                          />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {shouldCashpointModalOpen && <DeactivateCashpointPopup className="no-mobile" />}
          {show && (
            <VirtualAccountPopupDetails
              onClose={() => setShow(false)}
              totalAmount={`₦${formatNumber(currentRecord.orderamount)}`}
              status={currentRecord.paymentstatus}
              date={currentRecord.createdon}
              customer={currentRecord.name}
              id={currentRecord._id}
              merchantId={currentRecord?.merchanttransactiondata[0]?._id}
              type={currentRecord?.merchanttransactiondata[0]?.type}
              txnRef={currentRecord?.merchanttransactiondata[0]?.transactionref}
              description={currentRecord?.merchanttransactiondata[0]?.narrative}
              reversalexpireson={currentRecord?.merchanttransactiondata[0]?.reversalexpireson}
              createdon={currentRecord?.merchanttransactiondata[0]?.createdon}
              isLoading={isLoading}
              handleInitiateChallenge={handleInitiateChallenge}
              issenttowebhook={currentRecord?.merchanttransactiondata[0]?.issenttowebhook}
              merchantTnxId={currentRecord?.merchanttransactiondata[0]?._id}
            />
          )}
          {showChallengePopup && (
            <CashpointInitiateChallengePopup
              publickey={publickey}
              id={currentRecord.merchanttransactiondata[0]._id}
              onClose={() => {
                setShowChallengePopup(false);
                setShow(true);
              }}
              label={challengeDetails?.message}
              type={challengeDetails?.challenge_type}
            />
          )}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

// failed web hook
export const VirtualAccountFailedWebhooks = ({ merchantID, values, transactionHeader, isRowClickable }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);
  const [currentRecord, setCurrentRecord] = useState(null);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);

  const [reload, setReload] = useState(1);

  const payouts = useCallback(async () => {
    const res = await getVirtualAccountInfo(merchantID, values.from, values.to, '', true, pageNumber);
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values]);

  const { data, lastElementRef, loading } = useVirtualAccountPaginate(merchantID, pageNumber, setPageNumber, payouts, values);

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className="desktop-merchant-cashpoint-trans no-mobile table-padding primary-bg-color full-width border-radius5 full-height overflow-auto fade-in">
            <div className="d-flex trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((customer, i) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return (
                        <DesktopMerchantVirtualTransactionList
                          key={i}
                          t={customer}
                          ref={data.length === i + 1 ? lastElementRef : null}
                          setShow={setShow}
                          setCurrentRecord={setCurrentRecord}
                          isRowClickable={isRowClickable}
                          currentRecord={currentRecord}
                          setReload={setReload}
                        />
                      );
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <Fragment key={i}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <DesktopMerchantVirtualTransactionList
                            t={customer}
                            ref={data.length === i + 1 ? lastElementRef : null}
                            setShow={setShow}
                            setCurrentRecord={setCurrentRecord}
                            isRowClickable={isRowClickable}
                            currentRecord={currentRecord}
                            setReload={setReload}
                          />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {shouldCashpointModalOpen && <DeactivateCashpointPopup className="no-mobile" />}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

export const MobileVirtualAccountFailedWebhooks = ({ merchantID, values }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const payouts = useCallback(async () => {
    const res = await getVirtualAccountInfo(merchantID, values.from, values.to, '', true, pageNumber);
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values]);

  const { data, lastElementRef, loading } = useVirtualAccountPaginate(merchantID, pageNumber, setPageNumber, payouts, values);

  return (
    <div className="no-desktop-but-mobile">
      {data && data.length > 0 ? (
        data.map((t, idx) => {
          return (
            <VirtualAccFailedWebhookItem
              key={idx}
              t={t}
              ref={data.length === idx + 1 ? lastElementRef : null}
              setCurrentRecord={setCurrentRecord}
              setShow={setShow}
              topLeft={t.name && t.name}
              bottomLeft={t.createdon && formatDay(t.createdon)}
              topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
              needPushBtn={true}
            />
          );
        })
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
          </div>
        </>
      )}
      {loading && <Spacer height={20} />}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
    </div>
  );
};

// failed web hook

// virtual accounts

export const VirtualMobileMerchantCashpointTransactionTable = ({ cashpointID, values }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointID, pageNumber, values.from, values.to);
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointID, values]);

  const { data, lastElementRef, loading } = usePaginate(cashpointID, pageNumber, setPageNumber, payouts, values);

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(publickey, currentRecord.merchanttransactiondata[0]._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <div className="no-desktop-but-mobile">
      {data &&
        data.map((t, idx) => {
          if (uniqueTransactionDates.has(formatYear(t.createdon))) {
            return (
              <CashpointMobileTransactionItem
                key={idx}
                t={t}
                ref={data.length === idx + 1 ? lastElementRef : null}
                setCurrentRecord={setCurrentRecord}
                setShow={setShow}
                topLeft={t.name && t.name}
                bottomLeft={t.createdon && formatDay(t.createdon)}
                topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
                bottomRight={t.paymentstatus && t.paymentstatus}
                styles={{
                  color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                  textTransform: 'capitalize',
                }}
              />
            );
          } else {
            listDateInfo.current.add(formatYear(t.createdon));
            uniqueTransactionDates.add(formatYear(t.createdon));
            return (
              <div key={idx}>
                <DateRow debit="" credit={DATE_MAP[formatYear(t.createdon)]?.totalCredit} date={dayjs(t.createdon).format('DD/MMM/YYYY')} />
                <CashpointMobileTransactionItem
                  t={t}
                  ref={data.length === idx + 1 ? lastElementRef : null}
                  setCurrentRecord={setCurrentRecord}
                  setShow={setShow}
                  topLeft={t.name && t.name}
                  bottomLeft={t.createdon && formatDay(t.createdon)}
                  topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
                  bottomRight={t.paymentstatus && t.paymentstatus}
                  styles={{
                    color: t.paymentstatus === 'paid' ? '#47A08B' : t.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                    textTransform: 'capitalize',
                  }}
                />
              </div>
            );
          }
        })}
      {loading && <Spacer height={20} />}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
      {show && (
        <CashpointNewPopupDetails
          onClose={() => setShow(false)}
          totalAmount={`₦${formatNumber(currentRecord.orderamount)}`}
          status={currentRecord.paymentstatus}
          date={currentRecord.createdon}
          customer={currentRecord.name}
          id={currentRecord._id}
          merchantId={currentRecord?.merchanttransactiondata[0]?._id}
          type={currentRecord?.merchanttransactiondata[0]?.type}
          txnRef={currentRecord?.merchanttransactiondata[0]?.transactionref}
          description={currentRecord?.merchanttransactiondata[0]?.narrative}
          reversalexpireson={currentRecord?.merchanttransactiondata[0]?.reversalexpireson}
          createdon={currentRecord?.merchanttransactiondata[0]?.createdon}
          isLoading={isLoading}
          handleInitiateChallenge={handleInitiateChallenge}
        />
      )}
      {showChallengePopup && (
        <CashpointInitiateChallengePopup
          publickey={publickey}
          id={currentRecord.merchanttransactiondata[0]._id}
          onClose={() => {
            setShowChallengePopup(false);
            setShow(true);
          }}
          label={challengeDetails?.message}
          type={challengeDetails?.challenge_type}
        />
      )}
    </div>
  );
};

export const VirtualAccountFilteredHistory = ({ merchantID, values, transactionHeader, isRowClickable, transferType }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const dispatch = useDispatch();

  const payouts = useCallback(async () => {
    const res = await getVirtualAccountInfo(merchantID, values.from, values.to, transferType, false, pageNumber);
    if (transferType === 'transfer') {
      dispatch(resetCardPaymentCount());
      dispatch(setInboundPaymentCount(res?.data?.data?.count));
    }
    if (transferType === 'card') {
      dispatch(resetInboundTransfer());
      dispatch(setCardPaymentCount(res?.data?.data?.count));
    }
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values, transferType]);

  const totalCounts = useCallback(async () => {
    const res = await getInboundTransactionsCount(merchantID, values.from, values.to, '', false, pageNumber);
    dispatch(resetTotalTransferCount());
    dispatch(setTransferTotalCount(res?.data?.data?.count));

    //reset all transfer type when all radio is selected
    if (transferType === '') {
      dispatch(resetInboundTransfer());
      dispatch(resetCardPaymentCount());
    }
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values, transferType]);

  useEffect(() => {
    totalCounts();
    // return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantID, values, transferType]);

  const { data, lastElementRef, loading } = useVirtualAccountPaginate(merchantID, pageNumber, setPageNumber, payouts, values, transferType);

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);
  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className="desktop-merchant-cashpoint-trans no-mobile table-padding primary-bg-color full-width border-radius5 full-height overflow-auto fade-in">
            <div className="d-flex trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((customer, i) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return (
                        <DesktopMerchantVirtualTransactionList
                          key={i}
                          t={customer}
                          ref={data.length === i + 1 ? lastElementRef : null}
                          setShow={setShow}
                          setCurrentRecord={setCurrentRecord}
                          isRowClickable={isRowClickable}
                          currentRecord={currentRecord}
                        />
                      );
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <Fragment key={i}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <DesktopMerchantVirtualTransactionList
                            key={i}
                            t={customer}
                            ref={data.length === i + 1 ? lastElementRef : null}
                            setShow={setShow}
                            setCurrentRecord={setCurrentRecord}
                            isRowClickable={isRowClickable}
                            currentRecord={currentRecord}
                          />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {shouldCashpointModalOpen && <DeactivateCashpointPopup className="no-mobile" />}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no transactions"
                description={
                  <>
                    Your history page is empty.
                    <br /> No transaction matches your filter.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

export const MobileVirtualAccountFilteredHistory = ({ merchantID, values, transferType }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const dispatch = useDispatch();

  const payouts = useCallback(async () => {
    const res = await getVirtualAccountInfo(merchantID, values.from, values.to, transferType, false, pageNumber);
    if (transferType === 'transfer') {
      dispatch(resetCardPaymentCount());
      dispatch(setInboundPaymentCount(res?.data?.data?.count));
    }
    if (transferType === 'card') {
      dispatch(resetInboundTransfer());
      dispatch(setCardPaymentCount(res?.data?.data?.count));
    }
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values, transferType]);

  const totalCounts = useCallback(async () => {
    const res = await getInboundTransactionsCount(merchantID, values.from, values.to, '', false, pageNumber);
    dispatch(resetTotalTransferCount());
    dispatch(setTransferTotalCount(res?.data?.data?.count));
    //reset all transfer type when all radio is selected
    if (transferType === '') {
      dispatch(resetInboundTransfer());
      dispatch(resetCardPaymentCount());
    }

    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values, transferType]);

  useEffect(() => {
    totalCounts();
    // return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantID, values, transferType]);

  const { data, lastElementRef, loading } = useVirtualAccountPaginate(merchantID, pageNumber, setPageNumber, payouts, values, transferType);

  // Daily Total Transaction
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);
  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className=" fade-in">
            <div>
              {data
                ? data.map((customer, idx) => {
                    if (uniqueTransactionDates.has(formatYear(customer.createdon))) {
                      return (
                        <CashpointMobileTransactionItem
                          key={idx}
                          t={customer}
                          ref={data.length === idx + 1 ? lastElementRef : null}
                          setCurrentRecord={setCurrentRecord}
                          setShow={setShow}
                          topLeft={customer.name && customer.name}
                          bottomLeft={customer.createdon && formatDay(customer.createdon)}
                          topRight={customer.orderamount && `₦${formatNumber(customer.orderamount)}`}
                          bottomRight={customer.paymentstatus && customer.paymentstatus}
                          styles={{
                            color: customer.paymentstatus === 'paid' ? '#47A08B' : customer.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                            textTransform: 'capitalize',
                          }}
                        />
                      );
                    } else {
                      listDateInfo.current.add(formatYear(customer.createdon));
                      uniqueTransactionDates.add(formatYear(customer.createdon));
                      return (
                        <Fragment key={idx}>
                          <DateRow debit="" credit={DATE_MAP[formatYear(customer.createdon)]?.totalCredit} date={dayjs(customer.createdon).format('DD/MMM/YYYY')} />
                          <CashpointMobileTransactionItem
                            key={idx}
                            t={customer}
                            ref={data.length === idx + 1 ? lastElementRef : null}
                            setCurrentRecord={setCurrentRecord}
                            setShow={setShow}
                            topLeft={customer.name && customer.name}
                            bottomLeft={customer.createdon && formatDay(customer.createdon)}
                            topRight={customer.orderamount && `₦${formatNumber(customer.orderamount)}`}
                            bottomRight={customer.paymentstatus && customer.paymentstatus}
                            styles={{
                              color: customer.paymentstatus === 'paid' ? '#47A08B' : customer.paymentstatus === 'pending' ? '#F79420' : '#01B2E6',
                              textTransform: 'capitalize',
                            }}
                          />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
        </>
      ) : (
        <>
          <>
            <Spacer className="no-desktop-but-mobile" height={50} />
            <div className="no-desktop-but-mobile">
              {!loading && (
                <EmptyView
                  title="no transactions"
                  description={
                    <>
                      Your history page is empty.
                      <br /> No transaction matches your filter.
                    </>
                  }
                />
              )}
              {loading && <Spacer height={20} className="no-desktop-but-mobile" />}
              <div className="no-desktop-but-mobile-flex align-center j-center no-desktop-but-mobile">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
            </div>
          </>
        </>
      )}
    </section>
  );
};
