import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormCheckBox, FormInput } from '../../../components/form_input/form_input';
import { ColouredBackground, FormLayout } from '../../../components/layout/layout';
import { useInput } from '../../../hooks/use_input';
import { useQuery } from '../../../hooks/use_query';
import useSyncState from '../../../hooks/use_sync_state';
import { fetchIndustryList } from '../../../redux/merchant/merchant_actions';
import { addDetails } from '../../../redux/user/user_actions';
import { firstFromValidate } from '../../../utils/validation_rules/signup_validate';

export const openNotificationWithIcon = (type, title, description, duration) => {
  notification[type]({
    message: title,
    description: description,
    style: { zIndex: '9999', borderRadius: '10px' },
    duration: duration ? duration : 4.7,
  });
};

export const PasswordRequirementText = (
  <>
    Password must be at least 8 characters long and must include at least an uppercase character, lowercase character, a number and any of the following special characters: @ or # or $ or * or . or &.
    Leading, trailing or middle spaces are not allowed.
  </>
);

export default function PrimarySignUp({ setPosition }) {
  const query = useQuery();
  const mid = query.get('ref');
  const _mid = query.get('mid');
  const history = useHistory();
  const dispatch = useDispatch();
  const signUpCredentials = useSelector((state) => state.user.signUpCredentials);
  const [error, setError] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const isGoodPassword = useSyncState(false);
  const firstnameProps = useInput(signUpCredentials.contactfirstname ?? '', 'text', error, setError);
  const lastnameProps = useInput(signUpCredentials.contactlastname ?? '', 'text', error, setError);
  const emailProps = useInput(signUpCredentials.email ?? '', 'text', error, setError);
  const phoneProps = useInput(signUpCredentials.businessphone ?? '', 'text', error, setError, true);
  const referralProps = useInput(signUpCredentials.referralcode ?? _mid ?? mid ?? '', 'text', error, setError);
  const passwordProps = useInput(signUpCredentials.password ?? '', 'password', error, setError);
  const confirmpasswordProps = useInput(signUpCredentials.confirmPassword ?? '', 'password', error, setError);

  const AGREE_TERMS_TEXT = (
    <>
      By clicking on the checkbox, you agree to VPay's
      <span className="c-pointer action-color" onClick={() => history.push('/terms-and-condition')}>
        {' '}
        Terms
      </span>{' '}
      of acceptable use
    </>
  );

  const info = {
    email: emailProps.value,
    password: passwordProps.value,
    contactfirstname: firstnameProps.value,
    contactlastname: lastnameProps.value,
    businessphone: phoneProps.value,
    referralcode: referralProps.value,
    confirmPassword: confirmpasswordProps.value,
  };

  useEffect(() => {
    // dispatch(clearDetails());
    dispatch(fetchIndustryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doChecksOnPassword = (password) => {
    let hasNumber = /\d/g.test(password);
    let hasUppercase = /[A-Z]/g.test(password);
    let has8 = password.length >= 8;
    // eslint-disable-next-line
    let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/.test(password);

    if (hasUppercase && hasNumber && has8 && hasSpecialCharacter) {
      isGoodPassword.set(true);
    } else {
      isGoodPassword.set(false);
    }
  };

  const oncheckboxChange = (e) => {
    setAcceptTerms(e.target.checked);
  };

  const onClick = (e) => {
    e.preventDefault();
    const error = firstFromValidate(info);
    setError(error);

    doChecksOnPassword(info.password);

    if (!/\S+@\S+\.\S+/.test(info.email) && info.email) {
      openNotificationWithIcon('warning', 'Warning', 'Email address is Invalid');
    }

    if ((info.businessphone.length !== 11 || info.businessphone[0] !== '0') && info.businessphone.length > 0) {
      openNotificationWithIcon('warning', 'Warning', "Phone number must start with '0' and must be 11 characters");
      return;
    }

    if (!/^[0-9A-Za-z_@-]+$/.test(info.contactfirstname) && info.contactfirstname) {
      openNotificationWithIcon('warning', 'Warning', "Name must not include space and special characters except '-' ");
      return;
    }

    if ((!/^[A-Za-z]+$/.test(info.contactfirstname) && info.contactfirstname) || (!/^[A-Za-z]+$/.test(info.contactlastname) && info.contactlastname)) {
      openNotificationWithIcon('warning', 'Warning', 'Name must not include space or numbers');
      return;
    }

    if (!/^[0-9A-Za-z_@-]+$/.test(info.contactlastname) && info.contactlastname) {
      openNotificationWithIcon('warning', 'Warning', "Name must not include space and special characters except '-' ");
      return;
    }

    if (info.password !== info.confirmPassword && info.password && info.confirmPassword) {
      openNotificationWithIcon('warning', 'Warning', "Passwords don't match");
    }
    if (info.password.indexOf(' ') !== -1 || (info.confirmPassword.indexOf(' ') !== -1 && (info.password || info.confirmPassword))) {
      openNotificationWithIcon('warning', 'Warning', 'Password must not contain any space. Please remove all leading, trailing or middle spaces.');
      return;
    }

    if (!isGoodPassword.get() && info.password) {
      openNotificationWithIcon('warning', 'Warning', PasswordRequirementText);
      return;
    }
    if (!acceptTerms) {
      openNotificationWithIcon('warning', 'Warning', 'Kindly accept VPay terms of use.');
      return;
    }

    if (Object.keys(error).length === 0) {
      // const { confirmPassword, ...detail } = info
      // dispatch(clearDetails());
      dispatch(addDetails(info));
      setPosition((prev) => prev + 1);
    }
  };

  if (window.location.hostname.includes('sandbox')) {
    return (
      <ColouredBackground bottomText="" linkText="Login" route="" onClick={() => {}}>
        <h3>
          You can't signup through sandbox. Visit <a href="https://www.vpay.africa/signup">www.vpay.africa/signup</a> to sign up
        </h3>
      </ColouredBackground>
    );
  }

  return (
    <ColouredBackground bottomText="Already have an account?" linkText="Login" route="/login" onClick={() => history.push('/forgot-password')}>
      <FormLayout headerText="create your account" buttonText="Create My Account" onClick={(e) => onClick(e)}>
        <FormInput label="First Name" name="contactfirstname" {...firstnameProps} />
        <FormInput label="Last Name" name="contactlastname" {...lastnameProps} />
        <FormInput label="Phone No" name="businessphone" {...phoneProps} />
        <FormInput label="Email Address" name="email" {...emailProps} />
        <FormInput label="Referral Code (Optional)" name="referralcode" {...referralProps} />
        <FormInput label="Password" isPassword name="password" type="password" {...passwordProps} />
        <FormInput label="Confirm Password" isPassword name="confirmPassword" type="password" {...confirmpasswordProps} />
        <FormCheckBox checktext={AGREE_TERMS_TEXT} oncheckboxChange={oncheckboxChange} />
      </FormLayout>
    </ColouredBackground>
  );
}

// export const BottomContent = () => (

// )
