export const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FALIURE: 'SIGN_IN_FALUIRE',
  SIGN_IN_START: 'SIGN_IN_START',

  CASHIER_SIGN_IN_SUCCESS: 'CASHIER_SIGN_IN_SUCCESS',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FALIURE: 'SIGN_OUT_FALIURE',

  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FALIURE: 'SIGN_UP_FALIURE',

  ADD_DETAILS: 'ADD_DETAILS',
  CLEAR_DETAILS: 'CLEAR_DETAILS',

  USER_PASSWORD_RESET_START: 'USER_PASSWORD_RESET_START',
  USER_PASSWORD_RESET_SUCCESS: 'USER_PASSWORD_RESET_SUCCESS',
  USER_PASSWORD_RESET_FAILURE: 'USER_PASSWORD_RESET_FAILURE',
};
