import { CheckCircleOutlined, DownloadOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Progress, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButton, { AppButtonIcon } from '../../../components/app_button/app_button';
import { LogTable } from '../../../components/bulk_table/bulk_table';
import { FormDropdown } from '../../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../../components/form_input/form_input';
import { Spacer } from '../../../components/layout/layout';
import Popup from '../../../components/popup/popup';
import { CSVFILELINK } from '../../../constants/title';
import { useInput } from '../../../hooks/use_input';
import {
  delPaymentGroupID,
  getAllCurrentBeneficiaries,
  getAllPaymentGroups,
  setBeneficiariesCount,
  setBonus,
  setBulkPaymentStatus,
  setCsvListPopup,
  setFailedBeneficiaries,
  setFlatrate,
  setLogPopup,
  setPayStatusLog,
  setPayTitle,
  setPaymentGroupID,
  setRefreshCount,
  setShowDeletePopup,
  setShowPinPopup,
  setSuccessfulBeneficiaries,
  setTotalUploadCount,
  setUploadStatus,
} from '../../../redux/merchant/bulk_payment.feature';
import {
  authorizePin,
  bulkPaymentStatus,
  createMerchantPaymentBeneficiary,
  createMerchantPaymentGroup,
  deleteBulkBeneficiaries,
  deletePaymentGroup,
  getOneMerchantPaymentGroup,
  payBulkGroup,
  uploadBeneficiariesExcel,
  uploadFileStatus,
  verifyBankDetails,
} from '../../../services/apiSevices';
import { bulkPaymentFormValidate } from '../../../utils/validation_rules/bulk_payment_validate';
import { openNotificationWithIcon } from '../../auth/primary_signup/primary_signup';
import { MerchantCard } from '../../cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import { InitialPinSetUp } from '../profile';
import { getBankCode } from '../settings';
import { BulkPinInputGrid } from '../withdraw';
import './all_popups.css';

export const NewGroupPopup = ({ handleClose }) => {
  const [error, setError] = useState({});
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const groupNameProps = useInput('', 'text', error, setError);
  const dispatch = useDispatch();
  const data = {
    name: groupNameProps.value,
    description: description,
  };

  const createGroup = async (data) => {
    try {
      const res = await createMerchantPaymentGroup(data, publickey);
      if (res?.data?.status) {
        dispatch(getAllPaymentGroups(publickey));
        openNotificationWithIcon('success', 'Success', `Payment Group Added Successfully`);
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', 'Error', `${error?.message}`);
    }
  };

  const onClick = async () => {
    const error = bulkPaymentFormValidate(data);
    setError(error);

    if (error.name) {
      openNotificationWithIcon('warning', 'Warning', `${error.name}`);
      return;
    }
    if (error.description) {
      openNotificationWithIcon('warning', 'Warning', `${error.description}`);
      return;
    }
    if (Object.keys(error).length === 0) {
      setLoading(true);
      await createGroup(data);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Popup flag={true}>
        <div className="font-size-20 text-bold">Create Group</div>
        <Spacer height={25} />
        <FormInput name="groupName" label="Group Name" className="important-full-width" {...groupNameProps} />
        <Spacer height={10} />
        <div className="input-label fw200">Description</div>
        <textarea name="groupDesc" className="message-box fw400 font-size-17 full-width" onChange={(e) => setDescription(e.target.value)}></textarea>
        <Spacer height={15} />
        <div className="add-group-btn-wrapper d-flex j-space-between align-center">
          <button className="cancel-btn text-color font-size-18 c-pointer" onClick={() => handleClose()}>
            Cancel
          </button>
          <AppButton isBusy={loading} isActive={true} onClick={() => onClick()} className="create-btn font-size-18 fw400 primary-color" name="Create" />
        </div>
      </Popup>
    </>
  );
};

export const MakePaymentPopup = ({ setShowMakePaymentPopup }) => {
  const { groupName } = useSelector((state) => state.bulkPayment);
  const dispatch = useDispatch();
  const payTitle = useInput('', 'text', null, null);
  const bonus = useInput('', 'number', null, null, true);
  const flatrate = useInput('', 'number', null, null, true);

  const handlePayGroup = () => {
    if (payTitle.value.length < 1) {
      return openNotificationWithIcon('error', 'Error', 'Title cannot be empty');
    }
    if (bonus.value > 0 && flatrate.value > 0) {
      return openNotificationWithIcon('error', 'Error', 'Please choose either bonus or flat rate value, or make them empty');
    }
    if (parseInt(bonus.value) === 0 || parseInt(flatrate.value) === 0) {
      return openNotificationWithIcon('error', 'Error', 'Bonus or Flat Rate cannot be zero');
    } else {
      dispatch(setShowPinPopup(true));
      dispatch(setBonus(bonus.value));
      dispatch(setFlatrate(flatrate.value));
      dispatch(setPayTitle(payTitle.value));
      setShowMakePaymentPopup(false);
    }
  };

  return (
    <>
      <Popup flag={true} className="">
        <FormInput name="title" inputClass="rm-margin" placeholder="Title" className="important-full-width" style={{ marginBottom: '10px !important' }} {...payTitle} />
        <FormInput name="title" inputClass="rm-margin" value={groupName} className="important-full-width" style={{ marginBottom: '10px !important' }} disabled={true} />
        <FormInput name="flatrate" inputClass="rm-margin" placeholder="Flat rate to pay group (optional)" className="important-full-width" style={{ marginBottom: '10px !important' }} {...flatrate} />
        <FormInput name="bonus" placeholder="Flat bonus to pay group (optional)" className="important-full-width" style={{ marginBottom: '10px !important' }} {...bonus} />
        <div className="add-group-btn-wrapper d-flex j-space-between align-center">
          <button
            className="cancel-btn text-color font-s16 c-pointer"
            onClick={() => {
              setShowMakePaymentPopup(false);
            }}
          >
            Cancel
          </button>
          <AppButton isActive={true} onClick={() => handlePayGroup()} className="create-btn font-s16 fw400 primary-color" name="Confirm" />
        </div>
      </Popup>
    </>
  );
};

export const NewPinPopup = () => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const [shouldSetPin, setShouldSetPin] = useState(false);
  const dispatch = useDispatch();
  const [paymentPercent, setPaymentPercent] = useState(0);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const { flatrate, payTitle, bonus, groupID } = useSelector((state) => state.bulkPayment);

  const cancelPin = () => {
    dispatch(setShowPinPopup(false));
  };

  const onClick = async () => {
    try {
      if (pin.length === 4) {
        setLoading(true);
        const req = {
          pin,
        };
        const data = {
          title: payTitle,
          group_id: groupID,
          bonus: bonus,
          flatrate: flatrate,
          pin: pin,
        };

        await authorizePin(key, req);
        const res = await payBulkGroup(data, key);
        if (res.status && res?.data.status) {
          let localCount = 0;
          let beneficiariesCount = res?.data.count;
          openNotificationWithIcon('info', 'Info', 'Bulk Payment In Progress, Please Wait A Bit');

          let intervalId = setInterval(async () => {
            try {
              if (localCount < beneficiariesCount) {
                let paymentRes = await bulkPaymentStatus(key, groupID, merchantID);
                if (paymentRes?.data?.data) {
                  localCount = paymentRes?.data?.data.length;
                  let percent = Math.round((localCount / beneficiariesCount) * 100);
                  if (percent > 100) {
                    percent = 100;
                    setPaymentPercent(percent);
                  }
                  setPaymentPercent(percent);
                  dispatch(setBulkPaymentStatus(paymentRes?.data?.data));
                }
              } else {
                clearInterval(intervalId);
                setLoading(false);
                openNotificationWithIcon('success', 'Info', 'Please Check Logs');
                setLoading(false);
                dispatch(setShowPinPopup(false));
                dispatch(setPayStatusLog(true));
              }
            } catch (error) {
              console.log(error, ' this is the error');
            }
          }, 8000);
        }
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <>
      {merchantDetails.issetpin ? (
        <Popup width="35%" flag={true} className="fade-in">
          <Spacer height={30} />
          <p className="fw400 font-size-17 text-center text-color no-margin">Enter Your Pin To Confim This Transaction</p>
          <Spacer height={25} />
          <div className="full-width d-flex align-center j-center">
            <BulkPinInputGrid setValue={setPin} />
          </div>
          <Spacer height={50} />
          <div className="add-group-btn-wrapper d-flex j-space-between align-center">
            <button className="cancel-btn text-color font-s16 c-pointer" onClick={() => cancelPin()} disabled={loading}>
              Cancel
            </button>
            <AppButton isBusy={loading} isActive={true} onClick={() => onClick()} className="create-btn font-s16 fw400 primary-color" name="Pay Group" />
          </div>
          {loading && (
            <Popup className="fade-in" flag={true} height="50vh">
              <p className="fw400 text-center text-color mb-20">Bulk Payment In Progress, Please Wait A Bit</p>
              <Spacer height={100} />
              <Progress status="active" percent={paymentPercent} />
            </Popup>
          )}
        </Popup>
      ) : (
        <InitialPinSetUp setShouldSetPin={setShouldSetPin} onClose={() => cancelPin()} />
      )}
    </>
  );
};

export const AddBeneficiaryItemPopup = ({ action, showAddBeneficiary, setShowAddBeneficiary, title, editBeneficiary, setEditBeneficiary, banks }) => {
  const [bankName, setBankName] = useState('');
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const account_number = useInput('', null, null, null, true);
  const amount = useInput('', null, null, null, true);
  const [isBankDataVerified, setIsBankDataVerified] = useState(false);
  const [verifiedBankName, setVerifiedBankName] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const allBanks = useSelector((state) => state.merchant.banks);
  const { groupID } = useSelector((state) => state.bulkPayment);

  const dispatch = useDispatch();

  const formDetails = {
    beneficiary_account_name: verifiedBankName,
    beneficiary_bank: getBankCode(bankName, allBanks),
    beneficiary_account_number: account_number.value,
    amount: amount.value,
    group: groupID,
  };

  // this clone hold the current group id before we delete the group id and add again to cause a re-render effect
  const groupIdClone = groupID;
  const handleAddBeneficiary = async () => {
    if (parseInt(formDetails.amount) === 0 || parseInt(formDetails.amount) < 0 || !formDetails.amount) {
      return openNotificationWithIcon('error', 'Error', 'Amount cannot be less that 0');
    }
    if (formDetails.beneficiary_account_number.length < 10 || formDetails.beneficiary_account_number.length > 10) {
      setVerifiedBankName('');
      setIsBankDataVerified(false);
      return openNotificationWithIcon('error', 'Error', 'Wrong Account Number');
    }
    if (isBankDataVerified) {
      try {
        setLoading(true);
        const res = await createMerchantPaymentBeneficiary(formDetails, publickey);
        if (res?.data) {
          const groupinfoRes = await getOneMerchantPaymentGroup(publickey, groupID);
          const newBeneficiaryCount = groupinfoRes?.data?.data?.beneficiary_count;
          //dispatch(getAllCurrentBeneficiaries(publickey, formDetails.group));
          dispatch(delPaymentGroupID());
          dispatch(setPaymentGroupID(groupIdClone));
          dispatch(setBeneficiariesCount(newBeneficiaryCount));
          setLoading(false);
          setShowAddBeneficiary(false);
          openNotificationWithIcon('success', 'Success', `Beneficiary Added Successfully.`);
        }
      } catch (error) {
        // getAllCurrentBeneficiaries(publickey, groupID);
        dispatch(getAllCurrentBeneficiaries(publickey, formDetails.group));
        setLoading(false);
        setShowAddBeneficiary(false);
        openNotificationWithIcon('error', 'Error', `${error?.response?.data?.message}`);
      }
    } else if (!isBankDataVerified) {
      openNotificationWithIcon('error', 'Error', 'Incorrect Bank Details');
    }
  };

  const openUploadListPopup = () => {
    setShowAddBeneficiary(false);
    dispatch(setCsvListPopup(true));
  };
  const closePopup = () => {
    if (showAddBeneficiary) {
      setShowAddBeneficiary(false);
    }
    if (editBeneficiary) {
      setEditBeneficiary(false);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (bankName && account_number.value) {
        if (account_number.value.length > 10) {
          setVerifiedBankName('');
          setIsBankDataVerified(false);
          return openNotificationWithIcon('error', 'Error', 'Account number more than 10');
        }
        if (account_number.value.length < 10 || account_number.value.length === 9) {
          setVerifiedBankName('');
          setIsBankDataVerified(false);
          return;
        }
        if (account_number.value.length === 10) {
          const data = {
            accountnumber: account_number.value,
            bankcode: getBankCode(bankName, allBanks),
          };
          try {
            setIsBusy(true);
            const res = await verifyBankDetails(data);
            if (res.data.accountname === '') {
              setIsBusy(false);
              return openNotificationWithIcon('error', 'Error', 'Account not found');
            }
            setVerifiedBankName(res.data.accountname);
            setIsBankDataVerified(true);
            setIsBusy(false);
          } catch (error) {
            setIsBusy(false);
            setIsBankDataVerified(false);
            openNotificationWithIcon('error', 'Error', error?.response?.data?.message ?? 'An error occured');
          }
        }
      }
    };
    verify();
    return () => {
      setVerifiedBankName('');
      setIsBankDataVerified(false);
      setIsBusy(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankName, account_number.value]);

  return (
    <Popup flag={true} className="slidee-in">
      <div className="font-size-20 text-bold">{title}</div>
      <Spacer height={15} />
      {isBankDataVerified && <FormInput disabled readonly name="accountName" inputClass="rm-margin" placeholder="Account Name" className="important-full-width" value={verifiedBankName} />}
      <FormDropdown onChange={(e) => setBankName(e)} name="bank" className="important-full-width rm-margin" placeholder="Select Bank" options={banks} />
      <FormInput name="accountNumber" inputClass="rm-margin" placeholder="Account Number" className="important-full-width" {...account_number} />
      {isBankDataVerified && (
        <>
          <span className="important-full-width nav-item-color fw400 ">{verifiedBankName}</span>
          <Spacer height={5} />
        </>
      )}
      {isBusy && !isBankDataVerified && <LoadingOutlined />}
      <FormInput name="amount" inputClass="rm-margin" placeholder="Amount" className="important-full-width" {...amount} />
      <AppButtonIcon
        icon={<UploadOutlined style={{ fontSize: '14px', alignSelf: 'center', color: '#FF1C26' }} />}
        name="Upload List"
        margin={30}
        className="d-flex j-space-between p10 fw400"
        backgroundColor="rgba(32, 14, 50, 0.03)"
        onClick={() => openUploadListPopup()}
        isActive={true}
        isBusy={false}
      />

      <Spacer height={50} />
      <div className="add-group-btn-wrapper d-flex j-space-between align-center">
        <button className="cancel-btn text-color c-pointer" onClick={() => closePopup()}>
          Cancel
        </button>
        <AppButton isBusy={loading} isActive={true} onClick={() => handleAddBeneficiary()} className="primary-color create-btn fw400" name={action} />
      </div>
    </Popup>
  );
};

// not needed for now
export const EditBeneficiaryItemPopup = ({ action, showAddBeneficiary, setShowAddBeneficiary, title, editBeneficiary, setEditBeneficiary, banks }) => {
  const allBanks = useSelector((state) => state.merchant.banks);
  const { loadingForOneBeneficiary, currentBeneficiary } = useSelector((state) => state.bulkPayment);
  const defaultAccountName = currentBeneficiary.name ? currentBeneficiary.name : '';
  const defaultAccountNumber = currentBeneficiary.accountNumber ? currentBeneficiary.accountNumber : '';
  const defaultBank = currentBeneficiary.bankName ? currentBeneficiary.bankName : '';
  const defaultAmount = currentBeneficiary.amount ? currentBeneficiary.amount : '';

  const [bankName, setBankName] = useState('');
  const [, setPrevAccountName] = useState('');
  const [prevAccountNumber, setPrevAccountNumber] = useState('');
  const [prevAmount, setPrevAmount] = useState(0);
  const [isBankDataVerified, setIsBankDataVerified] = useState(false);
  const [verifiedBankName, setVerifiedBankName] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [loading] = useState(false);

  useEffect(() => {
    setPrevAccountNumber(defaultAccountNumber);
    setPrevAmount(defaultAmount);
    setBankName(defaultBank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAmount, defaultBank]);

  const handleEditSubmit = async () => {};

  const closePopup = () => {
    if (showAddBeneficiary) {
      setShowAddBeneficiary(false);
    }
    if (editBeneficiary) {
      setEditBeneficiary(false);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (bankName && prevAccountNumber) {
        if (prevAccountNumber.length === 10) {
          const data = {
            accountnumber: prevAccountNumber,
            bankcode: getBankCode(bankName, allBanks),
          };
          try {
            setIsBusy(true);
            const res = await verifyBankDetails(data);
            if (res.data.accountname === '') {
              setIsBusy(false);
              return openNotificationWithIcon('error', 'Error', 'Account not found');
            }
            setVerifiedBankName(res.data.accountname);
            setIsBankDataVerified(true);
            setPrevAccountName(res.data.accountname);
            setIsBusy(false);
          } catch (error) {
            setIsBusy(false);
            setIsBankDataVerified(false);
            openNotificationWithIcon('error', 'Error', error?.response?.data?.message ?? 'An error occured');
          }
        }
      }
    };

    verify();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAccountNumber, bankName]);

  return (
    <>
      {loadingForOneBeneficiary ? (
        <LoadingOutlined />
      ) : (
        <Popup flag={true} className="slide-in">
          <div className="font-size-20 text-bold">{title}</div>
          <Spacer height={15} />
          {isBankDataVerified && (
            <FormInput
              disabled
              readonly
              value={defaultAccountName}
              onChange={(e) => setBankName(e)}
              name="accountName"
              inputClass="rm-margin rm-input-padding-x "
              placeholder="Account Name"
              className="important-full-width"
            />
          )}
          <FormDropdown defaultValue={defaultBank} name="bank" className="important-full-width rm-margin" placeholder="Select Bank" options={banks} />
          <FormInput
            value={prevAccountNumber}
            onChange={(e) => setPrevAccountNumber(e.target.value)}
            name="accountNumber"
            inputClass="rm-margin rm-input-padding-x "
            placeholder="Account Number"
            className="important-full-width"
          />
          {isBankDataVerified && (
            <>
              <span className="important-full-width nav-item-color fw400 ">{verifiedBankName}</span>
              <Spacer height={5} />
            </>
          )}
          {isBusy && !isBankDataVerified && <LoadingOutlined />}
          <FormInput value={prevAmount} onChange={(e) => setPrevAmount(e.target.value)} name="amount" inputClass="rm-margin" placeholder="Amount" className="important-full-width" />

          <Spacer height={50} />
          <div className="add-group-btn-wrapper d-flex j-space-between align-center">
            <button className="cancel-btn text-color c-pointer" onClick={() => closePopup()}>
              Cancel
            </button>
            <AppButton isBusy={loading} isActive={true} onClick={() => handleEditSubmit()} className="create-btn fw400 primary-color" name={action} />
          </div>
        </Popup>
      )}
    </>
  );
};

export const DeleteBeneficiaryPopup = () => {
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const { beneficiary_id, groupID } = useSelector((state) => state.bulkPayment);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // this clone hold the current group id before we delete the group id and add again to cause a re-render effect
  const groupIdClone = groupID;

  const handleDelete = async (key, merchant_id) => {
    try {
      setLoading(true);
      const res = await deleteBulkBeneficiaries(key, merchant_id);
      if (res?.data?.data) {
        const groupinfoRes = await getOneMerchantPaymentGroup(publickey, groupID);
        const newBeneficiaryCount = groupinfoRes?.data?.data?.beneficiary_count;
        openNotificationWithIcon('success', 'Success', 'Beneficiary Deleted Successfully');
        //dispatch(getAllCurrentBeneficiaries(publickey, groupID));
        dispatch(setBeneficiariesCount(newBeneficiaryCount));
        dispatch(delPaymentGroupID());
        dispatch(setPaymentGroupID(groupIdClone));
      }
      setLoading(false);
      dispatch(setShowDeletePopup(false));
    } catch (error) {
      openNotificationWithIcon('error', 'Error', `${error?.response?.data?.message}`);
      setLoading(false);
    }
  };
  return (
    <>
      <Popup flag={true} className="fade-in">
        <div className="d-flex j-center align-center flex-column">
          <div className="text-bold">Are you sure you want to delete this beneficiary?</div>
          <Spacer height={20} />
          <div className="d-flex j-space-between w-50">
            <AppButton isActive={true} isBusy={false} onClick={() => dispatch(setShowDeletePopup(false))} className="ptb delBtn" name="No" />
            <AppButton isActive={true} isBusy={loading} onClick={() => handleDelete(publickey, beneficiary_id)} className="ptb delBtn" name="Yes" />
          </div>
        </div>
      </Popup>
    </>
  );
};

export const DeletePaymentPopup = () => {
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const { group_id_delete, refreshCount } = useSelector((state) => state.bulkPayment);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleDelete = async (key, group_id) => {
    try {
      setLoading(true);
      const res = await deletePaymentGroup(key, group_id);
      if (res?.data?.data) {
        openNotificationWithIcon('success', 'Success', 'Payment Group Deleted Successfully');
        dispatch(setRefreshCount(refreshCount + 1));
      }
      setLoading(false);
      dispatch(setShowDeletePopup(false));
    } catch (error) {
      openNotificationWithIcon('error', 'Error', `${error?.response?.data?.message}`);
      setLoading(false);
    }
  };
  return (
    <>
      <Popup flag={true} className="fade-in">
        <div className="d-flex j-center align-center flex-column">
          <div className="text-bold">Are you sure you want to delete this payment group?</div>
          <Spacer height={20} />
          <div className="d-flex j-space-between w-50">
            <AppButton isActive={true} isBusy={false} onClick={() => dispatch(setShowDeletePopup(false))} className="ptb delBtn" name="No" />
            <AppButton isActive={true} isBusy={loading} onClick={() => handleDelete(publickey, group_id_delete)} className="ptb delBtn" name="Yes" />
          </div>
        </div>
      </Popup>
    </>
  );
};

export const AddBeneficiaryListPopup = () => {
  //const popupRef = useRef(false);
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadList, setUploadList] = useState(null);
  const [skipDownload, setSkipDownload] = useState(0);
  const [buttonActive, setBtnActive] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(true);
  const dispatch = useDispatch();
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  const { groupID } = useSelector((state) => state.bulkPayment);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  // this clone hold the current group id before we delete the group id and add again to cause a re-render effect
  const groupIdClone = groupID;

  const background = 'linear-gradient(90deg, #AB3882 -2.86%, #FF1C26 60.36%, #F79420 114.82%)';
  const background2 = '#FFF0FA';
  const currentIcon =
    stage >= 1 ? <CheckCircleOutlined className="fade-in" style={{ fontSize: '2em', color: '#fff' }} /> : <DownloadOutlined className="fade-in" style={{ fontSize: '1.5em', color: '#fff' }} />;
  const currentIcon2 =
    stage === 2 ? <CheckCircleOutlined className="fade-in" style={{ fontSize: '2em', color: '#00BA48' }} /> : <UploadOutlined className="fade-in" style={{ fontSize: '1.5em', color: '#FF1C26' }} />;

  const onDownloadClick = async () => {
    try {
      let url = CSVFILELINK;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `VPay_EFT_Beneficiary_List_Sample.xlsx`); //set the attribute of the <a> link tag to be downloadable when clicked and name the sheet based on the date and time right now.
      document.body.appendChild(link);
      link.click(); //programmatically click the link so the user doesn't have to
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setStage(1);
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };
  const onUploadClick = (e) => {
    setUploadList(e.target.files[0]);
    setStage(2);
    setBtnActive(true);
  };

  const handleAddListBeneficiary = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    try {
      const res = await uploadBeneficiariesExcel(publickey, merchantId, groupID, data);
      return res?.data;
    } catch (error) {
      console.log(error?.response?.data?.error, ' error from uploading');
      openNotificationWithIcon('error', 'Error', `${error?.response?.data?.error}`);
      setLoading(false);
      dispatch(setCsvListPopup(false));
    }
  };

  useEffect(() => {
    if (skipDownload) {
      setStage(1);
    }
    if (!skipDownload) {
      setStage(0);
    }

    return () => {
      null;
    };
  }, [skipDownload]);

  const handleListBeneficiary = async () => {
    try {
      setLoading(true);
      const uploadRes = await handleAddListBeneficiary(uploadList);
      if (uploadRes?.count) {
        let uploadCount = uploadRes?.count;
        dispatch(setTotalUploadCount(uploadCount));
        let localCount = 0;
        openNotificationWithIcon('info', 'Info', 'Beneficiaries uploading, Please wait a bit....');
        setShowCancelBtn(false);
        let intervalId = setInterval(async () => {
          try {
            if (localCount < uploadCount) {
              let statusRes = await uploadFileStatus(publickey, groupID, merchantId);
              if (statusRes?.data?.data) {
                localCount = statusRes?.data?.data.length;
                let percent = Math.round((localCount / uploadCount) * 100);
                if (percent > 100) {
                  percent = 100;
                  setUploadPercentage(percent);
                }
                setUploadPercentage(percent);
                dispatch(setUploadStatus(statusRes?.data?.data));

                if (statusRes?.data?.data.status === false) {
                  dispatch(setFailedBeneficiaries(statusRes?.data?.data));
                } else if (statusRes?.data?.data.status) {
                  dispatch(setSuccessfulBeneficiaries(statusRes?.data?.data));
                }
              }
            } else {
              clearInterval(intervalId);
              setLoading(false);
              const groupinfoRes = await getOneMerchantPaymentGroup(publickey, groupID);
              const newBeneficiaryCount = groupinfoRes?.data?.data?.beneficiary_count;
              dispatch(setCsvListPopup(false));
              //dispatch(getAllCurrentBeneficiaries(publickey, groupID));
              dispatch(getAllPaymentGroups(publickey));
              dispatch(setBeneficiariesCount(newBeneficiaryCount));
              dispatch(delPaymentGroupID());
              dispatch(setPaymentGroupID(groupIdClone));
              dispatch(setLogPopup(true));
            }
          } catch (error) {
            console.log(error);
          }
        }, 5000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popup flag={true}>
      <>
        <div className="font-size-20 text-bold">Add Beneficiary</div>
        <Spacer height={15} />
        <MerchantCard
          topText={'Download List Template'}
          bottomText={'Download an editable template to add beneficairies to your group here'}
          height="8em"
          width="100%"
          background={background}
          className="pl-20 fade-in"
          center
          textWhite={true}
          left="22rem"
          onClick={() => onDownloadClick()}
          icon={currentIcon}
          isVisible
        />

        <Spacer height={10} />
        <div className="skip-download">
          <span className="pr-15 font-size-small">Already have the sample excel file? Skip Download </span> <Switch onChange={(checked) => setSkipDownload(checked)} />
        </div>
        <Spacer height={10} />
        {stage > 0 && (
          <MerchantCard
            topText={'Upload List '}
            bottomText={'Upload the edited list to add beneficairies to your group'}
            height="8em"
            width="100%"
            background={background2}
            className="pl-20 fade-in"
            center
            textWhite={false}
            left="22rem"
            onChange={(e) => onUploadClick(e)}
            icon={currentIcon2}
            needFileInput={true}
            isVisible
          />
        )}
        <Spacer height={50} />
        <div className="add-group-btn-wrapper d-flex j-space-between align-center">
          {showCancelBtn === true ? (
            <button className="cancel-btn text-color c-pointer" onClick={() => dispatch(setCsvListPopup(false))}>
              Cancel
            </button>
          ) : (
            <button className="cancel-btn text-color c-pointer">Cancel</button>
          )}
          {loading && (
            <Popup className="fade-in" flag={true} height="100%">
              <p className="fw400 text-center text-color mb-20">Uploading file, please wait</p>
              <Spacer height={50} />
              <Progress status="active" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} percent={uploadPercentage} />
            </Popup>
          )}
          <AppButton
            isActive={buttonActive}
            isBusy={loading}
            onClick={() => handleListBeneficiary()}
            className={` ${stage > 1 ? 'primary-color create-btn fw400' : 'disabled-popupBtn'} `}
            name="Continue"
          />
        </div>
      </>
    </Popup>
  );
};

export const LogPopup = () => {
  const dispatch = useDispatch();
  const headers = ['SN', 'Account No', 'Reason'];
  const { uploadStatus } = useSelector((state) => state.bulkPayment);

  let failedBeneficiaries = uploadStatus.filter((group) => group.status === false);
  let failedMessage = 'No Failed Uploads';
  let isUpload = true;
  return (
    <Popup width="45%" onClose={() => dispatch(setLogPopup(false))}>
      <LogTable headers={headers} data={failedBeneficiaries} title={'Failed Beneficiaries'} failedMsg={failedMessage} isUpload={isUpload} />
      <small className="small">Account Numbers can not have duplicates</small>
    </Popup>
  );
};

export const PayStatusLogPopup = () => {
  const dispatch = useDispatch();
  const headers = ['SN', 'Account Name', 'Reason'];
  const { bulkPaymentStatus } = useSelector((state) => state.bulkPayment);

  let failedPayment = bulkPaymentStatus.filter((group) => group.status === false);
  let failedMessage = 'No Failed Payment';
  return (
    <Popup width="45%" onClose={() => dispatch(setPayStatusLog(false))}>
      <LogTable headers={headers} data={failedPayment} title={'Failed Payments'} failedMsg={failedMessage} />
    </Popup>
  );
};
