import { LoadingOutlined } from '@ant-design/icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cameraIcon from '../../assets/images/camera.svg';
import { usePaginate } from '../../hooks/use_paginate';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { fetchMerchantCustomersCashpoints, repushWebhook } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { formatDay, formatYear } from '../../utils/helper';
import { Spacer } from '../layout/layout';
import { DateRow, EmptyView, TransactionTable } from '../merchant_widgets/merchant_widgets';
import './cashier_widget.css';

export const CashierTransactionTable = () => {
  return <TransactionTable />;
};

export const cashpointTotalTransactionByDate = (transactions, dates, total) => {
  if (!transactions.length) return null;
  dates.forEach((date) => {
    const creditTransactionsByDate = transactions.filter(
      // (transaction) => formatYear(transaction?.createdon) === date && transaction?.merchanttransactiondata[0]?.type === 'credit' && !transaction?.merchanttransactiondata[0].reversed,
      (transaction) => formatYear(transaction?.createdon) === date && transaction?.paymentstatus && !transaction?.reversed,
    );
    let totalCredit = creditTransactionsByDate.reduce((total, transaction) => total + transaction?.orderamount, 0);

    total = {
      ...total,
      [date]: {
        totalCredit,
      },
    };
  });

  return total;
};

export const CashpointMobileTransactionItem = React.forwardRef(({ noClick, topLeft, bottomLeft, topRight, bottomRight, styles, setShow, setCurrentRecord, t }, ref) => {
  const failureCodes = useSelector((state) => state.merchant.failureCodes);

  const onClick = () => {
    setCurrentRecord && setCurrentRecord(t);
    setShow && setShow(true);
  };

  return (
    <div ref={ref} onClick={!noClick ? onClick : () => {}} className="trans-item-wrapper">
      <div className="d-flex">
        {t?.merchanttransactiondata[0]?.issetselfie && (
          <div>
            <img src={cameraIcon} className="w-75" alt="an icon" />
          </div>
        )}
        <div className="trans-item ">
          <p className={`${t?.merchanttransactiondata[0]?.isqualifyforchallenge ? 'challenge-color' : ''} font-size-15 topLeft fw400`}>
            {t?.merchanttransactiondata[0]?.reversed && <span className="action-color">[Reversed]</span>} {topLeft}
          </p>
          <p className="bottomLeft fw400">{bottomLeft}</p>
        </div>
      </div>
      <div className="trans-item align-end">
        <p className="font-size-15 fw400">{topRight}</p>
        <p className="bottomRight fw400" style={styles}>
          {bottomRight}
        </p>
        {failureCodes.includes(t?.status) && <p className="bottomRight failedTag text-center fw400">Failed</p>}
      </div>
    </div>
  );
});

export const VirtualAccFailedWebhookItem = React.forwardRef(({ needPushBtn, topLeft, bottomLeft, topRight, setShow, setCurrentRecord, t }, ref) => {
  const [loading, setLoading] = useState(false);
  const [fadeout, setFadeout] = useState(false);

  const rePushClick = async () => {
    setCurrentRecord && setCurrentRecord(t);
    // setShow && setShow(true);
    if (!t?.merchant || t.merchanttransactiondata[0].length < 1) {
      return;
    }
    try {
      setLoading(true);
      const res = await repushWebhook(t?.merchant, t.merchanttransactiondata[0]?._id);
      // console.log(currentRecord?.merchant, currentRecord.merchanttransactiondata[0]?._id, " holla")
      if (res?.data?.status) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Webhook Pushed Successfully');
        setCurrentRecord(null);
        setFadeout(true);
      }
    } catch (error) {
      console.log(error, ' an error occurred');
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <div ref={ref} className={`trans-item-wrapper ${fadeout ? 'item-fadeout' : ''}`}>
      <div className="d-flex">
        <div className="trans-item ">
          <p className={`${t?.merchanttransactiondata[0]?.isqualifyforchallenge ? 'challenge-color' : ''} font-size-15 topLeft fw400`}>{topLeft}</p>
          <p className="bottomLeft fw400">{bottomLeft}</p>
        </div>
      </div>
      <div className="trans-item align-end">
        <p className="font-size-15 fw400">{topRight}</p>
        {needPushBtn && (
          <div onClick={() => rePushClick()}>
            <p
              className="d-flex j-center align-center font-size-13 fw400"
              style={{
                color: t?.merchanttransactiondata[0]?.issenttowebhook === true ? '#47A08B' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? '#fff' : '#47A08B',
                textTransform: 'capitalize',
                width: '100px',
                padding: '3px 20px !important',
                border: `1px solid ${
                  t?.merchanttransactiondata[0]?.issenttowebhook === true ? '#47A08B' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? 'var(--color-action)' : '#47A08B'
                }`,
                borderRadius: '25px',
                background: `${t?.merchanttransactiondata[0]?.issenttowebhook === false && 'var(--color-action)'}`,
              }}
            >
              {loading && <FontAwesomeIcon icon={faSpinner} spin={true} />}
              {t?.merchanttransactiondata[0]?.issenttowebhook === true ? 'Sent' : t?.merchanttransactiondata[0]?.issenttowebhook === false ? 'Push' : 'Sent'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

export const MobileCashierTransactionTable = ({ setCurrentRecord, setShow, shouldDisplay, noClick, emptyTitle, emptyDescription }) => {
  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};
  const [pageNumber, setPageNumber] = useState(1);

  const { cashpointid } = useSelector((state) => state.user.user);

  const payouts = useCallback(async () => {
    const res = await fetchMerchantCustomersCashpoints(cashpointid, pageNumber, { period: 'year' });
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cashpointid]);

  const { data, lastElementRef, loading } = usePaginate(cashpointid, pageNumber, setPageNumber, payouts);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => cashpointTotalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal]);

  return (
    <div className={`${!shouldDisplay ? 'no-desktop-but-mobile' : ''}`}>
      {data &&
        data.map((t, idx) => {
          if (uniqueTransactionDates.has(formatYear(t.createdon))) {
            return (
              <CashpointMobileTransactionItem
                key={idx}
                t={t}
                noClick={noClick}
                ref={data.length === idx + 1 ? lastElementRef : null}
                setCurrentRecord={setCurrentRecord}
                setShow={setShow}
                topLeft={t.name && t?.name}
                bottomLeft={t.createdon && formatDay(t.createdon)}
                topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
              />
            );
          } else {
            listDateInfo.current.add(formatYear(t.createdon));
            uniqueTransactionDates.add(formatYear(t.createdon));
            return (
              <div key={idx}>
                <DateRow debit="" credit={DATE_MAP[formatYear(t.createdon)]?.totalCredit} date={dayjs(t.createdon).format('DD/MMM/YYYY')} />
                <CashpointMobileTransactionItem
                  t={t}
                  noClick={noClick}
                  ref={data.length === idx + 1 ? lastElementRef : null}
                  setCurrentRecord={setCurrentRecord}
                  setShow={setShow}
                  topLeft={t.name && t?.name}
                  bottomLeft={t.createdon && formatDay(t.createdon)}
                  topRight={t.orderamount && `₦${formatNumber(t.orderamount)}`}
                />
              </div>
            );
          }
        })}
      {loading && <Spacer height={20} />}
      {data.length < 1 && (
        <EmptyView
          title={emptyTitle ?? 'no transactions'}
          description={
            emptyDescription ?? (
              <>
                Your history page is empty.
                <br /> Make a new transaction and find all your transactions here.
              </>
            )
          }
        />
      )}
      <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
    </div>
  );
};
