import imageCompression from 'browser-image-compression';

export async function compressImage(file, config) {
  try {
    const compressedFile = await imageCompression(file, config);
    return compressedFile;
  } catch (error) {
    console.error(error);
  }
}
