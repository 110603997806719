import { LoadingOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AppButton from '../../components/app_button/app_button';
import { BulkBeneficiariesList, PaymentGroups, TableSearchBar } from '../../components/bulk_table/bulk_table';
import { Spacer } from '../../components/layout/layout';
import { EmptyView, MerchantContentWrapper } from '../../components/merchant_widgets/merchant_widgets';
import { getAllPaymentGroups } from '../../redux/merchant/bulk_payment.feature';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import { MerchantCard } from '../cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import {
  AddBeneficiaryItemPopup,
  AddBeneficiaryListPopup,
  DeleteBeneficiaryPopup,
  DeletePaymentPopup,
  EditBeneficiaryItemPopup,
  LogPopup,
  MakePaymentPopup,
  NewGroupPopup,
  NewPinPopup,
  PayStatusLogPopup,
} from './bulk_payment/all_popups';
import { BulkDropDown } from './bulk_payment/bulk_dropdown';

import './index.css';
const { TabPane } = Tabs;

const MerchantBulkPayment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showMakePaymentPopup, setShowMakePaymentPopup] = useState(false);
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const { groups, loadingPaymentGroups, currentBeneficiaries, showDeletePopup, refreshCount } = useSelector((state) => state.bulkPayment);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const handleClose = () => {
    setShowPopup(false);
  };
  const openMakePayment = () => {
    setShowMakePaymentPopup(true);
  };

  const OperationsSlot = {
    right: <AppButton className="app-button action-color-2 button-nav-padding text-white fade-in" isActive={true} isBusy={false} name="Make Payment" onClick={() => openMakePayment()} />,
  };
  useEffect(() => {
    dispatch(getAllPaymentGroups(publickey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publickey, refreshCount]);

  return (
    <MerchantContentWrapper className="fade-in tab-settings fw400 full-vh no-mobile-but-desktop">
      <Tabs defaultActiveKey={state?.tab || 'Beneficiary'} tabBarExtraContent={currentBeneficiaries.length > 0 && OperationsSlot.right}>
        <TabPane tab="Beneficiary" key="Beneficiary">
          <div className="flex-vertical full-width d-flex j-center row-gap05">
            {!showPopup && groups.length < 1 ? (
              <div className="">
                <Spacer height={100} />
                {loadingPaymentGroups && <LoadingOutlined style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
                {!loadingPaymentGroups && (
                  <EmptyView
                    title="Create Group"
                    description={
                      <>
                        You have not created any Beneficiary Group
                        <br />
                        Click the button below to setup a new group.
                        <br />
                        <AppButton name="Create Group" onClick={() => setShowPopup(true)} isActive={true} className="cashier-change-btn mt-20" color="#FDFDFD" />
                      </>
                    }
                  />
                )}
              </div>
            ) : (
              <Segments setShowPopup={setShowPopup} groups={groups} />
            )}
            {showPopup && (
              <div className="full-width full-height d-flex flex-vertical align-center j-center">
                <NewGroupPopup handleClose={handleClose} />
              </div>
            )}
          </div>
        </TabPane>

        <TabPane tab="Payment Groups" key="Payment Groups">
          <div className="full-height flex-vertical full-width d-flex align-center j-center row-gap05">
            {groups.length < 1 ? (
              <div>
                <Spacer height={100} />
                <EmptyView
                  title="Create Group"
                  description={
                    <>
                      You do not have any Payment Group, click on the Beneficiary
                      <br />
                      to setup a new group.
                    </>
                  }
                />
                <Spacer height={15} />
              </div>
            ) : (
              <>
                <PaymentGroups groups={groups} />
                {showDeletePopup && <DeletePaymentPopup />}
              </>
            )}
          </div>
        </TabPane>
      </Tabs>

      <Spacer height={10} />
      {showMakePaymentPopup && <MakePaymentPopup setShowMakePaymentPopup={setShowMakePaymentPopup} groups={groups} />}
    </MerchantContentWrapper>
  );
};

export default MerchantBulkPayment;

export const Segments = ({ setShowPopup, groups }) => {
  const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
  const [, setShowList] = useState(false);
  const [editBeneficiary, setEditBeneficiary] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const [, setShowNewPinPop] = useState(false);
  const { groupID, showPinPopup, csvListPopup, logPopup, payStatusLog, groupName, showDeletePopup } = useSelector((state) => state.bulkPayment);
  const banks = useSelector((state) => state.merchant.banks);

  const mappedBanks = useMemo(() => banks.map((bank) => bank.name), [banks]);
  const checkForGroup = () => {
    return groupName !== 'No Group' ? setShowAddBeneficiary(true) : openNotificationWithIcon('error', 'Error', 'Please Select A group');
  };

  const bg = '#FFF0FA';

  const svg = (
    <svg width="18" height="11" viewBox="0 0 12 19" fill="#230B34" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.68472 2.10984C0.262629 1.71797 0.261159 1.08125 0.681436 0.687695C1.06351 0.329912 1.6626 0.296253 2.08549 0.587507L2.20668 0.684631L10.9067 8.76168C11.2915 9.11899 11.3266 9.6796 11.0117 10.0739L10.9067 10.1868L2.20674 18.2652C1.78469 18.6571 1.10181 18.6558 0.681499 18.2623C0.299397 17.9045 0.265829 17.3458 0.579981 16.9527L0.684657 16.8401L8.6168 9.47388L0.68472 2.10984Z"
        fill="#200E32"
      />
    </svg>
  );

  return (
    <div>
      {groups.length >= 1 && (
        <div className="fade-in">
          <div>
            <div className="bulk-row d-flex pad2020">
              <BulkDropDown />
              <Spacer width={20} />
              <MerchantCard
                topText="Create Group"
                bottomText={'Create Payment Group'}
                width="350px"
                height="127.47px"
                background={bg}
                className="boxShadow c-pointer"
                center
                textWhite={false}
                left="22em"
                icon={svg}
                cardClick={() => setShowPopup(true)}
                fontSizeBottom={true}
                isVisible
              />
            </div>
            <div className="beneficiary-table full-width pad2020">
              <TableSearchBar
                setSearchItem={setSearchItem}
                setShowAddBeneficiary={setShowAddBeneficiary}
                setShowList={setShowList}
                title="Add Beneficiary"
                showUpload={true}
                showAddBeneficiary={showAddBeneficiary}
                setEditBeneficiary={setEditBeneficiary}
                editBeneficiary={editBeneficiary}
                action="Add To Group"
                banks={mappedBanks}
              />
            </div>
            <BulkBeneficiariesList groupID={groupID} searchItem={searchItem} />
          </div>
          {editBeneficiary && (
            <EditBeneficiaryItemPopup
              setShowAddBeneficiary={setShowAddBeneficiary}
              setShowList={setShowList}
              title="Edit Beneficiary"
              showUpload={false}
              showAddBeneficiary={showAddBeneficiary}
              setEditBeneficiary={setEditBeneficiary}
              editBeneficiary={editBeneficiary}
              action="Done"
              banks={mappedBanks}
            />
          )}
          {showAddBeneficiary && (
            <AddBeneficiaryItemPopup
              setShowAddBeneficiary={setShowAddBeneficiary}
              setShowList={setShowList}
              title="Add Beneficiary"
              showUpload={true}
              showAddBeneficiary={showAddBeneficiary}
              setEditBeneficiary={setEditBeneficiary}
              editBeneficiary={editBeneficiary}
              action="Add To Group"
              banks={mappedBanks}
            />
          )}
          {showDeletePopup && <DeleteBeneficiaryPopup />}
          {csvListPopup && <AddBeneficiaryListPopup setShowList={setShowList} />}
          {showPinPopup && <NewPinPopup setShowNewPinPop={setShowNewPinPop} />}
          {logPopup && <LogPopup />}
          {payStatusLog && <PayStatusLogPopup />}
        </div>
      )}
    </div>
  );
};
