import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalance, fetchPayouts, fetchTransactions } from '../redux/merchant/merchant_actions';

export default function usePopup(value) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.user?.currentMerchant['businessid']);
  const [show, setShow] = useState(false);
  const onShow = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
    dispatch(fetchBalance(id));
    dispatch(fetchPayouts(id));
    dispatch(fetchTransactions(id));
  };
  return { ['on' + value + 'Show']: onShow, ['on' + value + 'Close']: () => onClose(), [value.toLowerCase() + 'Show']: show };
}
