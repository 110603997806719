import { Spacer } from '../layout/layout';
import './home_feature.css';
import dashFeature from '../../assets/images/dash-macbook.png';
import womanUsingVpay from '../../assets/images/vpayWoman.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import colors from '../../utils/color/colors';
import { Fragment } from 'react';
import { Features } from '../merchant_features/merchant_features';
import { FEATURES } from '../merchant_features/feature';

const Titles = [
  'Physical stores like supermarkets, POS agents, corner stores.',
  'Payment interface to websites and e-Commerce platforms such as Joomla, Magento, WordPress.',
  'Backend or back office integration to payment and  collections. Available to the developer community.',
];

export default function HomeFeature() {
  return (
    <div className="home-feature container mobile-spacing">
      <div className="d-flex align-center dash-flex-container flex-vertical-mobile j-center">
        <div className="dash-padding dash-left dash-margin">
          <p className="fw500 big-feature-text line-height12 primary-text no-margin">All-in-one payment solution platform for all businesses</p>
          <Spacer height={20} />
          <p className="feature-text fw400 text-font line-height17" style={{ maxWidth: '600px' }}>
            Payment via cash and card is the norm today. Our aim with VPay is to make payment via transfer, the number one channel for payments when shopping.
          </p>
          <Spacer height={30} />
          <KeyFeature title={Titles[0]} backgroundColor="#FFF6F7" headerText="VPay Retail" noCheck={true} />
          <Spacer height={30} />
          <KeyFeature title={Titles[1]} backgroundColor="#FFF6F7" headerText="VPay Web" noCheck={true} />
          <Spacer height={30} />
          <KeyFeature title={Titles[2]} backgroundColor="#FFF6F7" headerText="VPay API" noCheck={true} />
          <Spacer height={30} className="no-desktop-but-mobile" />
        </div>
        <Spacer width={20} />
        <div className="dash-right half-width">
          <img src={dashFeature} alt="desktop dashboard" className="dash-feature full-width" />
        </div>
      </div>
    </div>
  );
}

export const HomeInfo = () => (
  <div className="d-flex align-center container full-width flex-vertical-mobile woman-user-area inf0-c-padding j-space-between">
    <div className="half-width d-flex align-center woman-user-container">
      <img src={womanUsingVpay} className="woman-user" alt="woman using dart pay" />
    </div>
    <div className="half-width home-feature-text-area">
      <p className="fw500 big-info-text line-height17 text-color no-margin ">Features of VPay App</p>
      <Spacer height={25} />
      <div className="d-grid home-info-g">
        <Features features={FEATURES} />
      </div>
    </div>
  </div>
);

export const FeatureContent = ({ icon, title, description, className }) => {
  return (
    <div className={(className || '') + ' d-flex'}>
      <img src={icon} className="align-self-start dash-icon" alt={title} />
      <Spacer width={30} />
      <div>
        <p className="font-size-20 fw700">{title}</p>
        <Spacer height={15} />
        <p className="font-size-20">{description}</p>
      </div>
    </div>
  );
};

export const KeyFeature = ({ title, backgroundColor, headerText, noCheck }) => {
  return (
    <div className="d-flex full-width grid-gap-10">
      <div style={{ backgroundColor, height: '35px' }} className={`d-flex p10 align-center j-center ${noCheck && 'no-mobile-but-desktop'}`}>
        <FontAwesomeIcon color="#FF1C26" icon={faCheck} />
      </div>
      <Spacer width={20} className="no-mobile-but-desktop" />
      <div className="d-flex flex-vertical">
        {headerText && <h2 className="fw500 kfeature-header text-color d-block no-margin">{headerText}</h2>}
        <p className="no-margin fw400 k-feature-text">{title}</p>
      </div>
    </div>
  );
};

export const KeyFeatures = ({ content, noCheck }) => {
  // const { custom } = colors();
  // const color = custom.color('AB3882').withOpacity(0.05);
  return content.map((feature, index) => (
    <Fragment key={index}>
      <KeyFeature backgroundColor="#FFF7F7" title={feature} noCheck={noCheck} />
      <Spacer height={5} />
      {/* index < (kFeatures.length - 1) && */}
    </Fragment>
  ));
};
