// import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import reducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = configureStore({ reducer, middleware: middlewares });

// store.subscribe(() => localStorage.setItem("v_pay_token", store.getState().token));

export const persistor = persistStore(store);

// export default { store, persistor };
