import { useEffect, useRef, useState } from 'react';

export const useTimer = (startTime) => {
  const [time, setTime] = useState(startTime);
  const intervalID = useRef(null);
  const hasTimerEnded = time <= 0;
  const isTimerRunning = intervalID.current !== null;

  const update = () => {
    setTime((time) => time - 1);
  };

  const startTimer = () => {
    if (!hasTimerEnded && !isTimerRunning) {
      update();
      intervalID.current = setInterval(update, 1000);
    }
  };
  const stopTimer = () => {
    clearInterval(intervalID.current);
  };

  // clear interval when the timer ends
  useEffect(() => {
    if (hasTimerEnded) {
      stopTimer();
    }
    return () => stopTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimerEnded]);

  return {
    time,
    startTimer,
    stopTimer,
  };
};
