import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import navigation from '../../../assets/images/navigation.svg';
import AppNav from '../../../components/app_nav/app_nav';
import { Spacer } from '../../../components/layout/layout';
import { MobileDashboardNav } from '../../../components/navigation/navigation';
import { getUsableNavLink } from '../../../constants/title';
import { useNavigation } from '../../../hooks/use_ navigation';
import useOverflow from '../../../hooks/use_overflow';
import { loadCashierInfo } from '../../../redux/merchant/cashier.information';
import { tourSelector } from '../../../redux/tour/tour.feature';
import Bonus from '../../merchant/bonus';
import CashpointDashboard, { DashboardLayout } from '../cashpoint_dashboard/cashpoint_dashboard';
import CashpointSettings from '../cashpoint_settings/cashpoint_settings';
import CashpointSupport from '../cashpoint_support/cashpoint_support';
import CashpointTransactions from '../cashpoint_transactions/cashpoint_transactions';
import './cashpoint_wrapper.css';

const CashierDashboardWrapper = () => {
  const dispatch = useDispatch();
  // usePageLayout();
  const { links, keys } = getUsableNavLink(2);
  const {
    user: { cashpointid },
  } = useSelector((state) => state.user);
  const match = useRouteMatch();
  const BOTTOM_CARD_TEXT = 'Invite customers to transfer using VPay and Earn a percentage of the transaction fee';
  const { onNavMenuClick, navOpen, onBodyClick } = useNavigation();

  useEffect(() => {
    dispatch(loadCashierInfo(cashpointid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashpointid]);

  return (
    <DashBoardWrapper>
      <div className="no-desktop-but-mobile">
        <div className="d-flex justify-end pr-15 p-absolute z-index999 nav-button-wrapper">
          <button className="unstyle-button c-pointer" onClick={onNavMenuClick}>
            <img src={navigation} className="navigation-icon" alt="navigation button" />
          </button>
        </div>
        {navOpen !== null && (
          <MobileDashboardNav onClick={onBodyClick} className={navOpen ? 'nav-open' : 'nav-close'} BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT} links={links} navKeys={keys} isCashier={true} />
        )}
      </div>
      <div className="no-mobile">
        <AppNav BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT} links={links} navKeys={keys} isCashier={true} />
      </div>
      <DashboardLayout>
        <Spacer height={20} />
        <Switch>
          <Route exact path={`${match.path}`} component={CashpointDashboard} />
          <Route exact path={`${match.path}/transactions`} component={CashpointTransactions} />
          <Route exact path={`${match.path}/support`} component={CashpointSupport} />
          <Route exact path={`${match.path}/settings`} component={CashpointSettings} />
          <Route exact path={`${match.path}/bonus`} component={Bonus} />
        </Switch>
        <Spacer height={20} />
      </DashboardLayout>
    </DashBoardWrapper>
  );
};

export default CashierDashboardWrapper;

export function DashBoardWrapper({ children, background }) {
  useOverflow();
  const { visible = false } = useSelector(tourSelector);
  return (
    <div style={{ background: background || '#f7f7f7' }} className={`${visible ? 'tour-active' : ''} full-width dashboard-wrapper full-vh`}>
      {children}
    </div>
  );
}
