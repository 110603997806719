import { combineReducers } from 'redux';
import { merchantReducer } from './merchant/merchant_reducer';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import billReducer from './merchant/bill.features';
import bonusFeatureReducer from './merchant/bonus.feature';
import bulkPaymentFeatureReducer from './merchant/bulk_payment.feature';
import cashierInformationReducer from './merchant/cashier.information';
import cashierTransactionReducer from './merchant/cashier.transactions';
import customInboundReducer from './merchant/custom_inbound.feature';
import merchantReferralReducer from './merchant/merchant.referral';
import rolesReducer from './merchant/roles.feature';
import utilityReducer from './merchant/utility.features';
import merchantVirtualAccountReducer from './merchant/virtual_account.features';
import tourReducer from './tour/tour.feature';
import user_reducer from './user/user_reducer';

const persistConfig = {
  key: 'root', // key is just to tell the point inside our reducer object we want to start storing everything.
  storage,
  whitelist: ['merchant'],
};

const userPersistConfig = {
  key: 'user',
  storage: storage,
  blacklist: ['signUpCredentials'],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, user_reducer),
  merchant: merchantReducer,
  bills: billReducer,
  utilities: utilityReducer,
  cashierTransaction: cashierTransactionReducer,
  cashierInformation: cashierInformationReducer,
  tour: tourReducer,
  referral: merchantReferralReducer,
  bonus: bonusFeatureReducer,
  bulkPayment: bulkPaymentFeatureReducer,
  virtualAccount: merchantVirtualAccountReducer,
  roles: rolesReducer,
  customInboundReducer: customInboundReducer,
});

const reducer = persistReducer(persistConfig, rootReducer);
export default reducer;
