import { createSlice } from '@reduxjs/toolkit';
import { getCashpointInfo } from '../../services/apiSevices';

const cashierInformationSlice = createSlice({
  name: 'cashierInformation',
  initialState: {
    details: {},
    loading: false,
    error: null,
  },

  reducers: {
    setInformation: (state, { payload }) => {
      state.details = payload;
    },
    infoLoading: (state, { payload }) => {
      state.loading = payload;
    },
    infoError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

const cashierInformationReducer = cashierInformationSlice.reducer;

export const { setInformation, infoLoading, infoError } = cashierInformationSlice.actions;

const apply =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(infoLoading(true));
    try {
      const { data } = await remote();

      if (data) {
        dispatch(action(data));
        dispatch(infoLoading(false));
      } else {
        dispatch(infoError(data?.message));
        dispatch(infoLoading(false));
      }
    } catch (err) {
      dispatch(infoError(err.response?.data?.message));
      dispatch(infoLoading(false));
    }
  };

export const loadCashierInfo = (id) => async (dispatch) => {
  await apply({ remote: () => getCashpointInfo(id), action: setInformation })(dispatch);
};

export default cashierInformationReducer;
