import { faChevronLeft, faChevronRight, faEye, faEyeSlash, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Info from '../../assets/images/Info.svg';
import bank from '../../assets/images/bank.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import downArrow from '../../assets/images/downArrow.svg';
import downArrowWhite from '../../assets/images/shape.svg';
import AppButton from '../../components/app_button/app_button';
import SearchInput from '../../components/app_search/app_search';
import { FormCheckBox, FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import { CashpointNavigation, EmptyView, MerchantCashpointTransactionTable, MerchantContentWrapper, MobileMerchantCashpointTransactionTable } from '../../components/merchant_widgets/merchant_widgets';
import Popup from '../../components/popup/popup';
import { useFeatureFlag } from '../../hooks/use_feature_flag';
import { useInput } from '../../hooks/use_input';
import useVerifiedUser from '../../hooks/use_user_verified';
import {
  addCashier,
  closeCashpointPopup,
  closeCreateCashpoints,
  deleteCashier,
  fetchCashpointCustomer,
  fetchCashpoints,
  getAlternateCashpointAcctFees,
  openCashpointPopup,
  openCreateCashpoints,
  updateCurrentCashpoint,
} from '../../redux/merchant/merchant_actions';
import { getAlternateAccountForCashpoint } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { isNotAWebUser } from '../../utils/helper';
import { addCashpointValidate } from '../../utils/validation_rules/add_cashier_validate';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import { AlternateAccountCard, MerchantCard } from '../cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import './index.css';
import { getBankName } from './settings';
import { CashpointColumn } from './table_data';

const gradientBackground = 'linear-gradient(90deg, #AB3882 -2.86%, #FF1C26 60.36%, #F79420 114.82%)';

export default function MerchantCashpoint() {
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const currentCashpoint = useSelector((state) => state.merchant.currentCashpoint);
  const open = useSelector((state) => state.merchant.isOpenCreateCashpoints);
  const { cashpoints } = useSelector((state) => state?.merchant);
  const cashpointCustomers = useSelector((state) => state.merchant.cashpointCustomers);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const [showBalance, setShowBalance] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [values, setValues] = useState({ from: '', to: '' });
  const [showAltAccPopup, setShowAltAccPopup] = useState(false);
  const { isFlagRequired } = useFeatureFlag('web.create.alternate.account');
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo } = useVerifiedUser();

  // get merchant classification,
  const { classification } = useSelector((state) => state?.merchant?.merchantDetails);
  const isMerchantQualifiedForAlternateAcct = (classification === 'pos' || classification === 'retail') && cashpoints?.length > 0;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashpoints(id));
    dispatch(fetchCashpointCustomer(currentCashpoint !== null ? currentCashpoint._id : '0', { period: 'year' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    dispatch(getAlternateCashpointAcctFees(publickey, id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalTransactions = cashpointCustomers ? cashpointCustomers?.total : 0;

  const handleToggleBalance = (e) => {
    e.stopPropagation();
    setShowBalance((prev) => !prev);
  };
  const doesCashpointHaveProvidusAcct = () => {
    return currentCashpoint?.nubans?.length > 0;
  };
  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <MerchantContentWrapper className="full-height-minus-150 fade-in" beforeSidePadding={<CashpointNavigation onClick={() => openCreateCashpoints(dispatch)} />}>
      {!open && cashpoints.length < 1 && (
        <div className="no-desktop-but-mobile-flex j-end">
          <div className="pt-20">
            <AppButton className="action-color-2 button-nav-padding text-white" onClick={() => openCreateCashpoints(dispatch)} isActive name="Add A Cashpoint" />
          </div>
        </div>
      )}
      {!open && (
        <>
          <Spacer height={30} />
          <TopCards
            setShowAltAccPopup={setShowAltAccPopup}
            bottomText={showBalance ? (totalTransactions ? `₦${formatNumber(totalTransactions)}` : '₦0.00') : '₦****'}
            toggleBalanceIcon={<FontAwesomeIcon icon={showBalance ? faEyeSlash : faEye} className="c-pointer text-white" onClick={() => setShowBalance((prev) => !prev)} />}
          />
          <MobileTopCard
            transaction={showBalance ? `₦${formatNumber(totalTransactions)}` : '₦****'}
            toggleBalanceIcon={<FontAwesomeIcon icon={showBalance ? faEyeSlash : faEye} className="c-pointer text-white" onClick={handleToggleBalance} />}
          />
          <Spacer height={10} className="no-desktop-but-mobile" />
          {isFlagRequired && isMerchantQualifiedForAlternateAcct && !doesCashpointHaveProvidusAcct() && isVerified && <MobileAltAccountTrigger setShowAltAccPopup={setShowAltAccPopup} />}
          <Spacer height={30} />
          {cashpointCustomers?.data ? (
            cashpointCustomers?.data?.length < 1 ? (
              <div className="d-flex align-center j-center full-width full-height">
                <EmptyView
                  title="No Information Available"
                  description={
                    <>
                      Your cashpoint history page is empty.
                      <br /> Make a new transaction and find all your transactions here.
                    </>
                  }
                />
              </div>
            ) : (
              <>
                <MobileMerchantCashpointTransactionTable cashpointID={currentCashpoint?._id} values={values} />
                <MerchantCashpointTransactionTable cashpointID={currentCashpoint?._id} columns={CashpointColumn} values={values} />
              </>
            )
          ) : (
            <EmptyView
              title="No Information Available yet"
              description={
                <>
                  Your cashpoint history page is empty.
                  <br /> Make a new transaction and find all your transactions here.
                </>
              }
            />
          )}
          {shouldCashpointModalOpen && <DeactivateCashpointPopup />}
          {showAltAccPopup && <AlternateAccountPopup setShowAltAccPopup={setShowAltAccPopup} />}
        </>
      )}
      {open && (
        <div className="full-width full-height d-flex flex-vertical align-center j-center">
          <AddCashpointWrapper />
        </div>
      )}
    </MerchantContentWrapper>
  );
}

export const MobileTopCard = ({ transaction, toggleBalanceIcon }) => (
  <div className="no-desktop-but-mobile">
    <CashPointDropdown transaction={transaction} toggleBalanceIcon={toggleBalanceIcon} />
  </div>
);

export const TopCards = ({ toggleBalanceIcon, bottomText, setShowAltAccPopup }) => {
  const { classification } = useSelector((state) => state?.merchant?.merchantDetails);
  const isMerchantPosOrRetail = classification === 'pos' || classification === 'retail';
  const currentCashpoint = useSelector((state) => state.merchant.currentCashpoint);
  const { isFlagRequired } = useFeatureFlag('web.create.alternate.account');
  const doesCashpointHaveProvidusAcct = () => {
    return currentCashpoint?.nubans?.length > 0;
  };
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();
  const isCashpointAvailable = () => {
    return currentCashpoint !== null;
  };
  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <section className="d-flex align-center no-mobile">
      <CashPointDropdown />
      <Spacer width={20} />
      <MerchantCard
        toggleBalanceIcon={toggleBalanceIcon}
        background={gradientBackground}
        textWhite
        className="cashier-balance"
        height="120px"
        width="350px"
        topText="Total Recieved"
        bottomText={bottomText}
        center
        isVisible
      />
      <Spacer width={20} />
      {isCashpointAvailable() && isMerchantPosOrRetail && isFlagRequired && !doesCashpointHaveProvidusAcct() && isVerified && (
        <AlternateAccountCard height="120px" width="400px" setShowAltAccPopup={setShowAltAccPopup} />
      )}
    </section>
  );
};

export const DeactivateCashpointPopup = ({ className, title }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const cashpoints = useSelector((state) => state.merchant.cashpoints);
  const loading = useSelector((state) => state.merchant.loading);
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const [idToDelete, setIdToDelete] = useState(null);
  const dispatch = useDispatch();

  const onNext = (id) => {
    if (id === cashpoints[0]._id) {
      openNotificationWithIcon('warning', 'Warning', 'Cannot Deactivate Default Cashpoint');
      return;
    }
    setCurrentStep(currentStep + 1);
    setIdToDelete(id);
  };
  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };
  const onCancel = () => {
    closeCashpointPopup(dispatch);
    setCurrentStep(0);
  };

  const onDeactivateClick = () => {
    const data = {
      merchant: merchantID,
      cashpoint: idToDelete,
    };
    dispatch(deleteCashier(data));
  };

  let newTitle = title ? title : 'Deactivate Cashpoint';

  return (
    <div className={`p-relative ${className || ''}`}>
      {currentStep === 0 ? (
        <Popup className="cashlist-wrapper" title={newTitle} width="25%" onClose={onCancel}>
          <Divider />
          {cashpoints &&
            cashpoints.map((cashpoint, idx) => (
              <Fragment key={idx}>
                <div className="d-flex align-center j-space-between c-pointer" onClick={() => onNext(cashpoint._id)}>
                  <span className="font-size-17 fw400 text-color">{cashpoint.contactfirstname + ' ' + cashpoint.contactlastname}</span>
                  <img src={deleteIcon} alt="" />
                </div>
                <Spacer height={30} />
              </Fragment>
            ))}
          <button className="done-editing fw400 full-width primary-color c-pointer" onClick={onCancel}>
            Done Editing
          </button>
        </Popup>
      ) : (
        <Popup width="25%" onClose={onCancel}>
          <Spacer height={25} />
          <div className="d-flex flex-vertical align-center">
            <span className="fw400 font-size-20 text-color text-center">Do you really want to deactivate this cashier's profile?</span>
            <Spacer height={15} />
            <AppButton isActive={true} isBusy={loading} onClick={onDeactivateClick} className="done-editing font-size-17 fw400 full-width primary-color" name="Deactivate" />
            <Spacer height={15} />
            <span className="unstyle-button opacity-5 c-pointer fw400 font-size-17" onClick={onPrev}>
              Cancel
            </span>
          </div>
        </Popup>
      )}
    </div>
  );
};

export const AddCashpointWrapper = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const firstnameProps = useInput('', 'text', error, setError);
  const lastnameProps = useInput('', 'text', error, setError);
  const emailProps = useInput('', 'text');
  const phoneProps = useInput('', 'text', error, setError, true);
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const isBusy = useSelector((state) => state.merchant.loading);

  const data = {
    email: emailProps.value,
    phone: phoneProps.value,
    contactfirstname: firstnameProps.value,
    contactlastname: lastnameProps.value,
    merchant: merchantID,
  };

  const onClick = () => {
    const error = addCashpointValidate(data);
    setError(error);

    if (!/^[0-9A-Za-z_@-]+$/.test(data.contactfirstname) && data.contactfirstname) {
      openNotificationWithIcon('warning', 'Warning', "Name must not include space and special characters except '-' ");
      return;
    }
    if ((!/^[A-Za-z]+$/.test(data.contactfirstname) && data.contactfirstname) || (!/^[A-Za-z]+$/.test(data.contactlastname) && data.contactlastname)) {
      openNotificationWithIcon('warning', 'Warning', 'Name must not include numbers');
      return;
    }

    if (!/^[0-9A-Za-z_@-]+$/.test(data.contactlastname) && data.contactlastname) {
      openNotificationWithIcon('warning', 'Warning', "Name must not include space and special characters except '-'");
      return;
    }

    if ((data.phone.length !== 11 || data.phone[0] !== '0') && data.phone) {
      openNotificationWithIcon('warning', 'Warning', "Phone number must start with '0' and must be 11 characters");
      return;
    }
    if (Object.keys(error).length === 0) {
      dispatch(addCashier(data));
    }
  };
  return (
    <>
      <Spacer className="no-desktop-but-mobile" height={20} />
      <span className="fw400 text-color font-size-22">Create New Cashpoint</span>
      <Spacer height={30} className="no-desktop-but-mobile" />
      <div className="reg-form-margin-top border-radius5 add-cashier-form-padding primary-bg-color d-flex flex-vertical align-center">
        <div className="d-flex align-center">
          <FormInput label="First Name" {...firstnameProps} name="contactfirstname" className="create-cashier-input" />
          <Spacer width={10} />
          <FormInput label="Last Name" {...lastnameProps} className="create-cashier-input" name="contactlastname" />
        </div>
        <FormInput label="Phone Number" name="phone" {...phoneProps} />
        <FormInput label="Email Address (Optional)" name="email" {...emailProps} />
      </div>
      <Spacer height={50} />
      <div className="add-cashier-btn-wrapper d-flex j-space-between align-center">
        <button onClick={() => closeCreateCashpoints(dispatch)} className="cancel-btn text-color font-size-18 c-pointer">
          Cancel
        </button>
        <AppButton onClick={onClick} isActive={true} isBusy={isBusy} className="create-btn font-size-18 fw400 primary-color" name="Create" />
      </div>
    </>
  );
};

export const CashPointDropdown = ({ transaction, toggleBalanceIcon }) => {
  const { classification } = useSelector((state) => state?.merchant?.merchantDetails);
  const [showCashpoint, setShowCashpoint] = useState(false);
  const cashpoints = useSelector((state) => state.merchant.cashpoints);
  const currentCashpoint = useSelector((state) => state.merchant.currentCashpoint);
  const banks = useSelector((state) => state.merchant.banks);
  const { userIsWebUser, userType } = isNotAWebUser(classification);
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashpointCustomer(currentCashpoint !== null ? currentCashpoint._id : '0', { period: 'year' }));
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCashpoint && currentCashpoint._id]);

  const onToggle = () => {
    setShowCashpoint(!showCashpoint);
  };

  const onEditClick = () => {
    openCashpointPopup(dispatch);
    setShowCashpoint(!showCashpoint);
  };

  const onCashpointClick = (id) => {
    updateCurrentCashpoint(id, dispatch);
    setShowCashpoint(!showCashpoint);
  };

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  let allVerified = isVerified || hasBeenVerified;

  return (
    <div className="p-relative">
      {cashpoints.length > 0 && (
        <div className="cashpoint-dropdown primary-bg-color" style={{ minHeight: '120px' }}>
          <div className="d-flex align-center j-space-between c-pointer" onClick={onToggle}>
            <div className="top-name-area w-sm-100 w-90">
              <h3 className="fw400 text-color cashier-name">{currentCashpoint.contactfirstname + ' ' + currentCashpoint.contactlastname}</h3>
              <div className="d-flex align-center j-space-between no-desktop-but-mobile-flex w-sm-50">
                {transaction && <p className="cashier-transaction">{transaction}</p>}
                {toggleBalanceIcon}
              </div>
              <Spacer className="no-desktop-but-mobile" height={20} />
              {userIsWebUser && (
                <div className="no-mobile d-flex j-space-between">
                  {allVerified ? (
                    <span className="cashier-nuban purple_chip fw500">{`${currentCashpoint.nuban} | VFD MFB`}</span>
                  ) : (
                    <span className="cashier-nuban purple_chip fw500">{`Unverified Account`}</span>
                  )}
                  {/* {!allVerified && <span className="cashier-nuban purple_chip fw500">{`Unverified Account`}</span>} */}
                  {currentCashpoint?.nubans[0]?.nuban && (
                    <span className="cashier-nuban purple_chip fw500">{`${currentCashpoint?.nubans[0]?.nuban} | ${getBankName(currentCashpoint?.nubans[0]?.bank, banks)}`}</span>
                  )}
                </div>
              )}
              {userIsWebUser && (
                <div className="no-desktop-but-mobile-flex">
                  {allVerified ? (
                    <span className="cashier-nuban purple_chip fw500">{`${currentCashpoint.nuban} | VFD MFB`}</span>
                  ) : (
                    <span className="cashier-nuban purple_chip fw500">{`Unverified Account`}</span>
                  )}
                  {/* {isVerified && <span className="cashier-nuban purple_chip fw500">{`${currentCashpoint.nuban} | VFD MFB`}</span>}
                  {!isVerified && <span className="cashier-nuban purple_chip fw500">{`Unverified Account`}</span>} */}
                  <Spacer className="no-desktop-but-mobile" width={2} />
                  {currentCashpoint?.nubans[0]?.nuban && (
                    <span className="cashier-nuban purple_chip fw500">{`${currentCashpoint?.nubans[0]?.nuban} | ${getBankName(currentCashpoint?.nubans[0]?.bank, banks)}`}</span>
                  )}
                </div>
              )}
            </div>
            <img src={downArrow} alt="" className={`${showCashpoint && 'downArrow'} no-mobile`} />
            <img src={downArrowWhite} alt="" className={`${showCashpoint && 'downArrow'} no-desktop-but-mobile`} />
          </div>
        </div>
      )}
      {showCashpoint && cashpoints.length > 0 && (
        <div className="cashpoint-item-wrapper primary-bg-color" style={{ maxHeight: '400px' }}>
          <span className="edit-cashpoint no-desktop-but-mobile font-size-17 opacity-5 text-color c-pointer" onClick={() => openCreateCashpoints(dispatch)}>
            Add Cashpoints
          </span>
          <Spacer height={25} className="no-desktop-but-mobile" />
          {cashpoints &&
            cashpoints.map((cashpoint, idx) => (
              <Fragment key={idx}>
                <span onClick={() => onCashpointClick(cashpoint._id)} className="fw400 font-size-14 c-pointer text-color">
                  {cashpoint.contactfirstname + ' ' + cashpoint.contactlastname}
                </span>
                <Spacer height={5} />
                {userIsWebUser && isVerified && <span className="fw400 font-size-14 purple_chip mr-5">{`${cashpoint.nuban} | VFD MFB`}</span>}
                {cashpoint?.nubans[0]?.nuban && <span className="fw400 font-size-14 purple_chip">{`${cashpoint?.nubans[0]?.nuban} | ${getBankName(cashpoint?.nubans[0]?.bank, banks)}`}</span>}
                <Spacer height={25} />
              </Fragment>
            ))}
          <span className="edit-cashpoint font-size-15 opacity-5 text-color c-pointer" onClick={onEditClick}>
            Edit Cashpoints
          </span>
        </div>
      )}
    </div>
  );
};

export const AlternateAccountPopup = ({ setShowAltAccPopup }) => {
  // const [showCopy, setShowCopy] = useState(false);
  const [stage, setStage] = useState(1);
  const { nuban, merchant } = useSelector((state) => state?.merchant?.currentCashpoint);
  const { providus, outbound } = useSelector((state) => state?.merchant?.alternateAccountRate?.data);
  const { balance } = useSelector((state) => state?.merchant);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const banksList = [{ bank_name: 'Providus Bank' }];
  const checkForSufficientBalance = () => {
    return parseFloat(balance) > parseFloat(providus?.setup);
  };

  // bring in react query
  const reloadPageAfterFiveSeconds = () => {
    setTimeout(() => {
      location.reload();
      openNotificationWithIcon('success', 'Success', 'Refreshing page to get new Providus account number');
    }, 5000);
  };
  const validateCheck = async () => {
    if (checkForSufficientBalance()) {
      if (isChecked) {
        const body = {
          merchant: merchant,
          vfdvirtualaccount: nuban,
          banks: ['000023'],
        };
        try {
          setIsLoading(true);
          const res = await getAlternateAccountForCashpoint(body);
          if (res.status) {
            setIsLoading(false);
            openNotificationWithIcon(
              'success',
              'Success',
              'Your account request has been submitted  and will be processed shortly.  You will receive a push notification and SMS once the process is complete.',
            );
            setShowAltAccPopup(false);
            reloadPageAfterFiveSeconds();
          }
        } catch (error) {
          //console.log(error, 'error on adding banks');
          setIsLoading(false);
          openNotificationWithIcon('error', 'Error', error.response?.data?.message);
          setShowAltAccPopup(false);
        }
      } else {
        openNotificationWithIcon('error', 'Error', 'Please accept the terms');
      }
    } else {
      openNotificationWithIcon('error', 'Error', 'Insufficient Balance To Create An Alternate Account');
      return;
    }
  };

  return (
    <>
      {stage === 1 && (
        <Popup className="cashlist-wrapper fade-in" width="35em" flag={true}>
          <Spacer height={15} />
          <div className="fw400 d-flex j-space-between w-50">
            <span className="pr-40 c-pointer" onClick={() => setShowAltAccPopup(false)}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'var(--color-text-neutral)' }} />
            </span>
            <span className="d-flex j-center fw500">Bank</span>
          </div>
          <Spacer height={15} />
          <p className="text-center">Select a preferred bank to set up your alternate account number</p>
          <Spacer height={25} />
          <div className="w-80 margin-auto ">
            {banksList.map((item, idx) => {
              return (
                <div key={idx}>
                  <div className="d-flex j-space-between full-width c-pointer" onClick={() => setStage(2)}>
                    <div className="d-flex align-center">
                      <p className="circle-bg d-flex j-center align-center">
                        <FontAwesomeIcon icon={faUniversity} style={{ fontSize: '12px', color: '#F3000B' }} />
                      </p>
                      <Spacer width={10} />
                      <p className="fw400">{item.bank_name}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '14px', color: '#98A2B3' }} />
                    </div>
                  </div>
                  <Spacer height={14} />
                </div>
              );
            })}
            <Spacer height={20} />
          </div>
        </Popup>
      )}
      {stage === 2 && (
        <Popup maxHeight="95vh" className="fade-in" width="35em" flag={false} onClose={() => setShowAltAccPopup(false)}>
          <span className="pr-40 c-pointer" onClick={() => setStage(1)}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'var(--color-text-neutral)' }} />
          </span>
          <div className="d-flex j-center align-center">
            <div className="check d-flex j-center align-center">
              <img src={Info} alt="info" />
            </div>
          </div>
          <Spacer height={10} />
          <div className="fw400 text-center font-size-13">What to know before creating an alternative account number with "Providus Bank"</div>
          <Spacer height={15} />
          <div className="">
            <div className="d-flex j-space-between">
              <p className="fw400">Set-up fee</p>
              <p className="text-neutral font-size-13">₦{providus?.setup}</p>
            </div>
            <Divider />
            <div className="d-flex j-space-between">
              <p className="fw400">Inbound fee</p>
              <p className="text-neutral font-size-13">
                {providus?.fee}%, Capped at ₦{providus?.cap}
              </p>
            </div>
            <Divider />
            <div className="d-flex j-space-between">
              <p className="fw400">Oubound fee</p>
              <p className="text-neutral font-size-13">₦{outbound.fee} Flat</p>
            </div>
            <Divider />
            {/* <Spacer height={20} /> */}
            <div className="py-5 stale_purple_bg">
              <p className="text-center">
                <span className="font-size-small">Alternate accounts carry the name</span> <span className="fw500">"VPAY COLLECTIONS"</span>{' '}
              </p>
            </div>
            <Spacer height={20} />
            <FormCheckBox checktext="I agree to these terms" oncheckboxChange={(e) => setIsChecked(e.target.checked)} />
            <Spacer height={20} />
            <AppButton onClick={() => validateCheck()} name="Continue" isActive={true} isBusy={isLoading} className="primary-color fw500 upload-button action-color-bg full-width" />
            <Spacer height={5} />
          </div>
        </Popup>
      )}
      {/* {stage === 3 && (
        <Popup className="cashlist-wrapper fade-in" width="27em" flag={true}>
          <div className="d-flex j-center align-center">
            <div className="check d-flex j-center align-center">
              <FontAwesomeIcon icon={faCheck} style={{ color: '#F3000B', fontSize: '1rem' }} />
            </div>
          </div>
          <Spacer height={15} />
          <div className="fw400 text-center">Alternate account for {currentCashpoint.contactfirstname + ' ' + currentCashpoint.contactlastname}” has been created.</div>
          <Spacer height={15} />
          <div className="w-80 margin-auto">
            <div>
              <p className="text-neutral font-size-13">Bank Name</p>
              <p className="fw400">Providus Bank</p>
            </div>
            <Spacer height={14} />
            <div>
              <p className="text-neutral font-size-13">Account Number</p>
              <p className="fw400 d-flex j-space-between">
                98439696921{' '}
                <span className="c-pointer" onClick={() => copyText()}>
                  <FontAwesomeIcon icon={faCopy} style={{ color: '#F3000B' }} />
                  {showCopy && (
                    <p className="fw200i font-size-13" style={{ color: 'var(--color-success)' }}>
                      Copied
                    </p>
                  )}
                </span>
              </p>
            </div>

            <Spacer height={14} />
            <div>
              <p className="text-neutral font-size-13">Account Name</p>
              <p className="fw400">{currentCashpoint.contactfirstname + ' ' + currentCashpoint.contactlastname}</p>
            </div>
            <Spacer height={20} />
            <AppButton onClick={() => setShowAltAccPopup(false)} name="Done" isActive={true} className="primary-color fw500 upload-button action-color-bg full-width" />
            <Spacer height={5} />
          </div>
        </Popup>
      )} */}
    </>
  );
};

export const MobileAltAccountTrigger = ({ setShowAltAccPopup }) => {
  const { status, message } = useSelector((state) => state?.merchant?.alternateAccountRate);

  const checkForUserKYC = () => {
    let temp;
    if (status === false && message !== 'Success') {
      openNotificationWithIcon('error', 'Error', message);
      temp = false;
    } else if (status === true && message === 'Success') {
      temp = true;
      setShowAltAccPopup(true);
    }
    return temp;
  };

  return (
    <div className="no-desktop-but-mobile px4" onClick={() => checkForUserKYC()}>
      <div className="d-flex align-center j-space-between">
        <div className="d-flex j-center align-center">
          <div className="br-bg">
            <img src={bank} alt="bank" />
          </div>
          <Spacer width={5} />
          <p className="fw400 font-size-13">Generate alternate account number</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '14px', color: '#98A2B3' }} />
        </div>
      </div>
    </div>
  );
};

// move this to the virtual account on completion
export const VirtualAccountDropdown = ({ transaction, toggleBalanceIcon }) => {
  const [showCashpoint, setShowCashpoint] = useState(false);
  const banks = useSelector((state) => state.merchant.banks);
  const cashpoints = useSelector((state) => state.merchant.cashpoints);
  const currentCashpoint = useSelector((state) => state.merchant.currentCashpoint);
  const [customers, setCustomers] = useState(cashpoints);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashpointCustomer(currentCashpoint !== null ? currentCashpoint._id : '0', { period: 'year' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCashpoint && currentCashpoint._id]);

  const onToggle = () => {
    setShowCashpoint(!showCashpoint);
  };

  const onEditClick = () => {
    openCashpointPopup(dispatch);
    setShowCashpoint(!showCashpoint);
  };

  const onCashpointClick = (id) => {
    updateCurrentCashpoint(id, dispatch);
    setShowCashpoint(!showCashpoint);
  };

  const filteredCashpoint = (cashpointList, value) => {
    const data = cashpointList.filter((item) => {
      return item.nuban.includes(value.trim());
    });
    setCustomers(data);
  };

  return (
    <div className="p-relative">
      {cashpoints.length > 0 && (
        <div className="cashpoint-dropdown primary-bg-color">
          <div className="d-flex align-center j-space-between c-pointer" onClick={onToggle}>
            <div className="top-name-area">
              <h3 className="fw400 text-color cashier-name">{currentCashpoint.contactfirstname + ' ' + currentCashpoint.contactlastname}</h3>
              <div className="d-flex align-center j-space-between">
                {transaction && <p className="cashier-transaction">{transaction}</p>}
                {toggleBalanceIcon}
              </div>
              <span className="cashier-nuban text-color fw400 opacity-5">{`${currentCashpoint.nuban} | VFD MFB`}</span>
            </div>
            <img src={downArrow} alt="" className={`${showCashpoint && 'downArrow'} no-mobile`} />
            <img src={downArrowWhite} alt="" className={`${showCashpoint && 'downArrow'} no-desktop-but-mobile`} />
          </div>
        </div>
      )}
      {showCashpoint && cashpoints.length > 0 && (
        <div className="cashpoint-item-wrapper primary-bg-color" style={{ maxHeight: '400px' }}>
          {/* <span className="edit-cashpoint no-desktop-but-mobile font-size-17 opacity-5 text-color c-pointer" onClick={() => openCreateCashpoints(dispatch)}>
            Add Virtual Account
          </span> */}
          <span className="">
            <SearchInput placeholder="Find Virtual Account..." allowClear={true} onSearch={(value) => filteredCashpoint(cashpoints, value)} />
          </span>
          <Spacer height={25} className="no-desktop-but-mobile" />
          <Spacer height={25} className="no-mobile-but-desktop" />
          {customers &&
            customers.map((cashpoint, idx) => (
              <Fragment key={idx}>
                <span onClick={() => onCashpointClick(cashpoint._id)} className="fw400 font-size-14 c-pointer text-color">
                  {cashpoint.contactfirstname + ' ' + cashpoint.contactlastname}
                </span>
                <Spacer height={5} />
                <span className="fw400 font-size-14 action-color">{`${cashpoint.nuban} | VFD MFB`}</span>
                <br />
                {cashpoint?.nubans.length > 0 &&
                  cashpoint?.nubans?.map((item) => {
                    return (
                      <Fragment key={item._id}>
                        <span className="fw400 font-size-14 action-color">{`${item.nuban} | ${getBankName(item.bank, banks)}`}</span>
                      </Fragment>
                    );
                  })}
                <Spacer height={25} />
              </Fragment>
            ))}
          <span className="edit-cashpoint font-size-15 opacity-5 text-color c-pointer" onClick={onEditClick}>
            Edit Virtual Accounts
          </span>
        </div>
      )}
    </div>
  );
};

// move this to the virtual account on completion
export const VirtualAccountTopCards = ({ toggleBalanceIcon, bottomText }) => (
  <section className="d-flex align-center no-mobile">
    <VirtualAccountDropdown />
    <Spacer width={20} />
    <MerchantCard
      toggleBalanceIcon={toggleBalanceIcon}
      background={gradientBackground}
      textWhite
      className="cashier-balance"
      height="120px"
      width="350px"
      topText="Total Recieved"
      bottomText={bottomText}
      center
      isVisible
    />
  </section>
);
export const VirtualMobileTopCard = ({ transaction, toggleBalanceIcon }) => (
  <div className="no-desktop-but-mobile">
    <VirtualAccountDropdown transaction={transaction} toggleBalanceIcon={toggleBalanceIcon} />
  </div>
);
