import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

export default withRouter(function AuthGuard({ history, children }) {
  // eslint-disable-next-line
  const [token, setToken] = useState(() => localStorage.getItem('v_pay_token'));
  useEffect(() => {
    let token = localStorage.getItem('v_pay_token');
    if (!token) {
      history.push('/login');
    }
  });
  return <>{token ? children : null}</>;
});
