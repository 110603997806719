import { Route, Switch } from 'react-router-dom';
import AuthGuard from '../../services/auth/auth_guard';
import CashierDashboardWrapper from '../cashpoint_desktop/cashpoint_wrapper/cashpoint_wrapper';
import MerchantDashboard from '../merchant';

const Admin = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/merchant`}>
        <AuthGuard>
          <MerchantDashboard />
        </AuthGuard>
      </Route>
      <Route path={`${match.path}/cashpoint`}>
        <AuthGuard>
          <CashierDashboardWrapper />
        </AuthGuard>
      </Route>
    </Switch>
  );
};

export default Admin;
