import { useState } from 'react';
import { openNotificationWithIcon } from '../pages/auth/primary_signup/primary_signup';
import { uploadFilesToS3 } from '../services/apiSevices';
import { compressImage } from '../utils/compressImage';
import { getReadableFile } from '../utils/helper';

export const COMPRESSOR_CONFIG = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const useFormFile = (withSrc, fileTitle) => {
  const [file, setFile] = useState('');
  const [src, setSrc] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);

  const onChange = async (e) => {
    const file = e.target.files[0];

    if (file?.type === 'video/mp4') {
      openNotificationWithIcon('error', 'Error', 'Video Files are not allowed');
      return;
    } else if (file?.type.includes('image/')) {
      let newImage = await compressImage(file, COMPRESSOR_CONFIG);
      setFile(newImage);
    } else {
      setFile(file);
    }

    if (withSrc) {
      const reader = getReadableFile(e.target);
      if (reader) {
        reader.onloadend = function (_) {
          setSrc(reader.result);
        };
      }
    }
    if (e.target.name === 'otherdoc' && file) {
      const data = new FormData();
      data.append('file', file);

      try {
        const res = await uploadFilesToS3('OtherDocument', data);
        const fileResponse = res?.data?.data?.file_url;
        if (fileResponse) {
          setUploadedDocs([
            ...uploadedDocs,
            {
              url: fileResponse,
              type: fileTitle,
            },
          ]);
        }
        setFile('');
        setSrc(null);
      } catch (error) {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };
  return { file, onChange, src, uploadedDocs, setUploadedDocs };
};
