import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import downArrow from '../../../assets/images/downArrow.svg';
import { Spacer } from '../../../components/layout/layout';
import {
  getAllCurrentBeneficiaries,
  resetCurrentBeneficiaries,
  setBeneficiariesCount,
  setPaymentGroupDesc,
  setPaymentGroupID,
  setPaymentGroupName,
} from '../../../redux/merchant/bulk_payment.feature';
import './all_popups.css';

export const BulkDropDown = () => {
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);

  const [showBulkGroup, setShowBulkGroup] = useState(false);
  const { groups, groupName, groupID, groupDescription } = useSelector((state) => state.bulkPayment);

  const getCurrentBeneficiaries = useCallback(
    (publicKey, groupID) => {
      groupID && dispatch(getAllCurrentBeneficiaries(publicKey, groupID));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupID],
  );
  useEffect(() => {
    if (groups.length >= 1) {
      let firstGroup = groups[0];
      let id = firstGroup._id;
      let name = firstGroup.name;
      let desc = firstGroup.description;
      let count = firstGroup.beneficiary_count;
      dispatch(setPaymentGroupID(id));
      dispatch(setPaymentGroupDesc(desc));
      dispatch(setPaymentGroupName(name));
      dispatch(setBeneficiariesCount(count));
      getCurrentBeneficiaries(publickey, groupID);
    }
    return () => {
      dispatch(resetCurrentBeneficiaries());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (groupID.length > 1) {
      getCurrentBeneficiaries(publickey, groupID);
    }
    return () => {
      dispatch(resetCurrentBeneficiaries());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupID, publickey]);

  const dispatch = useDispatch();
  const onToggle = () => {
    setShowBulkGroup(!showBulkGroup);
  };

  const onGroupNameClick = (group) => {
    dispatch(setPaymentGroupID(group._id));
    dispatch(setPaymentGroupName(group.name));
    dispatch(setPaymentGroupDesc(group.description));
    dispatch(setBeneficiariesCount(group.beneficiary_count));
    setShowBulkGroup(!showBulkGroup);
  };

  return (
    <div className="p-relative">
      <div className="bulk-dropdown gradient-bg-color">
        <div className="d-flex align-center j-space-between c-pointer" onClick={onToggle}>
          <div className="top-name-area">
            <h3 className="fw400 text-white cashier-name">{groupName}</h3>
            <span className="cashier-nuban text-faded fw400">{`${groupDescription} `}</span>
          </div>
          {false && <img src={downArrow} alt="" className={`${showBulkGroup && 'downArrow'} no-mobile `} />}
          {!showBulkGroup ? <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /> : <FontAwesomeIcon icon={faAngleUp} className="dropdown-arrow" />}
        </div>
      </div>

      {showBulkGroup && groups.length > 0 && (
        <div className={`bulk-group-wrapper primary-bg-color d-flex flex-vertical fade-in ${showBulkGroup ? 'fade-in' : 'fade-out'} `}>
          {groups &&
            groups.map((group, idx) => (
              <Fragment key={idx}>
                <div className="bulk-group-item fade-in">
                  <span onClick={() => onGroupNameClick(group)} className="fw400 font-size-14 c-pointer text-color">
                    {group.name}
                    <Spacer height={5} />
                    <span className="fw400 font-size-14 action-color">{`${group.description}`}</span>
                    <Spacer height={25} />
                  </span>
                </div>
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

// export const BulkDropDown = () => {
//   const publickey = useSelector((state) => state.user.currentMerchant['publickey']);

//   const [showBulkGroup, setShowBulkGroup] = useState(false);
//   const { groups, groupName, groupID, groupDescription } = useSelector((state) => state.bulkPayment);

//   const getCurrentBeneficiaries = useCallback(
//     (publicKey, groupID) => {
//       groupID && dispatch(getAllCurrentBeneficiaries(publicKey, groupID));
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [groupID],
//   );
//   useEffect(() => {
//     if (groups.length >= 1) {
//       let firstGroup = groups[0];
//       let id = firstGroup._id;
//       let name = firstGroup.name;
//       let desc = firstGroup.description;
//       let count = firstGroup.beneficiary_count;
//       dispatch(setPaymentGroupID(id));
//       dispatch(setPaymentGroupDesc(desc));
//       dispatch(setPaymentGroupName(name));
//       dispatch(setBeneficiariesCount(count));
//       getCurrentBeneficiaries(publickey, groupID);
//     }
//     return () => {
//       dispatch(resetCurrentBeneficiaries());
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [groups]);

//   useEffect(() => {
//     if (groupID.length > 1) {
//       getCurrentBeneficiaries(publickey, groupID);
//     }
//     return () => {
//       dispatch(resetCurrentBeneficiaries());
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [groupID, publickey]);

//   const dispatch = useDispatch();
//   const onToggle = () => {
//     setShowBulkGroup(!showBulkGroup);
//   };

//   const onGroupNameClick = (group) => {
//     dispatch(setPaymentGroupID(group._id));
//     dispatch(setPaymentGroupName(group.name));
//     dispatch(setPaymentGroupDesc(group.description));
//     dispatch(setBeneficiariesCount(group.beneficiary_count));
//     setShowBulkGroup(!showBulkGroup);
//   };

//   return (
//     <div className="p-relative">
//       <div className="bulk-dropdown gradient-bg-color">
//         <div className="d-flex align-center j-space-between c-pointer" onClick={onToggle}>
//           <div className="top-name-area">
//             <h3 className="fw400 text-white cashier-name">{groupName}</h3>
//             <span className="cashier-nuban text-faded fw400 ">{`${groupDescription} `}</span>
//           </div>
//           {false && <img src={downArrow} alt="" className={`${showBulkGroup && 'downArrow'} no-mobile `} />}
//           {!showBulkGroup ? <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /> : <FontAwesomeIcon icon={faAngleUp} className="dropdown-arrow" />}
//         </div>
//       </div>

//       {showBulkGroup && groups.length > 0 && (
//         <div className={`bulk-group-wrapper primary-bg-color d-flex flex-vertical fade-in ${showBulkGroup ? 'fade-in' : 'fade-out'} `}>
//           {groups &&
//             groups.map((group, idx) => (
//               <Fragment key={idx}>
//                 <div className="bulk-group-item fade-in">
//                   <span onClick={() => onGroupNameClick(group)} className="fw400 font-size-14 c-pointer text-color">
//                     {group.name}
//                     <Spacer height={5} />
//                     <span className="fw400 font-size-14 action-color">{`${group.description}`}</span>
//                     <Spacer height={25} />
//                   </span>
//                 </div>
//               </Fragment>
//             ))}
//         </div>
//       )}
//     </div>
//   );
// };
