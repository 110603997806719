import { faChevronRight, faEye, faEyeSlash, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LogoutIcon from '../../../assets/images/Logout.svg';
import ProfileImage from '../../../assets/images/Profile.svg';
import avatar from '../../../assets/images/avatar.png';
import Avatar from '../../../assets/images/avatar2.png';
import emptyView from '../../../assets/images/emptyView.svg';
import notificationIcon from '../../../assets/images/notification.png';
import DashBoardCard from '../../../components/dashboard_card/dashboard_card';
import { UploadExcelFile } from '../../../components/image_upload/image_upload';
import { Spacer } from '../../../components/layout/layout';
import { MerchantContentWrapper, MerchantGraph, UserIcon } from '../../../components/merchant_widgets/merchant_widgets';
import { CashpointAccountInfoPopup, GeneratePaymentPopup } from '../../../components/popup/popup';
import TourWrapper from '../../../components/tour_wrapper/tour_wrapper';
import { CASHPOINT_MAPPING } from '../../../constants/title';
import { useOutsideRef } from '../../../hooks/useOutsideClick';
import { useCashierGrowth } from '../../../hooks/use_cashier_chart';
import { useTitleName } from '../../../hooks/use_title';
import { useTransactions } from '../../../hooks/use_transactions';
import { loadTransactions } from '../../../redux/merchant/cashier.transactions';
import { getCashierBanks } from '../../../redux/merchant/merchant_actions';
import { openCashpointQR } from '../../../redux/user/user_actions';
import { formatNumber } from '../../../utils/formatNumber';
import { formatDay } from '../../../utils/helper';
import { openNotificationWithIcon } from '../../auth/primary_signup/primary_signup';
import { CashierColumn } from '../../merchant/table_data';
import './cashpoint_dashboard.css';

export function MerchantCard({
  cardClick,
  className,
  background,
  height,
  width,
  center,
  topText,
  toggleBalanceIcon,
  bottomText,
  needOverlay,
  icon,
  top,
  left,
  right,
  bottom,
  onClick,
  textWhite,
  fontSizeBottom,
  needFileInput,
  onChange,
  needsToggle,
  isVisible,
}) {
  const [visible, setVisible] = useState(isVisible);

  return (
    <DashBoardCard onClick={cardClick} className={className} background={background} height={height} width={width} center={center} needOverlay={needOverlay}>
      <div className="d-flex align-center w-50">
        <div className="text-white">
          <h3 className={`d-flex align-center j-space-between no-margin line-height30 fw700 font-size-14 ${textWhite ? 'text-white' : 'text-black'} `}>
            <div className={`${visible ? 'fade-in-text' : ''}`}>{visible ? topText : '₦****'}</div>
            {needsToggle && (
              <FontAwesomeIcon
                icon={visible ? faEyeSlash : faEye}
                //className={`c-pointer text-white px-10 p-relative z-index1 ${visible ? 'fade-in-text' : ''} `}
                className={`c-pointer text-white p-relative z-index1 ${visible ? 'fade-in-text' : ''} `}
                onClick={() => setVisible(!visible)}
              />
            )}
          </h3>
          <p
            className={`d-flex align-center j-space-between no-margin fw400 text-capitalize ${textWhite ? 'text-faded' : 'text-faded2'}  ${
              fontSizeBottom ? 'font-size-13' : 'font-size-1em'
            } add-ellipsis`}
          >
            {bottomText}
            {toggleBalanceIcon}
          </p>
        </div>
        {icon && (
          <div className="p-absolute c-pointer" onClick={onClick} style={{ top: `${top}`, left: `${left}`, right: `${right}`, bottom: `${bottom}` }}>
            {icon}
          </div>
        )}
        {needFileInput && (
          <div className="p-absolute" onClick={onClick} style={{ top: `${top}`, left: `${left}`, right: `${right}`, bottom: `${bottom}` }}>
            <UploadExcelFile clickable={true} onChange={onChange} />
          </div>
        )}
      </div>
    </DashBoardCard>
  );
}

export const AlternateAccountCard = ({ height, width, setShowAltAccPopup }) => {
  const { status, message } = useSelector((state) => state?.merchant?.alternateAccountRate);
  const checkForUserKYC = () => {
    let temp;
    if (status === false && message !== 'Success') {
      openNotificationWithIcon('error', 'Error', message);
      temp = false;
    } else if (status === true && message === 'Success') {
      temp = true;
      setShowAltAccPopup(true);
    }
    return temp;
  };

  return (
    <DashBoardCard className="alternate-account fade-in" height={height} width={width}>
      <div className="d-flex">
        <div className="red-circle d-flex j-center align-center">
          <FontAwesomeIcon icon={faUniversity} style={{ fontSize: '18px', color: '#F3000B' }} />
        </div>
        <Spacer width={30} />
        <div>
          <p className="fw400 text-color font-size-16">Set up an alternative account number</p>

          <p className="fw200 text-neutral line-height17 font-size-13">Easily collect funds using multiple account numbers, with instant reflection in your VPay account.</p>
        </div>
        <Spacer width={15} />
        <div className="d-flex align-center c-pointer" onClick={() => checkForUserKYC()}>
          <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '16px', color: '#98A2B3' }} />
        </div>
      </div>
    </DashBoardCard>
  );
};

export const CashierDashboardTransactionTable = ({ cashpointCustomers, columns }) => {
  const history = useHistory();

  const data = cashpointCustomers
    ? cashpointCustomers.map((customer, i) => {
        return {
          key: i,
          ...customer,
          date: formatDay(customer.createdon),
        };
      })
    : [];
  return (
    <div className="no-mobile">
      {data.length > 0 ? (
        <div className="table-padding d-flex primary-bg-color full-width border-radius5 full-height">
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      ) : (
        <div className="full-height full-width ptb20 d-flex align-center j-center flex-vertical">
          <img src={emptyView} alt="" />
          <Spacer height={20} />
          <p className="fw400 text-color font-size-17">No Transactions</p>
          <Spacer height={30} className="no-desktop-but-mobile" />
        </div>
      )}
      {data.length > 0 && (
        <div className="d-flex align-center j-center">
          <Spacer height={45} />
          <button onClick={() => history.push('/admin/cashpoint/transactions')} className="unstyle-button c-pointer action-color  fw400 font-size-15">
            See More
          </button>
        </div>
      )}
    </div>
  );
};

const MobileCashierDashboardTransactions = ({ cashpointCustomers, noMobile, className }) => {
  const history = useHistory();

  return (
    <div className={`flex-one border-radius5 p-medium primary-bg-color p-0 ${className || ''} ${noMobile && 'no-mobile'}`}>
      {cashpointCustomers && cashpointCustomers.length > 0 ? (
        cashpointCustomers
          .filter((_, idx) => idx < 6)
          .map((cashpointCustomers, idx) => (
            <div key={idx}>
              <div className="full-width d-flex j-space-between align-center">
                <div className="d-flex">
                  <div className="mr-2">
                    <img style={{ height: '30px' }} src={emptyView} alt="" />
                  </div>
                  <div>
                    <p className="fw500 trans-snippet font-size-14">{cashpointCustomers.name}</p>
                    <p className="fw400 trans-snippet font-size-13">{formatDay(cashpointCustomers.createdon)}</p>
                  </div>
                </div>
                <div>
                  <p className="fw600 trans-snippet font-size-18">{`₦${formatNumber(cashpointCustomers.orderamount)}`}</p>
                </div>
              </div>
              <Spacer height={25} />
            </div>
          ))
      ) : (
        <div className="full-height full-width d-flex align-center j-center flex-vertical">
          <img src={emptyView} alt="" />
          <Spacer height={20} />
          <p className="fw400 text-color font-size-17">No Transactions</p>
          <Spacer height={30} className="no-desktop-but-mobile" />
        </div>
      )}
      {cashpointCustomers && cashpointCustomers.length > 0 && (
        <div className="d-flex align-center j-center">
          <button onClick={() => history.push('/admin/cashpoint/transactions')} className="unstyle-button c-pointer action-color  fw400 font-size-15">
            See More
          </button>
        </div>
      )}
    </div>
  );
};

const CashpointDashboard = () => {
  const dispatch = useDispatch();
  // const { onmerchantShow, onmerchantClose, merchantShow } = usePopup('merchant')
  const { isCashierQRopen } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const { user } = useSelector((state) => state.user);
  const {
    details: { nuban },
  } = useSelector((state) => state.cashierInformation);
  const { transactions } = useSelector((state) => state.cashierTransaction);
  const {
    user: { cashpointid },
  } = useSelector((state) => state.user);
  let total = transactions?.cashpoint_total;

  useEffect(() => {
    dispatch(loadTransactions(cashpointid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashpointid]);

  useEffect(() => {
    dispatch(getCashierBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tnxData, todayTnxTotal } = useTransactions(transactions?.data);
  const { graphData, transTotal } = useCashierGrowth();

  return (
    <MerchantContentWrapper className="d-flex flex-vertical full-height" hasTopSpacing>
      <div className="d-flex flex-vertical flex-one">
        <div className="dashboard-flex-wrapper d-flex p-relative full-width">
          <div className="dashboard-flex width-70-p">
            <DashBoardCard className="dashboard-card bank-details-card" background="white" height="130px" center>
              <div className="d-flex align-center">
                <img src={avatar} alt="avatar" />
                <div className="d-flex ml-20 flex-vertical">
                  <h3 className="no-margin line-height17 fw700 font-size-14em">{user.firstname + ' ' + user.lastname}</h3>
                  <Spacer height={3} />
                  <p className="no-margin fw400 font-size-1em">{nuban} | VFD MFB</p>
                </div>
              </div>
            </DashBoardCard>
            <Spacer width={20} />
            <DashBoardCard className="dashboard-card total-transaction-card" background="linear-gradient(91.66deg, #AB3882 9.63%, #ED2B30 76.56%, #F79420 110.1%)" height="130px" center>
              <p className="fw400 font-size-1em line-height12 no-margin primary-color">Total Daily Transactions</p>
              <div className="d-flex align-center">
                <h3 className="no-margin fw700 font-size-14em primary-color">{showBalance ? (transactions ? `₦${formatNumber(total ?? 0)}` : '₦0.00') : '₦****'}</h3>
                <FontAwesomeIcon icon={showBalance ? faEyeSlash : faEye} className="c-pointer text-white" onClick={() => setShowBalance((prev) => !prev)} />
              </div>
            </DashBoardCard>
          </div>
          <Spacer height={50} />
          <div className="d-flex j-end width-30-p">
            {/* <AppButton name='GENERATE PAYMENT' onClick={onmerchantShow} className="fw500 font-size-small generate-payment primary-color c-pointer " isActive /> */}
          </div>
        </div>
        <Spacer className="no-mobile" height={25} />
        <div className="d-flex j-space-between full-width">
          <p className="fw500 recent-clamp-font text-color">Your Recent Transactions</p>
          <span className="text-color fw500"></span>
        </div>
        <Spacer height={25} />
        <div className="d-flex flex-one p-relative full-width">
          <div className="cashier-wrapper border-radius5 primary-bg-color width-70-p overflow-auto">
            <CashierDashboardTransactionTable cashpointCustomers={transactions.data} columns={CashierColumn} />
            <MobileCashierDashboardTransactions cashpointCustomers={transactions?.data} className="no-desktop-but-mobile" />
          </div>
          <Spacer width={20} />
          <div className="chart-wrapper no-mobile border-radius5 d-flex flex-vertical p-medium primary-bg-color width-30-p overflow-auto">
            <p className="fw500 font-size-18 text-color">
              {' '}
              Daily Turnover <br />
              {`₦${formatNumber(todayTnxTotal || 0)}`}
            </p>
            <Spacer height={20} />
            <div className="flex-one">
              <MerchantGraph data={tnxData} />
            </div>
            <Spacer height={20} />
            <p className="fw500 font-size-18 text-color">
              Customer Growth <br />
              {`₦${formatNumber(transTotal)}`}
            </p>
            <Spacer height={20} />
            <div className="flex-one">
              <MerchantGraph data={graphData} currencyType="" />
            </div>
          </div>
        </div>
      </div>
      {show && (
        <GeneratePaymentPopup
          onClose={() => {
            setShow(false);
            dispatch(loadTransactions(cashpointid));
          }}
        />
      )}
      {isCashierQRopen && <CashpointAccountInfoPopup />}
    </MerchantContentWrapper>
  );
};

export default CashpointDashboard;

export const NotificationButton = ({ borderRadius, background, onClick, children }) => (
  <button onClick={onClick} type="button" style={{ borderRadius: borderRadius || 5, background }} className="unstyle-button notify-card c-pointer">
    {children}
  </button>
);

export const NotificationCard = ({ borderRadius }) => (
  <NotificationButton borderRadius={borderRadius}>
    <div>
      <img src={notificationIcon} alt="" />
    </div>
    <Spacer width={6} />
    <span className="fw500 primary-color">15</span>
  </NotificationButton>
);

export const DashboardHeader = ({ children, backgroundColor, titleMap }) => {
  const titleName = useTitleName(titleMap);
  return (
    <div className="d-flex dashboard-header full-width" style={{ backgroundColor }}>
      <h2 className="no-margin fw700 text-color font-size-22">{titleName}</h2>
      <div>{children}</div>
    </div>
  );
};

// const CashpointHeader = () =>
// (
//     <DashboardHeader titleMap={CASHPOINT_MAPPING}>
//         <NotificationCard />
//     </DashboardHeader>
// )

export function CashpointHeader() {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const {
    details: { contactfirstname, contactlastname, email },
  } = useSelector((state) => state.cashierInformation);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  useOutsideRef(dropdownRef, () => setShow(false));
  const onClick = () => {
    setShow(!show);
  };

  // useEffect(() => {
  //     if (location.pathname !== '/admin/cashpoint') {
  //         setShow(false)
  //     }
  // }, [location.pathname])

  const onLogoutClick = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.push('/login');
  };
  return (
    <DashboardHeader titleMap={CASHPOINT_MAPPING} backgroundColor="white">
      <div className="d-flex j-center align-center no-mobile">
        {/* <NotificationCard borderRadius={7} /> */}
        <Spacer width={10} />
        <TourWrapper
          title="Profile Settings"
          contentClass="tour-triangle-right"
          className="white-bg"
          content="Control your personal information, preferences, security and log out."
          index={1}
          style={{ right: '20px' }}
        >
          <UserIcon onClick={onClick} />
        </TourWrapper>
        <Spacer height={50} />
        {show && (
          <div ref={dropdownRef} style={{ padding: '20px   ', top: '60px', right: '42px', width: '260px', borderRadius: '5px' }} className="p-absolute primary-bg-color drop-shadow">
            <div className="d-flex align-center c-pointer">
              <img style={{ height: '25px' }} src={Avatar} alt="" />
              <Spacer width={14} />
              <div
                onClick={() => {
                  history.push('/admin/cashpoint/settings');
                  setShow(false);
                }}
              >
                <p className="fw400 no-margin font-size-17 text-color">{(contactfirstname ?? '') + (' ' + (contactlastname ?? 'Nil'))}</p>
                <p className="fw400 no-margin font-size-12 text-cut opacity-5">{email}</p>
              </div>
            </div>
            <Spacer height={20} />
            <div
              onClick={() => {
                openCashpointQR(dispatch);
                history.push('/admin/cashpoint');
                setShow(false);
              }}
              className="d-flex align-center c-pointer"
            >
              <img src={ProfileImage} alt="" />
              <Spacer width={20} />
              <span className="fw400 font-size-17 text-color">Bank Details</span>
            </div>
            <Spacer height={20} />
            <div onClick={onLogoutClick} className="d-flex align-center c-pointer">
              <img src={LogoutIcon} alt="" />
              <Spacer width={20} />
              <span className="fw400 font-size-17 text-color">Log Out</span>
            </div>
          </div>
        )}
      </div>
    </DashboardHeader>
  );
}
/**
 *
 * @param {ReactNode} header - Specifies the nav
 *
 */
export const DashboardLayout = ({ children, header }) => {
  const defaultHeader = header || <CashpointHeader />;
  return (
    <div className="p-relative fade-in dashboard-layout full-height">
      {defaultHeader}
      {children}
    </div>
  );
};
