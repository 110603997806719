import { faFacebookSquare, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppDivider from '../app_divider/app_divider';
import { Spacer } from '../layout/layout';
import './footer.css';

export default function Footer() {
  return (
    <footer className="small-content-padding">
      <FooterLinks />
      <Spacer height={40} className="no-mobile" />
      <AppDivider />
      <Spacer height={15} />
      <div className="social-link-c">
        <p style={{ color: '#FFFFFF' /*textColor.withOpacity(0.5)*/, opacity: '0.5' }} className="text-justify text-center-mobile fw400 font-size-small footer-text">
          VPay, powered by VFD Microfinance Bank and Minerva Technologies, provides financial services through its different web and mobile platforms. VPay and VFD are legal trademarks in Nigeria. All
          rights reserved.
        </p>
        <Spacer height={30} />
        <SocialLinks className="no-desktop-but-mobile-flex" />
      </div>
      <Spacer height={20} className="no-desktop-but-mobile" />
      <p style={{ color: '#FFFFFF' /*textColor.withOpacity(0.5)*/, opacity: '0.5' }} className="text-justify text-center-mobile fw400 font-size-little">
        &copy; {new Date().getUTCFullYear()} VPay. All rights reserved
      </p>
    </footer>
  );
}

const FooterLinks = () => {
  return (
    <div className="d-flex align-center flex-vertical-mobile j-space-between">
      <ul className="unstyle-ul d-flex vpay-links flex-vertical-mobile">
        <li className="footer-links fw500">
          <Link to="/terms-and-condition">Terms of Service</Link>
        </li>
        <Spacer width={40} />
        <li className="footer-links fw500">
          <Link to="/terms-and-condition">Privacy Policy</Link>
        </li>
        <Spacer width={40} />
        <li className="footer-links fw500">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <SocialLinks className="no-mobile" />
    </div>
  );
};

const SocialLinks = ({ className }) => {
  return (
    <ul className={(className || '') + ' unstyle-ul d-flex j-center'}>
      <li className="social-links fw500">
        <a href="https://web.facebook.com/Vpayafrica-109354711228898/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="footer-social-links" icon={faFacebookSquare} />
        </a>
      </li>
      <Spacer width={20} />
      <li className="social-links fw500">
        <a href="https://www.instagram.com/myvpayafrica/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="footer-social-links" icon={faInstagram} />
        </a>
      </li>
      <Spacer width={20} />
      <li className="social-links fw500">
        <a href="https://twitter.com/vpay_africa" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="footer-social-links" icon={faTwitter} />
        </a>
      </li>
      <Spacer width={20} />
      <li className="social-links fw500">
        <a href="https://www.linkedin.com/company/vpayafrica" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="footer-social-links" icon={faLinkedinIn} />
        </a>
      </li>
    </ul>
  );
};
