import { LoadingOutlined } from '@ant-design/icons';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultIcon from '../../../assets/images/default-profile-icon.png';
import AppButton from '../../../components/app_button/app_button';
import AppDivider from '../../../components/app_divider/app_divider';
import { FormDropdown } from '../../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../../components/form_input/form_input';
// import { useFormFile } from "../../../hooks/use_form_file";
import { CustomInputFile } from '../../../components/image_upload/image_upload';
import { Spacer } from '../../../components/layout/layout';
import { MerchantContentWrapper, TwoRowText } from '../../../components/merchant_widgets/merchant_widgets';
import { useInput } from '../../../hooks/use_input';
import { loadCashierInfo } from '../../../redux/merchant/cashier.information';
import { uploadCashierProfilePictureAction, verifyCashierBankDetailsAction } from '../../../redux/merchant/merchant_actions';
import { changePassword } from '../../../services/apiSevices';
import { getReadableFile } from '../../../utils/helper';
import { openNotificationWithIcon } from '../../auth/primary_signup/primary_signup';
import { getBankCode, getBankName } from '../../merchant/settings';

import './cashpoint_settings.css';

export const CashierSettingsWrapper = ({ children }) => <div className="primary-bg-color cashier-settings-wrapper">{children}</div>;

export const CashierPersonalInformation = () => {
  const dispatch = useDispatch();
  const {
    details: { phone, email, contactfirstname, contactlastname, bank, profile_picture },
  } = useSelector((state) => state.cashierInformation);
  const banks = useSelector((state) => state.merchant.cashierBanks);
  const isBankDetailsLoading = useSelector((state) => state.merchant.cashierBankDetailsLoading);
  const mappedBanks = banks.map((bank) => bank.name) ?? [];
  const defaultBank = bank ? getBankName(bank.code, banks) : '';
  const [bankName, setBankName] = useState(defaultBank);
  const defaultAccountNumber = bank ? bank.number : '';
  const accountNumberProps = useInput(defaultAccountNumber, 'text', null, null, true);
  const [preview] = useState(defaultIcon);
  const cashpointID = useSelector((state) => state.user['user'].cashpointid);
  const [profileFile, setProfileFile] = useState(null);
  const [profileSrc, setProfileSrc] = useState(null);
  const [edit, setEdit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let { _, ...accountNumberPropsRest } = accountNumberProps;

  useEffect(() => {
    if (!isBankDetailsLoading) {
      setEdit(false);
    }
  }, [isBankDetailsLoading]);

  const picturePropsChange = (e) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    setProfileFile(e.target.files[0]);
    const reader = getReadableFile(e.target);
    if (reader) {
      reader.onloadend = function (_) {
        setProfileSrc(reader.result);
      };
    }
    dispatch(uploadCashierProfilePictureAction(cashpointID, data));
  };

  const fileProps = { src: profileSrc, onChange: picturePropsChange, file: profileFile };

  const bankDetails = {
    accountnumber: accountNumberProps.value,
    bankcode: getBankCode(bankName, banks),
    accountname: `${contactlastname} ${contactfirstname}`,
    cashpoint: cashpointID,
  };

  const onBankChange = (e) => {
    setBankName(e);
  };

  const onClick = async () => {
    if (!/^[0-9]+$/.test(bankDetails.accountnumber) && bankDetails.accountnumber) {
      openNotificationWithIcon('warning', 'Warning', 'Account Number must not include space or any special character');
      return;
    }
    if (bankDetails.accountnumber.length !== 10) {
      openNotificationWithIcon('warning', 'Warning', 'Account Number must contain 10 numbers');
      return;
    }
    if (!bankName) {
      openNotificationWithIcon('warning', 'Warning', 'Please select your bank');
      return;
    }
    const data = {
      accountnumber: accountNumberProps.value,
      bankcode: getBankCode(bankName, banks),
    };
    dispatch(verifyCashierBankDetailsAction(data, bankDetails, cashpointID));
  };

  // const onEditClick = () => {
  //   setEdit(true);
  // };

  const onCancelClick = () => {
    setEdit(false);
  };

  return (
    <div>
      <Spacer height={10} />
      <div className="w-124 h-124">
        <CustomInputFile fileProps={fileProps} className="rounded-widget w-124 h-124 p-relative" clickable>
          <img src={profile_picture ?? preview} className={`c-pointer  w-124 h-124  ${profile_picture && 'rounded-widget'}`} alt="" />
        </CustomInputFile>
      </div>
      <Spacer height={35} />
      <AppDivider height="1px" color="rgba(32, 14, 50)" />
      <Spacer height={30} />
      <TwoRowText title="Full Name" description={`${contactfirstname ?? ''} ${contactlastname ?? ''}`} />
      <Spacer height={20} />
      <TwoRowText title="Email Address" description={email ?? ''} />
      <Spacer height={20} />
      <TwoRowText title="Phone Number" description={phone ?? ''} />
      <Spacer height={30} />
      {/* <TwoRowText title="Account Number" description={defaultAccountNumber} />
      <Spacer height={30} />
      <TwoRowText title="Bank" description={defaultBank} />
      <Spacer height={30} /> */}
      <AppDivider height="1px" color="rgba(32, 14, 50)" />
      <Spacer height={30} />
      {/* {!edit && (
        <div onClick={onEditClick} className="c-pointer btn-outline border-radius5 action-color-hover">
          <FontAwesomeIcon icon={faPencilAlt} />
          <Spacer width={10} className="display-inline-block" />
          <span className="font-size-17 fw500">Edit Bank Details</span>
        </div>
      )} */}

      {!bank && edit && (
        <div>
          <LoadingOutlined />
        </div>
      )}

      {bank && edit && (
        <div className="d-flex full-width align-center" style={{ columnGap: '10px' }}>
          <FormInput {...accountNumberPropsRest} placeholder="Account Number" className="width-35 fw400 font-size-17 max-width-500" />
          <FormDropdown onChange={onBankChange} placeholder="Select Bank" width="35%" options={mappedBanks} className="full-width max-width-500" />
        </div>
      )}

      {edit && (
        <div>
          <AppButton onClick={onClick} isBusy={isBankDetailsLoading} isActive={true} className="cashier-change-btn font-size-17 fw500 primary-color" name="Save Changes" />
          <Spacer width={20} className="display-inline-block" />
          <AppButton onClick={onCancelClick} isActive={true} className="font-size-17 fw500 action-color unstyle-button" name="Cancel" />
        </div>
      )}

      <Spacer height={30} />
    </div>
  );
};

export const CashierSecurity = () => {
  const newPasswordProps = useInput('', 'password');
  const confirmPasswordProps = useInput('', 'password');
  const oldPasswordProps = useInput('', 'password');
  const [loading, setLoading] = useState(false);

  const clearFields = () => {
    newPasswordProps.reset();
    confirmPasswordProps.reset();
    oldPasswordProps.reset();
  };

  const onClick = async () => {
    if (newPasswordProps.value && confirmPasswordProps.value && oldPasswordProps.value) {
      if (confirmPasswordProps.value !== newPasswordProps.value) {
        openNotificationWithIcon('warning', 'Warning', "Passwords don't match");
        return;
      }
      try {
        setLoading(true);
        await changePassword({ password: newPasswordProps.value, old_password: oldPasswordProps.value });
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Password changed successfully');
        clearFields();
      } catch (error) {
        setLoading(false);
        return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  return (
    <div className="p-relative full-width">
      <Spacer height={10} />
      <h3 className="no-margin fw500 text-color font-size-17">Change Password</h3>
      <Spacer height={10} />
      <FormInput {...oldPasswordProps} isPassword placeholder="Old password" className="width-40 fw400 font-size-17 max-width-500" />
      <FormInput {...newPasswordProps} isPassword placeholder="New password" className="width-40 fw400 margin-top-negative-25 font-size-17 max-width-500" />
      <FormInput {...confirmPasswordProps} isPassword placeholder="Confirm new password" className="width-40 margin-top-negative-25 fw400 font-size-17 max-width-500" />
      <AppButton isBusy={loading} isActive={true} onClick={onClick} className="cashier-change-btn font-size-17 fw500 primary-color" name="Save Changes" />
      <Spacer height={40} />
    </div>
  );
};

const TabItems = [
  {
    label: 'Personal Information',
    key: 'Personal Information',
    children: <CashierPersonalInformation />,
  },
  {
    label: 'Security',
    key: 'Security',
    children: <CashierSecurity />,
  },
];

const CashpointSettings = () => {
  const dispatch = useDispatch();
  const {
    user: { cashpointid },
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadCashierInfo(cashpointid));
  }, [cashpointid, dispatch]);

  return (
    <MerchantContentWrapper className="d-flex flex-vertical full-height" hasTopSpacing>
      <CashierSettingsWrapper>
        <Tabs items={TabItems} tabBarStyle={{ border: 'none', padding: '0px' }} defaultActiveKey="Personal Information" />
      </CashierSettingsWrapper>
    </MerchantContentWrapper>
  );
};

export default CashpointSettings;
