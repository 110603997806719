import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import add from '../../assets/images/add.svg';
import cardOverlay from '../../assets/images/cardOverlay.svg';
import category from '../../assets/images/category-2.svg';
import checkMark from '../../assets/images/check-green.svg';
import arrow from '../../assets/images/downArrow.svg';
import housing from '../../assets/images/house.svg';
import salary from '../../assets/images/money-send.svg';
import phone from '../../assets/images/monitor-mobbile.svg';
import { useInput } from '../../hooks/use_input';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { FlexContainerWithPaddingForCustomInbound, FlexContainerWithPaddingForCustomInboundLower } from '../../pages/merchant/withdraw';
import { removeConfirmCustomInbound } from '../../redux/merchant/custom_inbound.feature';
import { addOutboundCategory, getTnxCategoryList, submitCustomInboundSrv } from '../../services/apiSevices';
import { formatMoney } from '../../utils/formatNumber';
import { addProductValidate } from '../../utils/validation_rules/custom_inbound_validate';
import AppButton from '../app_button/app_button';
import { BorderLessFormDropdownWithAddButton } from '../form_dropdown/form_dropdown';
import { BorderLessAppInput, BorderLessAppInputWithCount } from '../form_input/form_input';
import { Spacer } from '../layout/layout';
import Popup from '../popup/popup';
import { PopupHeader } from '../popup_header/popup_header';
import './popup_widget.css';

export const BorderlessDropdownWithImg = ({ openCategory }) => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  // get the list of categories
  const { data, isLoading } = useQuery({ queryKey: ['getCategoryList'], queryFn: () => getTnxCategoryList(merchantId, 1), refetchInterval: false });

  //console.log(data?.data?.data?.categories, ' data from get category');
  const handleDropdown = () => {
    setArrowDown(!arrowdown);
  };

  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize this expense</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => handleDropdown()} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <Popup width="20%" onClose={() => handleDropdown()}>
          <div className="full-width d-flex">
            <div className="full-width">
              <div className="d-flex j-space-between linebreak">
                <div className="d-flex align-center pl-10 py-15 c-pointer" onClick={openCategory}>
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 addBg">
                    <img src={add} className="" />
                  </div>
                  <Spacer width={15} />
                  <p>Add a custom category</p>
                </div>
              </div>

              <div className={`${currentSelection === 1 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(1)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                    <img src={salary} className="salaryColor" />
                  </div>
                  <p>Salary</p>
                </div>
                {currentSelection === 1 && <img src={checkMark} className="fade-in" />}
              </div>
              <div className={`${currentSelection === 2 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(2)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                    <img src={housing} className="salaryColor" />
                  </div>
                  <p>Housing and Rent</p>
                </div>
                {currentSelection === 2 && <img src={checkMark} className="fade-in" />}
              </div>
              <div className={`${currentSelection === 3 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(3)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 phoneColor">
                    <img src={phone} className="phoneColor" />
                  </div>
                  <p>Phone & Internet</p>
                </div>
                {currentSelection === 3 && <img src={checkMark} className="fade-in" />}
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Spacer height={10} />
    </div>
  );
};

export const BorderlessCategoriseOutbound = ({ openCategory }) => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);
  const [count, setCount] = useState(0);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  const { showProdctPopup } = useSelector((state) => state?.customInboundReducer);
  // get the list of categories
  const { data, isLoading } = useQuery({ queryKey: ['getCategoryList'], queryFn: () => getTnxCategoryList(merchantId, 1), refetchInterval: false });

  const handleAdd = () => {
    setCount((count) => count + 1);
  };

  const handleMinus = () => {
    if (count === 0) {
      return 0;
    }
    setCount((count) => count - 1);
  };
  const handleDropdown = () => {
    setArrowDown(!arrowdown);
  };

  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize This Inbound</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => handleDropdown()} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <Popup flag={true}>
          <PopupHeader text="Categorise this inbound" goBack={() => handleDropdown()} />
          <Spacer height={30} />
          <div className="d-flex j-center align-center flex-column">
            <BorderLessFormDropdownWithAddButton placeholder="Select Product" onClick={setArrowDown(false)} />
            <BorderLessAppInputWithCount handleAdd={() => handleAdd()} handleMinus={() => handleMinus()} placeholder="Enter Quantity" readOnly={true} count={count} />
            <div className="px-50 full-width">
              <Spacer height={30} />
              <AppButton
                className="d-flex full-width align-center j-center app-button action-color-2 button-nav-padding text-white fade-in margin-top-10"
                name="Save"
                isActive={true}
                onClick={() => console.log('testing the mic')}
                isBusy={false}
              />
            </div>
          </div>
        </Popup>
      )}
      {showProdctPopup && <ProductPopup />}
      <Spacer height={10} />
    </div>
  );
};

export const BorderlessDropdownWithImgClone = ({ openCategory }) => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);
  // get the list of categories

  const handleDropdown = () => {
    setArrowDown(!arrowdown);
  };

  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize this expense</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => handleDropdown()} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <div className="p-absolute options full-width slide-up d-flex justify-end">
          <div className=" options-content half-width">
            <div className="d-flex j-space-between linebreak">
              <div className="d-flex align-center pl-10 py-15 c-pointer" onClick={openCategory}>
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 addBg">
                  <img src={add} className="" />
                </div>
                <p>Add a custom category</p>
              </div>
            </div>

            <div className={`${currentSelection === 1 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(1)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={salary} className="salaryColor" />
                </div>
                <p>Salary</p>
              </div>
              {currentSelection === 1 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 2 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(2)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={housing} className="salaryColor" />
                </div>
                <p>Housing and Rent</p>
              </div>
              {currentSelection === 2 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 3 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(3)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 phoneColor">
                  <img src={phone} className="phoneColor" />
                </div>
                <p>Phone & Internet</p>
              </div>
              {currentSelection === 3 && <img src={checkMark} className="fade-in" />}
            </div>
          </div>
        </div>
      )}
      <Spacer height={10} />
    </div>
  );
};

export const AddProductCategory = ({ closeCategory, merchantId }) => {
  const [loading, setLoading] = useState(false);
  const categoryNameProps = useInput('', 'text', '', '');
  const [errors, setError] = useState({});

  const handleAddProduct = async () => {
    // validate input
    const val = {
      categoryName: categoryNameProps.value,
    };
    const error = addProductValidate(val);
    setError(error);
    if (Object.keys(errors).length > 0) {
      return;
    }
    setLoading(true);
    const data = {
      merchant: merchantId,
      name: categoryNameProps.value,
    };
    try {
      const res = await addOutboundCategory(data);
      if (res?.data?.status) {
        openNotificationWithIcon('success', 'Success', 'Product Category Added Successfully');
        setLoading(false);
        // close the popup
        closeCategory();
      }
      setLoading(false);
    } catch (error) {
      //console.log('an error occurered ', error?.response?.data?.message);
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message ?? 'Error Ading Product Category');
      setLoading(false);
    }
  };
  return (
    <Popup flag={true} width="20%">
      <PopupHeader text="Create a category" goBack={closeCategory} />
      <Spacer height={40} />
      <BorderLessAppInput placeholder="Category Name" {...categoryNameProps} name="categoryName" error={errors} />
      <Spacer height={40} />
      <AppButton
        onClick={() => handleAddProduct()}
        isBusy={loading}
        className="full-width d-flex align-center j-center app-button action-color-2 button-nav-padding text-white fade-in"
        isActive={true}
        name="Save"
      />
    </Popup>
  );
};

export const ConfirmCustomHeader = () => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(removeConfirmCustomInbound());
  };
  return (
    <div className="p-relative fade-in page-color-background">
      <div className="overlay">
        <img src={cardOverlay} className="p-absolute" style={{ right: '0' }} />
        <Spacer height={40} />
        <div className="d-flex align-center j-space-between text-white p10">
          <div style={{ width: '4%' }} onClick={() => goBack()}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" className="c-pointer" />
          </div>
          <div className="fw700 font-size14em full-width d-flex j-center">
            <p>Confirm Details</p>
          </div>
        </div>
      </div>
      <ConfirmCustomDetails />
      <ConfirmCustomLowerDetails />
    </div>
  );
};

export const ConfirmCustomDetails = () => {
  // get state values from redux
  const { customer_name, amount } = useSelector((state) => state?.customInboundReducer);
  return (
    <div className="custom-confirm-details">
      <div className="d-flex flex-column align-center j-center full-height full-width fw500">
        <div className="text-center ">
          <p className="text-vpay-purple ">From</p>
          <p className="font-size13em">{customer_name}</p>
        </div>
        <Spacer height={10} />
        <div className="text-center">
          <p className="text-vpay-purple">Amount</p>
          <p className=" font-size13em">₦{formatMoney(amount)}</p>
        </div>
      </div>
    </div>
  );
};

export const ConfirmCustomLowerDetails = () => {
  const [loading, setLoading] = useState(false);
  // get state values from redux
  const { tnx_date, product, payment_type, quantity, amount, customer_name } = useSelector((state) => state?.customInboundReducer);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);

  const saveCustomInbound = async () => {
    const info = {
      merchant_id: merchantId,
      product_id: product.split('-')[0],
      amount: amount,
      originator_account_name: customer_name,
    };
    setLoading(true);
    try {
      const res = await submitCustomInboundSrv(info);
      console.log(res, ' response from SAVING');
      setLoading(false);
    } catch (error) {
      console.log('an error occured', error?.response);
      openNotificationWithIcon('error', 'Error');
      setLoading(false);
    }
  };
  const modifiedProduct = product.split('-')[1];

  return (
    <div>
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Date" rightText="Payment Type" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText={tnx_date} rightText={payment_type} className="pl-40 pr-40" />
      </div>
      <Spacer height={20} />
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Product" rightText="Quantity" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText={modifiedProduct} rightText={quantity} className="pl-40 pr-40" />
      </div>
      <Spacer height={30} />
      <div>
        <AppButton
          onClick={() => saveCustomInbound()}
          isBusy={loading}
          isActive={true}
          className="full-width d-flex align-center j-center app-button action-color-2 button-nav-padding text-white fade-in"
          name="Save"
        />
      </div>
    </div>
  );
};

export const ProductPopup = () => {
  return (
    <Popup flag={true}>
      <PopupHeader text="Add New Product" />
      <Spacer height={40} />
      <div className="app-padding-lr">
        <BorderLessAppInput placeholder="Product Name" />
        <BorderLessAppInputWithCount placeholder="Unit" />
        <BorderLessAppInputWithCount placeholder="Stock" />
      </div>
    </Popup>
  );
};
