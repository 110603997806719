export const bulkPaymentFormValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Group Name Should Not Be Empty.';
  }
  if (!values.description) {
    errors.description = 'Group Description Should Not Be Empty.';
  }
  return errors;
};
