export const saveToLS = (key, value) => {
  if (localStorage) {
    localStorage.setItem(key, value);
  }
};
export const getInLS = (key) => {
  if (localStorage) {
    return localStorage.getItem(key);
  }
};

export const getInSS = (key) => {
  if (sessionStorage) {
    return sessionStorage.getItem(key);
  }
};
