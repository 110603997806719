import './home_app_area.css';
import mobileAppPortrait from '../../assets/images/areaHand.png';
import spiral from '../../assets/images/spiral.png';
import appleStore from '../../assets/images/appleBlack.svg';
import googlePlay from '../../assets/images/googleBlack.svg';
import mobile_googlePlay from '../../assets/images/smallGoogle.svg';
import mobile_appleStore from '../../assets/images/mobileApple.svg';
import big_hand_with_phone from '../../assets/images/hand.png';
import small_hand from '../../assets/images/small-hand.png';
import { Spacer } from '../layout/layout';
import AppButton from '../app_button/app_button';
import { useHistory } from 'react-router';

export default function HomeAppArea() {
  const history = useHistory();
  return (
    <div className="small-content-padding  mobile-spacing home-app-area-container">
      <div className="p-relative home-app-area">
        <div className="home-app-text-area">
          <Spacer height={50} className="no-desktop-but-mobile" />
          <p className="fw500 font-size-bigger no-margin primary-color">All-in-one platform for business</p>
          <Spacer height={10} />
          <p className="home-app-text fw400 primary-color">Sign up today and start receiving payments from over 50% of your unstapped customer base, transaction notifications are instant.</p>
          <Spacer height={25} />
          <AppButton isActive={true} name="Create free account" onClick={() => history.push('/signup')} className="header-account-button no-mobile app-area-btn fw500 font-size-20" />
          <div className="d-flex align-center no-desktop store-links-wrapper">
            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={mobile_googlePlay} className="no-desktop-but-mobile" alt="click to download from Google Play Store" />
            </a>
            <Spacer width={30} />
            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={mobile_appleStore} className="no-desktop-but-mobile" alt="click to download from Apple Store" />
            </a>
          </div>
        </div>
        <>
          <img className="p-absolute home-app-image no-mobile" src={mobileAppPortrait} alt="mobile app portrait view" />
          <img className="full-height no-desktop-but-mobile small-hand" src={small_hand} alt="mobile app portrait view" />
        </>
        <img src={spiral} alt="" className="p-absolute spiral-rope" />
      </div>
      <div className="frag-space">
        <Spacer height={70} />
      </div>
    </div>
  );
}

export const HomeAreaWithoutBackground = () => {
  return (
    <div className="small-content-padding container mobile-spacing home-app-area-container">
      <div className="p-relative area-container">
        <div className="h-app-text-area">
          <p className="fw500 font-size-bigger all-platform no-margin text-color">All-in-one platform for business</p>
          <Spacer height={10} />
          <p className="h-app-text fw400 text-color">Sign up today and start receiving payments from over 50% of your unstapped customer base, transaction notifications are instant.</p>
          <Spacer height={25} />
          <div className="d-flex align-center store-links-wrapper">
            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={appleStore} className="no-mobile-but-desktop" alt="click to download from Apple Store" />
            </a>
            <Spacer width={20} />
            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={googlePlay} className="no-mobile-but-desktop" alt="click to download from Google Play Store" />
            </a>

            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={mobile_appleStore} className="no-desktop-but-mobile" alt="click to download from Apple Store" />
            </a>
            <Spacer width={20} />
            <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
              <img src={mobile_googlePlay} className="no-desktop-but-mobile" alt="click to download from Google Play Store" />
            </a>
          </div>
          <Spacer height={80} className="no-desktop" />
        </div>
        <img className="full-height no-mobile" src={big_hand_with_phone} alt="mobile app portrait view" />
        <img className="full-height no-desktop-but-mobile small-hand" src={small_hand} alt="mobile app portrait view" />
      </div>
      {/* <div className="frag-space">
                <Spacer height={70} />
            </div> */}
    </div>
  );
};
