import { createSlice } from '@reduxjs/toolkit';
import { merchantReferral } from '../../services/apiSevices';

const merchantReferralSlice = createSlice({
  name: 'merchantReferral',
  initialState: {
    referrals: {},
    loading: false,
    error: null,
  },
  reducers: {
    setReferrals: (state, { payload }) => {
      state.referrals = payload;
    },
    refLoading: (state, { payload }) => {
      state.loading = payload;
    },
    refError: (state, { payload }) => {
      state.error = payload;
    },
  },
});
const merchantReferralReducer = merchantReferralSlice.reducer;

export const { setReferrals, refLoading, refError } = merchantReferralSlice.actions;
const apply =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(refLoading(true));
    try {
      const { data } = await remote();

      if (data) {
        dispatch(action(data));
        dispatch(refLoading(false));
      } else {
        dispatch(refError(data?.message));
        dispatch(refLoading(false));
      }
    } catch (err) {
      console.log({ err });
      dispatch(refError(err.response?.data?.message));
      dispatch(refLoading(false));
    }
  };

export const fecthReferralDetails = (mid) => async (dispatch) => {
  await apply({ remote: () => merchantReferral(mid), action: setReferrals })(dispatch);
};

export const merchantReferralSelector = (state) => state.referral.referrals;
export default merchantReferralReducer;
