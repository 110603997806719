import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '../../../assets/images/check-green.svg';
import { ColouredBackground, FormLayout } from '../../../components/layout/layout';
import { addDetails } from '../../../redux/user/user_actions';
import { thirdFormValidate } from '../../../utils/validation_rules/signup_validate';
import { openNotificationWithIcon } from '../primary_signup/primary_signup';
import './intermediate_signup.css';

export default function IntermediateSignUp({ setPosition }) {
  const signUpCredentials = useSelector((state) => state.user.signUpCredentials);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const [selectedOption, setSelectedOption] = useState(signUpCredentials.account_type ?? '');
  const info = {
    account_type: selectedOption,
  };

  let radioRef = useRef(null);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);

    let radioboxes = radioRef.current.children;
    for (const element of radioboxes) {
      let radioBox = element.children[0].children[0].children[0];

      if (radioBox.checked && radioBox.value === event.target.value) {
        element.style.border = '1px solid #FF1C26';
        element.style.borderRadius = '10px';
        element.style.marginBottom = '10px';
        element.children[0].style.background = 'transparent';
        element.children[0].style.paddingBottom = '0';
        element.children[0].style.marginBottom = '5px';
      } else {
        element.style.border = 'none';
        element.style.marginBottom = '0';
        element.style.animation = 'none';
        element.children[0].style.background = '#f8f8f8';
        element.children[0].style.paddingBottom = '20px';
        element.children[0].style.marginBottom = '10px';
      }
    }
  };

  const goBackClick = () => {
    dispatch(addDetails(info));
    setPosition((prev) => prev - 1);
  };

  const handleClick = (e) => {
    e.preventDefault();
    const error = thirdFormValidate(info);
    setError(error);

    if (Object.keys(error).length === 0) {
      dispatch(addDetails(info));
      setPosition((prev) => prev + 1);
    } else {
      openNotificationWithIcon('warning', 'Warning', error.account_type);
    }
  };

  return (
    <ColouredBackground>
      <FormLayout headerText="Choose Account Type" onClick={(e) => handleClick(e)} buttonText="Continue" isBusy={loading} goBackFlag goBackClick={goBackClick}>
        <div ref={radioRef}>
          <div className="option-wrapper full-width">
            <div className="radio">
              <label className="d-flex align-baseline c-pointer">
                <input type="radio" value="1" checked={selectedOption === '1'} onChange={handleChange} />
                <span className="radio-mark"></span>
                <span>Your business is registered with CAC.</span>
              </label>
            </div>
            {selectedOption === '1' && (
              <div className="benefits">
                <p>What you’ll get:</p>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Paperless setup of a bank account for your business</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>An account name that is same as your business name</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Receive and send funds to any bank in Nigeria</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to track your revenue, expenses and customer loyalty</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools for your staff to verify payments even when you are not present</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to accept transfer, card, USSD payments from customers in your retail establishment</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to accept transfer, card, USSD payments from customers on your website</span>
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper full-width">
            <div className="radio">
              <label className="d-flex align-baseline c-pointer">
                <input type="radio" value="2" checked={selectedOption === '2'} onChange={handleChange} />
                <span className="radio-mark"></span>
                <span>You are not CAC registered but you need a dedicated account for your business</span>
              </label>
            </div>
            {selectedOption === '2' && (
              <div className="benefits">
                <p>What you'll get:</p>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Paperless setup of a dedicated personal account for your business operations</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Receive and send funds to any bank in Nigeria</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to track your revenue, expenses and customer loyalty</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools for your staff to verify payments even when you are not present</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to accept transfer, card, USSD payments from customers in your retail establishment</span>
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper full-width">
            <div className="radio">
              <label className="d-flex align-baseline c-pointer">
                <input type="radio" value="3" checked={selectedOption === '3'} onChange={handleChange} />
                <span className="radio-mark"></span>
                <span>You need a personal account</span>
              </label>
            </div>
            {selectedOption === '3' && (
              <div className="benefits">
                <p>What you’ll get:</p>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Paperless setup of a personal account in your name</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Receive and send funds to any bank in Nigeria</span>
                </div>
                <div className="d-flex align-baseline">
                  <img src={CheckIcon} alt="check icon" />
                  <span>Tools to track your revenue, expenses and customer loyalty</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </FormLayout>
    </ColouredBackground>
  );
}
