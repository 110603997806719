import { store } from '../../redux/store';
import { formatNumber } from '../../utils/formatNumber';
// import { requeryTransaction } from "../../services/apiSevices";
// import { openNotificationWithIcon } from "../primary_signup/primary_signup";
import { formatDay } from '../../utils/helper';

export const getStoreDetails = () => {
  const {
    merchant: { failureCodes, successCodes },
    user: {
      currentMerchant: { publickey },
    },
  } = store.getState();

  return { failureCodes, successCodes, publickey };
};

// const { failureCodes, successCodes, publickey } = getStoreDetails()

// const allCodes = [...failureCodes, ...successCodes]

// const RequeryTransaction = async (transactionref) => {
//   try {
//     const res = await requeryTransaction(publickey, transactionref);
//     if (successCodes.includes(res.transactionStatus)) {
//       openNotificationWithIcon('success',"Success", "Transaction Successful");
//     } else if (failureCodes?.includes(res.transactionStatus) === 1) {
//       openNotificationWithIcon("error", "Error", "Transaction Failed");
//     } else {
//       openNotificationWithIcon("error", "Error", res.data.data.message);
//     }
//   } catch (error) {
//     openNotificationWithIcon("error", "Error", error?.response?.data.data);
//   }
// };

export const CashpointColumn = [
  {
    title: 'Amount',
    dataIndex: 'orderamount',
    key: 'orderamount',
    render: (text) => <span>₦{formatNumber(text)}</span>,
  },
  {
    title: 'Customer',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
  },
  {
    title: 'Description',
    dataIndex: 'orderdescription',
    key: 'orderdescription',

    render: (text) => <span>{text || 'Not Available'}</span>,
  },
  {
    title: 'Date/Time',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Status',
    dataIndex: 'paymentstatus',
    key: 'paymentstatus',
    render: (text) => (
      <span
        style={{
          color: text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6',
          textTransform: 'capitalize',
          width: '50px',
          padding: '3px 20px',
          border: `1px solid ${text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6'}`,
          borderRadius: '25px',
        }}
      >
        {text}
      </span>
    ),
  },
];

export const VirtualAccountColumn = [
  {
    title: 'Amount',
    dataIndex: 'orderamount',
    key: 'orderamount',
    render: (text) => <span>₦{formatNumber(text)}</span>,
  },
  {
    title: 'Customer',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
  },
  {
    title: 'Description',
    dataIndex: 'orderdescription',
    key: 'orderdescription',

    render: (text) => <span>{text || 'Not Available'}</span>,
  },
  {
    title: 'Date/Time',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Status',
    dataIndex: 'paymentstatus',
    key: 'paymentstatus',
    render: (text) => (
      <span
        style={{
          color: text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6',
          textTransform: 'capitalize',
          width: '50px',
          padding: '3px 20px',
          border: `1px solid ${text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6'}`,
          borderRadius: '25px',
        }}
      >
        {text}
      </span>
    ),
  },
  {
    title: 'Webhook Status',
    dataIndex: 'paymentstatus',
    key: 'paymentstatus',
    render: (text) => (
      <span
        style={{
          color: text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6',
          textTransform: 'capitalize',
          width: '50px',
          padding: '3px 20px',
          border: `1px solid ${text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6'}`,
          borderRadius: '25px',
        }}
      >
        {text}
      </span>
    ),
  },
];


export const CashierColumn = [
  {
    title: 'Amount',
    dataIndex: 'orderamount',
    key: 'orderamount',
    render: (text) => <span>₦{formatNumber(text)}</span>,
  },
  {
    title: 'Customer',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
  },
  {
    title: 'Destination',
    dataIndex: 'accountnumber',
    key: 'accountnumber',

    render: (text) => {
      return <span>{text || 'Not Available'}</span>;
    },
  },
  {
    title: 'Date/Time',
    dataIndex: 'date',
    key: 'date',
  },

  {
    title: 'Status',
    dataIndex: 'paymentstatus',
    key: 'paymentstatus',
    render: (text) => (
      <span
        style={{
          color: text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6',
          textTransform: 'capitalize',
          width: '50px',
          padding: '3px 20px',
          border: `1px solid ${text === 'paid' ? '#47A08B' : text === 'pending' ? '#F79420' : '#01B2E6'}`,
          borderRadius: '25px',
        }}
      >
        {text}
      </span>
    ),
  },
];

export const PayoutColumn = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Narrative',
    dataIndex: 'narrative',
    key: 'narrative',
  },
  {
    title: 'Txn Referrence',
    dataIndex: 'transactionref',
    key: 'transactionref',
  },
  {
    title: 'Date',
    dataIndex: 'created_on',
    key: 'created_on',
    render: (text) => <span>{formatDay(text)}</span>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => (
      <span
        style={{
          color: text === 'credit' ? '#47A08B' : '#ED2B30',
          textTransform: 'capitalize',
          width: '50px',
          padding: '3px 20px',
          border: `1px solid ${text === 'credit' ? '#47A08B' : '#ED2B30'}`,
          borderRadius: '20px',
        }}
      >
        {text}
      </span>
    ),
  },

  // {
  //   title: '',
  //   dataIndex: 'status',
  //   key: 'status',
  //   onCell: (record, idx) => {
  //     return {
  //       onClick: (e) => {
  //         console.log(allCodes)
  //         if (
  //           allCodes.indexOf(record.status) === false
  //         ) {
  //           RequeryTransaction(record.transaction_id);
  //         }
  //       },
  //     };
  //   },
  //   render: (status, i) => {
  //     if (allCodes.indexOf(status) !== -1) {
  //       return (
  //         <button className="c-pointer" >Requery</button>
  //       )
  //     } else {
  //       return ""
  //     }
  //   }
  // }
];
