import { UserActionTypes } from './user_types';

import { setNuban, setPublicKey } from '../../utils/helper';

const INITIAL_STATE = {
  user: {},
  currentMerchant: {},
  loading: false,
  error: '',
  role: '',
  signUpCredentials: {},
  isWaitlistModalOpen: false,
  isSession: false,
  resetMail: '',
  isCashierQRopen: false,
  isLoggedIn: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_START:
    case UserActionTypes.SIGN_UP_START:
    case UserActionTypes.USER_PASSWORD_RESET_START:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        currentMerchant: action.payload.merchants[0],
        loading: false,
        role: action.payload.role,
        error: '',
        isLoggedIn: true,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpCredentials: {},
        loading: false,
        error: '',
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        isLoggedIn: false,
      };
    case UserActionTypes.SIGN_IN_FALIURE:
    case UserActionTypes.SIGN_OUT_FALIURE:
    case UserActionTypes.SIGN_UP_FALIURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UserActionTypes.ADD_DETAILS:
      return {
        ...state,
        error: '',
        signUpCredentials: { ...state.signUpCredentials, ...action.payload },
      };
    case UserActionTypes.CLEAR_DETAILS:
      return {
        ...state,
        error: '',
        signUpCredentials: {},
      };
    case UserActionTypes.USER_PASSWORD_RESET_SUCCESS:
    case UserActionTypes.USER_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case 'OPEN_WAITLIST_MODAL':
      return {
        ...state,
        isWaitlistModalOpen: true,
      };
    case 'CLOSE_WAITLIST_MODAL':
      return {
        ...state,
        isWaitlistModalOpen: false,
      };
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case 'UPDATE_CURRENT_MERCHANT_SUCCESS':
      return {
        ...state,
        currentMerchant: {
          ...state.currentMerchant,
          ...action.payload,
          businessid: action.payload._id,
          publickey: setPublicKey(state.user.merchants, action.payload._id),
          nuban: setNuban(state.user.merchants, action.payload._id),
        },
      };
    case 'OPEN_SESSION_MODAL':
      return {
        ...state,
        isSession: true,
      };
    case 'CLOSE_SESSION_MODAL':
      return {
        ...state,
        isSession: false,
      };
    case 'SET_RESET_MAIL':
      return {
        ...state,
        resetMail: action.payload,
      };
    case 'OPEN_CASHPOINT_QR':
      return {
        ...state,
        isCashierQRopen: true,
      };
    case 'CLOSE_CASHPOINT_QR':
      return {
        ...state,
        isCashierQRopen: false,
      };
    case 'LOADING_FALSE':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
