import { Link } from 'react-router-dom';
import navigation from '../../assets/images/navigation.svg';
import { useNavigation } from '../../hooks/use_ navigation';
import useScroll from '../../hooks/use_scroll';
import AppIcon from '../app_icon/app_icon';
import AppNav from '../app_nav/app_nav';
import { Spacer } from '../layout/layout';
import StoreButton from '../store_button/store_button';
import './navigation.css';

export default function Navigation() {
  const { onNavMenuClick, navOpen, onBodyClick, pathname } = useNavigation();
  const { hasBackground } = useScroll();

  return (
    <div className={`${hasBackground ? 'nav-bg' : 'nav-no-bg'} navigation p-fixed full-width`}>
      <div className="d-flex j-space-between align-center no-mobile container app-padding-lr ptb20">
        <NavigationItem hasBackground={hasBackground} pathname={pathname} />
        {/* onClick={() => (openWaitlistModal(dispatch))} */}
        <div className="nav-links always-active fw500">
          <Link to="/signup" style={{ padding: '8px 30px', borderRadius: '3px' }}>
            Get Started
          </Link>
        </div>
      </div>
      <MobileNavigation onBodyClick={onBodyClick} onNavMenuClick={onNavMenuClick} navOpen={navOpen} />
    </div>
  );
}

const MobileNavigation = ({ onNavMenuClick, onBodyClick, navOpen }) => {
  return (
    <div className="no-desktop-but-mobile-flex navigation j-space-between content-padding">
      <AppIcon />
      <button className="unstyle-button c-pointer" onClick={onNavMenuClick}>
        <img src={navigation} className="navigation-icon" alt="navigation button" />
      </button>
      {navOpen !== null && <MobileNavigationItem className={navOpen ? 'nav-open' : 'nav-close'} onClick={onBodyClick} innerContent={<NavInnerContent />} />}
    </div>
  );
};

const NavigationItem = ({ className }) => {
  const { pathname } = useNavigation();
  const activeLink = (path) => (pathname === path ? 'active-link' : '');

  return (
    <div className={`${className || ''}`}>
      <div className="main-portion">
        <div className="content">
          <ul className="unstyle-ul d-flex centents j-space-between">
            <div className="">
              <AppIcon />
            </div>
            <Spacer width={80} />
            <li className="nav-links fw400">
              <Link className={activeLink('/merchant-page')} to="/merchant-page">
                Merchants
              </Link>
            </li>
            <Spacer width={40} />
            <li className="nav-links fw400">
              <Link className={activeLink('/cashier-page')} to="/cashier-page">
                Cashiers
              </Link>
            </li>
            <Spacer width={40} />
            <li className="nav-links fw400">
              <Link className={activeLink('/developer-page')} to="/developer-page">
                Developers
              </Link>
            </li>
            <Spacer width={40} />
            <li className="nav-links fw400">
              <Link to="/login">Login</Link>
            </li>
            <Spacer width={40} />
          </ul>
        </div>
      </div>
    </div>
  );
};

const MobileNavigationItem = ({ className, onClick, innerContent }) => {
  return (
    <div className={`p-fixed whole-area z-index999 ${className || ''}`} onClick={(e) => onClick(e, false)}>
      <div onClick={(e) => onClick(e, true)} className="p-absolute accent-bg-color main-portion">
        {innerContent}
      </div>
    </div>
  );
};

export const MobileDashboardNav = ({ onClick, className, links, navKeys, beforeNavWidget, isCashier, BOTTOM_CARD_TEXT }) => (
  <MobileNavigationItem
    onClick={onClick}
    className={className}
    innerContent={<AppNav links={links} navKeys={navKeys} beforeNavWidget={beforeNavWidget} isCashier={isCashier} BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT} />}
  />
);

export const NavInnerContent = () => (
  <div className="d-flex content flex-vertical p-absolute d-flex-space-between">
    <ul className="unstyle-ul">
      <li className="nav-links fw400">
        <Link to="/">Home</Link>
      </li>
      <Spacer width={40} />
      <li className="nav-links fw400">
        <Link to="/merchant-page">Merchants</Link>
      </li>
      <Spacer width={40} />
      <li className="nav-links fw400">
        <Link to="/cashier-page">Cashiers</Link>
      </li>
      <Spacer width={40} />
      <li className="nav-links fw400">
        <Link to="/developer-page">Developers</Link>
      </li>
      <Spacer width={40} />
      <li className="nav-links fw400">
        <Link to="/login">Login</Link>
      </li>
      <Spacer width={40} />
      {/* <li className='nav-links always-active'><Link to='/'>Join Waitlist</Link></li> */}
    </ul>
    <StoreButton className="nav-store-button" orientation="vertical" />
  </div>
);
