import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState: {
    id: '',
    email: '',
    role: '',
    roles: [],
  },
  reducers: {
    setMemberId: (state, { payload }) => {
      state.id = payload;
    },
    setMemberEmail: (state, { payload }) => {
      state.email = payload;
    },
    setMemberRole: (state, { payload }) => {
      state.role = payload;
    },
    setTeamRoles: (state, { payload }) => {
      state.roles = payload;
    },
    resetMemberId: (state) => {
      state.id = '';
    },
    resetMemberEmail: (state) => {
      state.email = '';
    },
    resetMemberRole: (state) => {
      state.role = '';
    },
    resetTeamRoles: (state) => {
      state.roles = [];
    },
  },
});

export const { setMemberEmail, setMemberRole, resetMemberEmail, resetMemberRole, resetTeamRoles, setTeamRoles, resetMemberId, setMemberId } = rolesSlice.actions;
const rolesReducer = rolesSlice.reducer;
export default rolesReducer;
