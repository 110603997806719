import { LoadingOutlined } from '@ant-design/icons';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import photo from '../../assets/images/photo-template.svg';
import AppButton from '../../components/app_button/app_button';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import Popup, { PasswordResetVideoPopup, PinChangeVideoPopup } from '../../components/popup/popup';
import { useFeatureFlag } from '../../hooks/use_feature_flag';
import { OTPInputGrid, PinInputGrid } from './withdraw';

import { useInput } from '../../hooks/use_input';
import { fetchUserMerchant } from '../../redux/merchant/merchant_actions';
import { changePassword, forgetPinInitial, forgotPin, initialPinSet, requestOTP, resetPin, validateChangePassword, validatePinReset } from '../../services/apiSevices';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';

export const Profile = () => {
  const merchant = useSelector((state) => state.merchant.merchantDetails);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [isResetPinModal, setIsResetPinModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { issetpin } = useSelector((state) => state.merchant.merchantDetails);
  const { isFlagRequired } = useFeatureFlag('hide.pin.reset');
  const [page, setPage] = useState(1);

  const onForgotPinClick = async () => {
    try {
      setIsLoading(true);
      const res = await requestOTP(key);
      openNotificationWithIcon('success', 'Success', res.data.message);
      setIsLoading(false);
      setTimeout(() => {
        setIsResetPinModal(true);
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      openNotificationWithIcon('error', 'Error', error.response.data.message);
    }
    // setIsResetPinModal(true)
  };

  return (
    <>
      <div className="profile-wrapper primary-bg-color p-relative">
        <Spacer height={50} />
        <div className="d-flex full-width align-center j-center">
          <img src={photo} alt="" style={{ height: '65px' }} />
        </div>
        <Spacer height={30} />
        <div className="d-flex align-center flexCol j-center">
          <span className="fw400 text-color font-size-14">First Name</span>
          <Spacer width={55} />
          <FormInput value={merchant.contactfirstname} disabled={true} className="profile-input" />
        </div>
        <Spacer height={10} />
        <Spacer height={15} className="no-desktop-but-mobile" />
        <div className="d-flex align-center flexCol j-center">
          <span className="fw400 text-color font-size-14">Last Name</span>
          <Spacer width={55} />
          <FormInput value={merchant.contactlastname} disabled={true} className="profile-input" />
        </div>
        <Spacer height={10} />
        <Spacer height={15} className="no-desktop-but-mobile" />
        <div className="d-flex align-center flexCol j-center">
          <span className="fw400 text-color font-size-14">Phone No</span>
          <Spacer width={60} />
          <FormInput value={merchant.businessphone} disabled={true} className="profile-input" />
        </div>
        <Spacer height={10} />
        <Spacer height={15} className="no-desktop-but-mobile" />
        <div className="d-flex align-center flexCol j-center">
          <span className="fw400 text-color font-size-14">Email Address</span>
          <Spacer width={30} />
          <FormInput value={merchant.businessemail} disabled={true} className="profile-input" />
        </div>
        <Spacer height={15} />
        {issetpin && (
          <>
            <div className="d-flex align-center flexCol j-center">
              <span className="fw400 text-color font-size-14 mail-ab">Transaction Pin</span>
              <Spacer width={25} />
              <div className="mobile-pin-btn-wrap" style={{ width: '65%' }}>
                <button onClick={() => setOpenPinModal(true)} className="pin-btn full-width c-pointer">
                  Change Transaction Pin
                </button>
              </div>
            </div>
            <Spacer height={15} />
          </>
        )}
        <div className="d-flex align-center flexCol j-center">
          <span className="fw400 text-color font-size-14 mail-ab">Password</span>
          <Spacer width={65} />
          <div className="mobile-pin-btn-wrap" style={{ width: '65%' }}>
            <button onClick={() => setPasswordResetModal(true)} className="pin-btn full-width c-pointer">
              Change Password
            </button>
          </div>
        </div>
        <Spacer height={30} />
        {issetpin && (
          <>
            <div className="d-flex flexCol align-center j-center">
              <button onClick={onForgotPinClick} className="fw400 unstyle-button text-color c-pointer font-size-16 opacity-5">
                Forgot Pin? Click Here To Reset Pin
              </button>
            </div>
            <Spacer height={10} />
          </>
        )}
        <div className="d-flex align-center j-center">{isLoading && <LoadingOutlined />}</div>
        <Spacer height={30} />
      </div>
      <Spacer height={30} />
      {/* <div style={{width : '55%', display : 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <AppButton isActive={true} className="s-c font-size-16 fw400 primary-color" name="Save Changes"/>
            </div> */}
      {openPinModal && PinChange(isFlagRequired, setOpenPinModal, setPage)}
      {passwordResetModal && PasswordChange(isFlagRequired, setPasswordResetModal)}
      {isResetPinModal && PinReset(isFlagRequired, setIsResetPinModal)}
      {/* <InitialPinSetUp/> */}
    </>
  );
};

{
  /* <TransactionPin setOpenPinModal={setOpenPinModal} /> */
}
const PinChange = (flag, setOpenPinModal, setPage) => {
  if (flag) {
    return <TransactionPinWithLivelinessVideo setOpenPinModal={setOpenPinModal} setPage={setPage} />;
  }
  return <TransactionPin setOpenPinModal={setOpenPinModal} />;
};

const PasswordChange = (flag, setPasswordResetModal) => {
  if (flag) {
    return <ChangePasswordWithLivelinessVideo setPasswordResetModal={setPasswordResetModal} />;
  }
  return <ResetPassword setPasswordResetModal={setPasswordResetModal} />;
};

const PinReset = (flag, setIsResetPinModal) => {
  if (flag) {
    return <ResetPinWithLivelinessVideo setIsResetPinModal={setIsResetPinModal} />;
  }
  return <ResetPin setIsResetPinModal={setIsResetPinModal} />;
};

export const TransactionPin = ({ setOpenPinModal }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [loading, setLoading] = useState(false);
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  const resetMerchantPin = async () => {
    if (newPin !== confirmPin) {
      openNotificationWithIcon('warning', 'Error', "Pin Don't Match");
      return;
    }
    if (!newPin && !confirmPin && !oldPin) {
      return;
    }
    try {
      const data = {
        old_pin: oldPin,
        new_pin: newPin,
      };
      setLoading(true);
      const res = await resetPin(key, data);
      if (res?.data?.status === true) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Pin Changed Successfully');
        setOpenPinModal(false);
      } else {
        setLoading(false);
        openNotificationWithIcon('error', 'Error', res?.data?.message);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Popup title="Change transaction pin" onClose={() => setOpenPinModal(false)}>
      <div className="d-flex align-center j-center flex-vertical">
        <Spacer height={30} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Enter Old Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setOldPin} />
        </div>
        <Spacer height={20} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Enter New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setNewPin} />
        </div>
        <Spacer height={20} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Confirm New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setConfirmPin} />
        </div>
        <Spacer height={30} />
        <AppButton isActive={true} isBusy={loading} onClick={resetMerchantPin} className="reset-pin-btn primary-color" name="Change My Pin" />
      </div>
    </Popup>
  );
};

export const TransactionPinWithLivelinessVideo = ({ setOpenPinModal }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1);
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  const checkPin = async () => {
    if (newPin !== confirmPin) {
      openNotificationWithIcon('warning', 'Error', "Pin Don't Match");
      return;
    }
    if (!newPin && !confirmPin && !oldPin) {
      return;
    }
    try {
      const data = {
        old_pin: oldPin,
        new_pin: newPin,
      };
      setLoading(true);
      const res = await validatePinReset(key, data);
      if (res?.data?.status === true) {
        setLoading(false);
        setStage(2);
      } else {
        setLoading(false);
        openNotificationWithIcon('error', 'Error', res?.data?.message);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {stage === 1 && (
        <Popup title="Change transaction pin" onClose={() => setOpenPinModal(false)}>
          <div className="d-flex align-center j-center flex-vertical">
            <Spacer height={30} />
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Enter Old Pin</p>
              <Spacer height={10} />
              <PinInputGrid setValue={setOldPin} setFocus={true} />
            </div>
            <Spacer height={20} />
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Enter New Pin</p>
              <Spacer height={10} />
              <PinInputGrid setValue={setNewPin} setFocus={false} />
            </div>
            <Spacer height={20} />
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Confirm New Pin</p>
              <Spacer height={10} />
              <PinInputGrid setValue={setConfirmPin} setFocus={false} />
            </div>
            <Spacer height={30} />
            <AppButton isActive={true} isBusy={loading} onClick={() => checkPin()} className="reset-pin-btn primary-color" name="Continue" />
          </div>
        </Popup>
      )}
      {stage === 2 && (
        <Popup title="Confirm transaction pin" onClose={() => setOpenPinModal(false)}>
          <div className="d-flex align-center j-center flex-vertical">
            <Spacer height={30} />
            <PinChangeVideoPopup onClose={() => setStage(1)} oldPin={oldPin} newPin={newPin} service={resetPin} folderName="Pin Change" setOpenPinModal={setOpenPinModal} />
          </div>
        </Popup>
      )}
    </div>
  );
};

export const ResetPin = ({ setIsResetPinModal }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  // const otpProps = useInput('');
  const [loading, setLoading] = useState(false);
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [otp, setOtp] = useState('');

  const forgotPinReset = async () => {
    if (newPin !== confirmPin) {
      openNotificationWithIcon('warning', 'Error', "Pin Don't Match");
      return;
    }
    if (!otp) {
      openNotificationWithIcon('warning', 'Error', 'Input valid OTP');
      return;
    }
    if (otp.length < 6) {
      openNotificationWithIcon('warning', 'Error', 'OTP not up to 6');
      return;
    }
    if (!newPin && !confirmPin && !otp) {
      return;
    }
    if (newPin !== confirmPin) {
      return openNotificationWithIcon('warning', 'Warning', 'Pin do not match');
    }
    try {
      const data = {
        otp: otp,
        pin: newPin,
      };
      setLoading(true);
      const res = await forgotPin(key, data);
      if (res?.data?.status === true) {
        setLoading(false);

        openNotificationWithIcon('success', 'Success', 'Pin Reset Successfully');
        setIsResetPinModal(false);
      } else {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', res?.data?.message);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };
  return (
    <Popup title="Reset pin" onClose={() => setIsResetPinModal(false)}>
      <div className="d-flex align-center j-center flex-vertical">
        <Spacer height={30} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Enter OTP sent to your phone or email</p>
          <Spacer height={10} />
          {/* <FormInput {...otpProps} className="width-275" /> */}
          <OTPInputGrid setValue={setOtp} />
        </div>
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Enter New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setNewPin} />
        </div>
        <Spacer height={20} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Confirm New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setConfirmPin} />
        </div>
        <Spacer height={30} />
        <AppButton isActive={true} onClick={forgotPinReset} isBusy={loading} className="reset-pin-btn primary-color" name="Reset My Pin" />
      </div>
    </Popup>
  );
};

export const ResetPinWithLivelinessVideo = ({ setIsResetPinModal }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  // const otpProps = useInput('');
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [otp, setOtp] = useState('');

  const onClose = () => {
    setStage(1);
  };
  const popupClose = () => {
    setIsResetPinModal(false);
  };

  const forgotPinReset = async () => {
    if (newPin !== confirmPin) {
      openNotificationWithIcon('warning', 'Error', "Pin Don't Match");
      return;
    }
    if (!otp) {
      openNotificationWithIcon('warning', 'Error', 'Input valid OTP');
      return;
    }
    if (otp.length < 6) {
      openNotificationWithIcon('warning', 'Error', 'OTP not up to 6');
      return;
    }
    if (!newPin && !confirmPin && !otp) {
      return;
    }
    if (newPin !== confirmPin) {
      return openNotificationWithIcon('warning', 'Warning', 'Pin do not match');
    }
    try {
      const data = {
        otp: otp,
        pin: newPin,
      };
      setLoading(true);

      const res = await forgetPinInitial(key, data);
      //const res = { data: { status: true } };
      if (res?.data?.status === true) {
        setLoading(false);

        //openNotificationWithIcon('success', 'Success', 'Pin Reset Successfully');
        //setIsResetPinModal(false);
        setStage(2);
      }
      // else {
      //   setLoading(false);
      //   openNotificationWithIcon('success', 'Success', res?.data?.message);
      // }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {stage === 1 && (
        <Popup title="Reset pin" onClose={() => setIsResetPinModal(false)}>
          <div className="d-flex align-center j-center flex-vertical">
            <Spacer height={30} />
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Enter OTP sent to your phone or email</p>
              <Spacer height={10} />
              {/* <FormInput {...otpProps} className="width-275" /> */}
              <OTPInputGrid setValue={setOtp} setFocus={true} />
            </div>
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Enter New Pin</p>
              <Spacer height={10} />
              <PinInputGrid setValue={setNewPin} setFocus={false} />
            </div>
            <Spacer height={20} />
            <div className="p-relative">
              <p className="font-size-13 fw400 text-color opacity-5">Confirm New Pin</p>
              <Spacer height={10} />
              <PinInputGrid setValue={setConfirmPin} setFocus={false} />
            </div>
            <Spacer height={30} />
            <AppButton isActive={true} onClick={forgotPinReset} isBusy={loading} className="reset-pin-btn primary-color" name="Continue" />
          </div>
        </Popup>
      )}
      {stage === 2 && <PasswordResetVideoPopup isPin={true} otp={otp} onClose={onClose} folderName="Pin Reset" closePasswordPopup={popupClose} newPassword={newPin} service={forgotPin} />}
    </div>
  );
};

export const InitialPinSetUp = ({ onClose, setShouldSetPin }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const [pin, setPin] = useState('');
  const dispatch = useDispatch();
  const [confirmPin, setConfirmPin] = useState('');
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      if (pin !== confirmPin) {
        openNotificationWithIcon('warning', 'Warning', 'Pin Must Match');
      } else if (pin && confirmPin) {
        const data = {
          pin,
        };
        setLoading(true);
        await initialPinSet(key, data);
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Pin Set Success');
        setShouldSetPin(false);
        dispatch(fetchUserMerchant(id));
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response.data.message);
    }
  };
  return (
    <Popup title="CREATE PIN" onClose={onClose}>
      <div className="d-flex align-center j-center flex-vertical">
        <Spacer height={30} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Enter New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setPin} />
        </div>
        <Spacer height={20} />
        <div className="p-relative">
          <p className="font-size-13 fw400 text-color opacity-5">Confirm New Pin</p>
          <Spacer height={10} />
          <PinInputGrid setValue={setConfirmPin} />
        </div>
        <Spacer height={30} />
        <AppButton isActive={true} isBusy={loading} onClick={onClick} className="reset-pin-btn primary-color" name="Create" />
      </div>
    </Popup>
  );
};

export const ResetPassword = ({ setPasswordResetModal }) => {
  const newPasswordProps = useInput('', 'password');
  const confirmPasswordProps = useInput('', 'password');
  const oldPasswordProps = useInput('', 'password');
  const [loading, setLoading] = useState(false);

  const clearFields = () => {
    newPasswordProps.reset();
    confirmPasswordProps.reset();
    oldPasswordProps.reset();
  };

  const onClick = async () => {
    if (newPasswordProps.value && confirmPasswordProps.value) {
      if (confirmPasswordProps.value !== newPasswordProps.value) {
        openNotificationWithIcon('warning', 'Warning', "Passwords don't match");
        return;
      }
      if (!confirmPasswordProps.value || !newPasswordProps.value || !oldPasswordProps.value) {
        return;
      }
      try {
        setLoading(true);
        const res = await changePassword({ password: newPasswordProps.value, old_password: oldPasswordProps.value });
        if (res?.data?.status === true) {
          setLoading(false);
          openNotificationWithIcon('success', 'Success', 'Password changed successfully');
          clearFields();
          setPasswordResetModal(false);
        } else {
          setLoading(false);
          openNotificationWithIcon('error', 'Error', res?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };
  return (
    <Popup title="RESET PASSWORD" onClose={() => setPasswordResetModal(false)}>
      <Spacer height={40} />
      <FormInput {...oldPasswordProps} isPassword className="reset-input full-width" label="Old Password" />
      <FormInput {...newPasswordProps} isPassword className="reset-input full-width" label="New Password" />
      <FormInput {...confirmPasswordProps} isPassword className="reset-input full-width" label="Confirm New Password" />
      <AppButton isBusy={loading} isActive={true} onClick={onClick} className="reset-pin-btn primary-color reset-input" name="Change My Password" />
    </Popup>
  );
};

export const ChangePasswordWithLivelinessVideo = ({ setPasswordResetModal }) => {
  const newPasswordProps = useInput('', 'password');
  const confirmPasswordProps = useInput('', 'password');
  const oldPasswordProps = useInput('', 'password');
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1);

  const clearFields = () => {
    newPasswordProps.reset();
    confirmPasswordProps.reset();
    oldPasswordProps.reset();
  };

  const onClick = async () => {
    if (newPasswordProps.value && confirmPasswordProps.value) {
      if (confirmPasswordProps.value !== newPasswordProps.value) {
        openNotificationWithIcon('warning', 'Warning', "Passwords don't match");
        return;
      }
      if (!confirmPasswordProps.value || !newPasswordProps.value || !oldPasswordProps.value) {
        return;
      }
      try {
        setLoading(true);
        const res = await validateChangePassword({ password: newPasswordProps.value, old_password: oldPasswordProps.value });
        if (res?.data?.status === true) {
          setLoading(false);
          setStage(2);
        } else {
          setLoading(false);
          openNotificationWithIcon('error', 'Error', res?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };
  return (
    <div>
      {stage === 1 && (
        <Popup title="CHANGE PASSWORD" onClose={() => setPasswordResetModal(false)}>
          <Spacer height={40} />
          <FormInput {...oldPasswordProps} isPassword className="reset-input full-width" label="Old Password" />
          <FormInput {...newPasswordProps} isPassword className="reset-input full-width" label="New Password" />
          <FormInput {...confirmPasswordProps} isPassword className="reset-input full-width" label="Confirm New Password" />
          <AppButton isBusy={loading} isActive={true} onClick={onClick} className="reset-pin-btn primary-color reset-input" name="Continue" />
        </Popup>
      )}
      {stage === 2 && (
        <Popup title="CONFIRM PASSWORD CHANGE" onClose={() => setPasswordResetModal(false)}>
          <Spacer height={40} />
          <PinChangeVideoPopup
            onClose={() => setStage(1)}
            oldPin={oldPasswordProps.value}
            newPin={newPasswordProps.value}
            service={changePassword}
            folderName="Change Password"
            setOpenPinModal={setPasswordResetModal}
            isPassword={true}
          />
        </Popup>
      )}
    </div>
  );
};
