import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeTourVisibilty } from '../redux/tour/tour.feature';
import { isDesktop } from '../utils/helper';
import useURLType from './use_url_type';

export default function useTour(tourClassVisible, index) {
  const history = useHistory();
  const dispatch = useDispatch();
  const type = useURLType();
  useEffect(() => {
    if (!isDesktop()) {
      dispatch(changeTourVisibilty());
      return;
    }
    if (tourClassVisible) {
      let url = '';
      switch (index) {
        case 1:
          url = type === 'merchant' ? '/admin/merchant' : '/admin/cashpoint';
          history.push(url || '/');
          break;
        case 2:
          url = type === 'merchant' ? '/admin/merchant/cashpoint' : '/admin/cashpoint/settings';
          history.push(url || '/');
          break;
        case 3:
          url = type === 'merchant' ? '/admin/merchant/cashpoint' : '/admin/cashpoint';
          history.push(url || '/');
          break;
        case 4:
          url = type === 'merchant' ? '/admin/merchant/settings' : '/admin/cashpoint';
          history.push(url || '/');
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
}
