import { Spacer } from '../../components/layout/layout';

export const QA = [
  {
    title: "Why is my VPay Account number not active after sign up and people can't send money to my VPay Account?",
    content: (
      <div className="fw400 body-details font-size-15">
        After a successful sign up on the VPay platform, you need to immediately upload your Identity or Know Your Customer (KYC) documents using the "Upload KYC Documents" link on your home screen. Your documents will be reviewed in a matter of hours.
        <Spacer height={10} />
        A VPay support personnel might also reach out to you to further confirm your nature of business and provide necessary guidance.
        <Spacer height={10} />
        Once VPay certifies your KYC documents, your account will be activated and you can enjoy your seamless transactions using VPay.
      </div>
    ),
  },
  {
    title: 'My customer made a transfer to me and he has been debited but I have not received the funds. He also sent me proof of transfer.',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly send the proof of payment to any of our support channels. We will also need the details of the sender, the bank from which the transaction was made, the date and amount of the
        transaction as well as your business name, account number and email address.
      </div>
    ),
  },
  {
    title: 'I bought airtime/paid for electricity but I have not received the token.',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly reachout to any of our support channels with a screenshot of the transaction as well as your business name, email address and account number.
      </div>
    ),
  },
  {
    title: "I don't have access to my BVN linked number and I didn't get the OTP.",
    content: (
      <div className="fw400 body-details font-size-15">
        Reach out to any of the VPay support channels through your registered phone number or email with VPay.
        <Spacer height={10} />
        Send your business name, email and the phone number linked to your BVN (it's usually shown on the "Verify BVN" page during BVN authentication on the VPay app, and although masked, you can still see the first
        3 digits and last 2 digits of your BVN phone number).
        <Spacer height={10} />
        Once you provide the particular phone number that's linked with your BVN to the support rep, the BVN OTP will be forwarded to the phone number you used to sign up on VPay platform.
        <Spacer height={5} />
        Enter the BVN OTP to authenticate it.
      </div>
    ),
  },
  {
    title: 'How to pay into VPay using the unique USSD code.',
    content: (
      <div className="fw400 body-details font-size-15">
        You can make fast payments from your bank using your unique USSD code
        <Spacer height={5} />
        This are the steps below:
        <Spacer height={4} />
        Dial <strong style={{ wordBreak: 'break-all' }}>*BankUSSDCode*000*5037+VPayAccountNumber+Amount#</strong>
        <Spacer height={5} />
        This means for example, if you want to send 100 Naira to VPay account "4600040982" using GTBank USSD, you will dial something like this:
        <Spacer height={5} />
        <strong>*737*000*5037+4600040982+100#</strong>
        <Spacer height={5} />
        If its from UBA bank, you'll change to UBA bank code (919*000.... etc), First Bank (*894*000...etc).
        <Spacer height={8} />
        <strong>NB</strong>: Note that there are two plus "+" signs on this unique code. One is after "5037" and another after the VPay account number as shown below:
        <Spacer height={5} />
        <strong>*894*000*5037+4600040982+100#</strong>
        <Spacer height={5} />
        Do NOT mistake the plus signs for a star sign "*"
        <Spacer height={10} />
        Follow the exact process with your bank code, account number and the amount placed in the right places. The payment will go through as expected.
      </div>
    ),
  },
  {
    title: 'How can I log in using biometrics (Fingerprint or Face ID) on VPay?',
    content: (
      <div className="fw400 body-details font-size-15">
        To log in using biometrics on VPay, you must have signed up on the VPay platform and logged in initially with your Email and Password.
        <Spacer height={10} />
        After your initial log in, the next time you access the login screen, you will see "Login with Biometrics" located slightly above the "Sign In" button.
        <Spacer height={10} />
        Click on it to access the biometric log in options.
      </div>
    ),
  },
  {
    title: 'Does VPay have any transaction limits?',
    content: (
      <div className="fw400 body-details font-size-15">
        Yes, at the moment VPay applies an outbound transfer limit of ₦50,000 for partially verified accounts.
        <Spacer height={20} />
        This amount is not a daily limit. It’s a cummulative total limit for all your outgoing transactions on the app i.e. transfers, withdrawals and billing transactions. Once it totals ₦50,000, the
        restrictions will set in until you provide complete KYC documentation to verify your account.
        <Spacer height={20} />
        However, your account may receive funds up to any amount. Restrictions only apply to withdrawals, transfers and billing transactions.
      </div>
    ),
  },
  {
    title: 'How do I upgrade my account?',
    content: (
      <div className="fw400 body-details font-size-15">
        <p>To upgrade your account, please send the following Know Your Customer (KYC) information/documents to support@vpay.africa after signing up on VPay:</p>
        <Spacer height={20} />
        <p className="fw400">
          <strong>Your Business Name</strong>
        </p>
        <p className="fw400">
          <strong>Your Email Address</strong>
        </p>
        <Spacer height={20} />
        Required documents include:
        <Spacer height={20} />
        (a) One passport photograph
        <Spacer height={20} />
        (b) Valid ID card (either National ID Card, Voters Card, Intl Passport, Driver's Licence)
        <Spacer height={20} />
        (c) Proof of Address, such as a utility bill (not older than 1 month). We will accept a PoS bill printout for prepaid electricity that shows your address.
        <Spacer height={20} />
        (d) Bank Verification Number (BVN)
        <Spacer height={20} />
        NOTE: Other acceptable documents for Proof of Address include:
        <Spacer height={20} />
        1. Rent receipt showing your address and your name.
        <Spacer height={20} />
        2. Waste management bill receipt showing your name and address.
        <Spacer height={20} />
        3. Water bill receipt showing your name and address.
        <Spacer height={20} />
        4. Bank statement (not more than 1 month old) showing your name and address.
        <Spacer height={20} />
        <strong>
          Based on the nature of business and your present or future usage patterns, you may be required to provide more information to fully utilize your account at any time. The documents you submit will be validated and applied to KYC compliance requirements in accordance to government regulations and Nigerian laws.
        </strong>
      </div>
    ),
  },
  {
    title: 'My password is being rejected. Why?',
    content: (
      <div className="fw400 body-details font-size-15">
        We have a strong password policy. Your password must have a capital letter, small letter, numbers, and special characters like $, @, #, %, & before the app can accept it.
        <Spacer height={20} />
        Please include all these on your password and you will navigate to the next page. Ensure you are not using a full stop '.' or an underscore '_' as a special character as the system will reject
        it. Use any of the special characters listed above.
      </div>
    ),
  },
  {
    title: "Why can't I register? When I click the register button nothing is happening.",
    content: (
      <div className="fw400 body-details font-size-15">
        Ensure you have filled all registration details and have followed password guidelines as we have a strong password policy.
        <Spacer height={20} />
        Your password must have a capital letter, small letter, numbers, and special characters like $,@, #, %, & ensuring you are not using a full stop '.' or an underscore '_' as a special character
        as the system will reject it.
        <Spacer height={20} />
        Use any of the special characters listed above. before the app can accept it. Please include all these on your password and you will navigate to the next page.
      </div>
    ),
  },
  {
    title: 'How do I see my VPay account number?',
    content: <div className="fw400 body-details font-size-15">Once your KYC documents are verified, login and find your account number at the top left area of your Home screen.</div>,
  },
  {
    title: 'How do I fund my account?',
    content: (
      <div className="fw400 body-details font-size-15">
        Please tap on the profile icon on the top-left of your Home screen to display your VPay account number. Enter this account number on any commercial bank app, the bank to click on is{' '}
        <strong>VFD MICROFINANCE BANK</strong> or <strong>VBANK</strong>.
      </div>
    ),
  },
  {
    title: 'How can I add a transaction PIN?',
    content: (
      <div className="fw400 body-details font-size-15">
        On your home page, you will see an option to "<strong>Set Transaction PIN</strong>". Click on ‘<strong>Set Transaction PIN</strong>’ to add a transaction <strong>PIN</strong>. Alternatively,
        you will be prompted to set your <strong>PIN</strong> when you initiate your very first outbound transaction.
      </div>
    ),
  },
  {
    title: 'How do I change my transaction PIN?',
    content: (
      <div className="fw400 body-details font-size-15">
        Sign in, then click on the Profile menu (located at the top right of the Home screen for mobile app; located on the Settings page for the web app www.vpay.africa)
        <Spacer height={10} />
        Click on ‘Change Transaction PIN’
        <Spacer height={10} />
        Enter old PIN and enter new PIN.
        <Spacer height={20} />
        If you forgot your transaction PIN, click on Reset PIN option to reset your PIN. You will get a temporary OTP on your registered email. Use it to reset your transaction PIN.
      </div>
    ),
  },
  {
    title: 'How does the VPay payment system work for small businesses?',
    content: (
      <div className="fw400 body-details font-size-15">
        When you sign up to VPay Merchant app as a business, you automatically get a bank account linked to your business name. This helps you separate your personal transactions from professional ones.
        <Spacer height={20} />
        When your customers make transfers to your VPay account you are able to verify faster, plus you get charged lower bank fees.
        <Spacer height={20} />
        Additionally, you may add sub accounts for your cashiers or products and each cashier can then receive and instantly verify customer transfers in your absence using the VPay Cashier app.
      </div>
    ),
  },
  {
    title: 'Can I make international transfers using VPay?',
    content: (
      <div className="fw400 body-details font-size-15">
        Unfortunately, we do not support international transfers at the moment. The VPay app is essentially designed to help businesses receive and instantly confirm customer bank transfers from banks within Nigeria.
      </div>
    ),
  },
  {
    title: 'What is VPay pricing structure?',
    content: (
      <div className="fw400 body-details font-size-15">
        VPay caters for regular businesses and also for Agency Banking businesses.
        <Spacer height={20} />
        <p className="fw500 font-size-15">(a)Regular Business</p>
        <Spacer height={20} />
        For regular businesses, VPay charges 0.3% - 0.5% for <strong>inbound</strong> transfers. Inbound transfers are the funds you receive from your customers into your VPay account. This charge is capped at 100 Naira or 200 Naira for special use cases.
        <Spacer height={20} />
        A 0.3% charge for <strong>inbound</strong> transfers in real scenario means that if you receive ₦1,000 we would charge you 3 Naira which is 0.3% of 1,000. For 10,000 received our charge is 30
        Naira. In the least expensive use case, for 100,000 our charge is 100 Naira and that is the highest charge. Even if you receive 300,000, our charge will still be 100 Naira.
        <Spacer height={20} />
        For <strong>outbound</strong> transfers using the app, we charge 20 Naira.
        <Spacer height={20} />
        It's a flat rate, so any amount you wish to transfer to another bank using VPay will attract a 20 Naira charge.
        <Spacer height={20} />
        All withdrawals to your pre-registered bank account is free. You may register this bank account in your Settings page. We won’t charge you when you s withdraw to this account.
        <Spacer height={20} />
        <p className="fw500 font-size-15">(b) POS (Agency Banking) Business</p>
        <Spacer height={20} />
        A POS business industry is recognized on the VPay app when a merchant signs up using ‘Banking’ or ‘Agency Banking’ as their business industry type.
        <Spacer height={20} />
        For POS Business, depending on the use case, we charge 0.3% - 0.5% for <strong>inbound</strong> transfers capped at 100 Naira.
        <Spacer height={20} />
        For <strong>outbound</strong> transfers, we charge 5 Naira when the transfer amount is below 2,000 Naira. We charge 15 Naira when the transfer amount is above 2,000 Naira. This is a flat rate.
        <Spacer height={20} />
        Withdrawing your funds to your pre-registered bank account is FREE!
      </div>
    ),
  },
  {
    title: 'How do I change my buisness name, personal name or email?',
    content: (
      <div className="fw400 body-details font-size-15">
        Send a Whatsapp message to our support line +2348028108892 using the phone number you registered during Sign Up. Alternatively, you may also mail us from the email used during Sign Up. Send your message to 'support@vpay.africa.'
        <Spacer height={20} />
        Include the following details in your message:
        <Spacer height={20} />
        <ul>
          <li className="font-size-15 fw400 text-color">Your business name, account number and email address.</li>
          <li className="font-size-15 fw400 text-color">Send the new details that you would like VPay to update your records with.</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'How do I open a vpay account?',
    content: (
      <div className="fw400 body-details font-size-15">
        If you are a business owner, visit the Google Play store or Apple App store and search for "VPay Merchant". Download the VPay Merchant app, then follow through the Sign Up registration process.
        <Spacer height={20} />
        <span className="fw500 font-size-15">Note: </span>To open your account using the web channel, visit 'www.vpay.africa' from your preferred web browser.
        <Spacer height={20} />
        For your cashiers or point of sale attendants, download and install the VPay Cashier/Cashpoint on their phones.
      </div>
    ),
  },
  {
    title: 'How do I add a new business to my account?',
    content: (
      <div className="fw400 body-details font-size-15">
        <ul>
          <li className="font-size-15 fw400 text-color">Click on the profile button</li>
          <li className="font-size-15 fw400 text-color">Click on your default Business Name located below "My Profile"</li>
          <li className="font-size-15 fw400 text-color">From the screen that appears click on "Add New Merchant." Follow the prompt and add a new business.</li>
        </ul>
        <Spacer height={20} />
        You can always click on the Business Name from the profile page to select which business you would want to switch to. Once you select the business, go to the home screen and your business will be switched. Your VPay account will switch to the selected business.
      </div>
    ),
  },
  {
    title: 'Why do I need to link my personal bank account to my VPay account?',
    content: (
      <div className="fw400 body-details font-size-15">
        Your personal bank account is needed to receive funds for free from your VPay business account. When customers send funds to your VPay account, it reflects on your VPay account balance which is seen on the home screen. You have the option to use the withdraw option to withdraw the funds to the personal bank account you added on the VPay platform.
      </div>
    ),
  },
  {
    title: 'Are funds secure with VPay?',
    content: (
      <div>
        VPay is powered by VBank (VFB Microfinance Bank), owned by VFD Group PLC which is a publicly traded investment entity that is regulated by SEC while the bank is also regulated by CBN. All depositor funds are insured by Nigeria Deposit Insurance Corporation (NDIC). You can be assured of the safety of your funds.
      </div>
    ),
  },
  {
    title: 'What is the difference between VPay Merchant, VPay Cashier and VBank?',
    content: (
      <div className="fw400 body-details font-size-15">
        VPay Merchant is for business owners. When they sign up on the platform, they can quickly get professional bank accounts as well options to sign up staff and monitor staff/outlet transactions from a single, simple dashboard, anywhere in the world.
        <Spacer height={20} />
        VPay Cashier application works together with VPay Merchant app. When a merchant adds a cashier or sales attendant to the business, the cashier is then able to download the VPay cashier and start indepently receiving payments and confirming bank transfers without access to funds.
        <Spacer height={20} />V BY VFD (or VBank app) is the digital personal banking app powered by VFD Microfinance Bank. The VFD MFB banking infrastructure also powers VPay's business banking infrastructure.
      </div>
    ),
  },
  {
    title: 'How do I add a cashier to my account as a merchant?',
    content: (
      <div className="fw400 body-details font-size-15">
        To add a Cashier: Log into your merchant app, and click on the ‘CashPoints’ Menu.
        <Spacer height={20} />
        Click on the long red bar that has your name on it. From the page that displays, click on the ‘Add New Cash point’ button. Alternatively, you may click on the + button located on the top right of the Cashpoint page to add a Cashpoint. (On the web app, click on "Add Cashpoint" button located on the top right of the Cashpoint menu).
        <Spacer height={20} />
        Enter the First Name and Last Name of the Cashier.
        <Spacer height={20} />
        Enter the Cashier’s phone number and email. (Email is optional).
        <Spacer height={20} />
        Note that VPay will send a temporary password to this cashier’s phone number. That is what they will use for their first login. They may change the password afterwards.
        <Spacer height={20} />
        VPay Cashier/Cashpoint App is accessible on the Apple App Store or Google Playstore.
      </div>
    ),
  },
  {
    title: 'How does the cashier app work?',
    content: (
      <div className="fw400 body-details font-size-15">
        Only a business owner or the VPay Merchant can add a Cashier on the VPay platform. When a cashier is added, they will receive a temporary password on the phone number the merchant used to add them. With this phone number and temporary password they would be able to log in.
        <Spacer height={20} />
        Once they have logged in, they can click on the account number button to view their own unique business account number tied to the Merchant account. Each cashier has a different unique account number which they use to collect payments. Any payment collected from the cashiers will go directly to the merchant that added them. <strong>CASHIERS CAN ONLY CONFIRM PAYMENTS</strong> made by
        customers. They don’t have access to the real funds and cannot make withdrawals from the account. Real funds go to the Business Owner or the VPay Merchant.
      </div>
    ),
  },
  {
    title: 'I can’t find VPay on my bank app.',
    content: (
      <div className="fw400 body-details font-size-15">
        If you are using a mobile banking app, kindly search for VFD Microfinance Bank, Vbank or VFD MFB. VFD Microfinance Bank is the bank VPay is integrated with.
        <Spacer height={20} />
        Alternatively, your customers can pay you via your unique USSD code which is listed below :
        <Spacer height={20} />
        The customer should type STAR, then code for his/her bank, then STAR, then Zero into 3 places that is zero zero zero, then STAR again, then 5037, then PLUS (if they don’t know how to type plus
        on their phone, they can long press the zero button on their phone, most phones will change that zero to a PLUS) then your VPay account number, then PLUS again, then amount then Hash and enter
        or dail.
        <Spacer height={10} />
        This will instantly transfer the amount to your VPay account.
        <Spacer height={10} />
        For clarity, it is;
        <Spacer height={5} />
        *Bank USSD Code*000* 5037+VPay Account Number+Amount#
        <Spacer height={20} />
        Please note that the bank USSD code is different for all banks, for First Bank, it is 894, for GTB, it is 737, and so on for other banks.
      </div>
    ),
  },
  {
    title: 'What is VPay License Number?',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly visit the VPay website - www.vpay.africa.
        <Spacer height={20} />
        All legal information is contained within the website footer.
      </div>
    ),
  },
  {
    title: 'I cannot find VFD Microfiance Bank on my bank USSD menu.',
    content: (
      <div className="fw400 body-details font-size-15">
        Your Unique *USSD CODE* is now available. See steps for using it below.
        <Spacer height={20} />
        *BankUSSDCode*000*5037+VPayAccountNumber+Amount#
        <Spacer height={20} />
        This means for example, if you want to send 100 Naira to this VPay account "4600040982" using a GTBank USSD, you will dial something like this:
        <Spacer height={5} />
        *737*000*5037+4600040982+100#
        <Spacer height={10} />
        Once you dial this, the payment will instantly go through after you authenticate it.
        <Spacer height={20} />
        If your are paying from UBA, you change the to UBA bank USSD code (*919*000*.... etc); First Bank (*894*000..., etc). Just follow the pattern and it will work.
      </div>
    ),
  },
  {
    title: "How do you ensure fraudulent people do not use VPay accounts to defraud people?",
    content: (
      <div className="fw400 body-details font-size-15">
        There is a need to submit proof of business ownership after sign up. Every new account has restrictions placed on it whereby users need to provide identity documents that will be validated and verified prior to the lifting of restrictions. We also have a fraud system that monitors for suspicious patterns of activity.
      </div>
    ),
  },
  {
    title: 'What does the error message ‘Transfer Limit Exceeded. Contact customer support to increase your limit’ mean?',
    content: (
      <div className="fw400 body-details font-size-15">
        This error message means you have exceeded your withdrawal/transfer/billing transaction limit on the app. For partially verified accounts, it might be 30,000. It’s important to note that after an account verification/upgrade, the limit for a merchant account is within 1 to 5 Million Naira per day which can be withdrawn in tranches of 1 million Naira per withdrawal.
        <Spacer height={20} />
        Nevertheless, depending on the nature of your business and authenticity of the merchant, this limit can be increased further.
      </div>
    ),
  },
  {
    title: 'How do I transfer to another VPay account?',
    content: (
      <div className="fw400 body-details font-size-15">
        To transfer funds to another VPay account, use the ‘Send’ option found on the Home screen. Select VFD Microfinance Bank as the beneficiary bank and then provide the beneficiary's VPay account number. The beneficiary name should pop up and then you may complete your transaction.
      </div>
    ),
  },
  {
    title: 'Can I pay for my electricity/cable TV bills using VPay platform?',
    content: (
      <div className="fw400 body-details font-size-15">
        Yes, you can pay for your electricity bills by clicking on the ‘Pay Bills’ option. Choose ‘Utility’ if you are paying for electricity, otherwise choose ‘Cable’ to pay for your Cable TV subscriptions.
      </div>
    ),
  },
  {
    title: 'How do I share my transaction receipt?',
    content: (
      <div className="fw400 body-details font-size-15">
        To share your transaction receipt:
        <ul>
          <li className="font-size-15 fw400 text-color">Click on the History menu</li>
          <li className="font-size-15 fw400 text-color">Locate the transaction you want to share the receipt for and click on it.</li>
          <li className="font-size-15 fw400 text-color">From the Transaction Details page, click on the ‘Share Receipt’ button below the page to share your receipts.</li>
        </ul>
        <br />
        Note that receipts are only available for outbound transactions like Transfers, Electricity/Cable TV or Airtime transactions. <strong>Print Receipt</strong> option (located beside the ‘Share
        Receipt’ option) is used to print out receipts using a Bluetooth Printer.
      </div>
    ),
  },
  {
    title: 'How do I change my password?',
    content: (
      <div className="fw400 body-details font-size-15">
        <ul>
          <li className="font-size-15 fw400 text-color">
            Click on the Profile menu (located at the top right of the Home screen for mobile app. This is located on the settings page for the web app - www.vpay.africa)
          </li>
          <li className="font-size-15 fw400 text-color">Click on "Change Password", to change your existing password.</li>
          <li className="font-size-15 fw400 text-color">Enter a new password and confirm.</li>
        </ul>
        <br />
        <strong>NB:</strong> Ensure your password follows the strong password policy which entails that your password must have a capital letter, small letter, number, special characters like $,@,&,%,# and must be at least up to 8 before the system can accept it.
      </div>
    ),
  },
  {
    title: 'How can a customer use the VPay QR code to make payment?',
    content: (
      <div className="fw400 body-details font-size-15">
        To display your unique QR code, click on the profile icon on the top left on the Home screen. A customer who has the NQR code feature integrated on their bank app can then scan your QR code. The customer will be able to automatically see your business name and can enter the amount and make payment seamlessly. NQR is available on the VBank app as well as some other banks.
      </div>
    ),
  },
  {
    title: 'What is my transfer limit?',
    content: (
      <div className="fw400 body-details font-size-15">
        Your transfer limit for partially verified Merchants is 30,000 Naira. By partially verified we mean accounts that have provided at least BVN and a liveliness video. This amount is not a daily limit.
        It’s a total transaction limit which is a total of all your transactions on the app like transfers, withdrawals and billing transactions. Once it totals 30,000 the restrictions will set in until you are fully verified. Refer to how you can upgrade your account above to remove the limit.
      </div>
    ),
  },
  {
    title: 'I made an outward transfer but I was debited twice.',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly reach out to any of our support channels with a screenshot of the transaction as well as your business name, email address and account number.
      </div>
    ),
  },
  {
    title: "Why does it say 'Account Number Does Not Exist' or 'Account or Beneficiary Not Found' when people try to send money to my VPay account number?",
    content: (
      <div className="fw400 body-details font-size-15">
        This is because you are yet to verify or upgrade your account. To verify or upgrade your account, kindly click on '<strong>Upload KYC Documents</strong>' on your app Home screen, then upload the required compliance documents.
        You will be required to provide:
        <Spacer height={20} />
        (a) Valid ID Card (either National ID Card, Voters Card, International Passport and Driving Licence) <br />
        (b) Bank Verification Number (BVN) <br />
        (c) A Utility Bill, not more than 1 months old. We accept POS Print out for Prepaid meter electricity bill showing your Address.
      </div>
    ),
  },
  {
    title: 'Is VPay down? I am trying to open my app but it is not opening.',
    content: <div className="fw400 body-details font-size-15">Kindly delete and re-download the app.</div>,
  },
  {
    title: 'I made a transfer and it says pending, what does this mean?',
    content: <div className="fw400 body-details font-size-15">Kindly send us the details of the transaction as well as your business name, email address and account number.</div>,
  },
  {
    title: 'I have verified my account before, why is it still telling me that I have exceeded my limit?',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly contact any of our support channels with your business name, email address and account number for further assistance.
      </div>
    ),
  },
  {
    title: 'Why is the system telling me I have exceeded my limit when I have not made any transaction?',
    content: (
      <div className="fw400 body-details font-size-15">
        Ensure that you have submitted all the required KYC documents and contact any of our support channels with your business name, account number and email address. If you have done so, kindly reach out to any of our support channels with your business name, email address and account number for further assistance.
      </div>
    ),
  },
  {
    title: 'I want to add another business to my profile. How do I go about it?',
    content: (
      <div className="fw400 body-details font-size-15">
        Click on the ‘Settings’ icon at the top right hand of your screen, then click on human icon at the top of the screen. Click on ‘Add Merchant’ at the bottom of the screen.
      </div>
    ),
  },
  {
    title: 'I forgot my password, how can I access my account?',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly click on forgot password, a new password will be sent to your registered email address. You may copy the password and paste it in the login page then proceed to change the password to your desired password from inside the app.
      </div>
    ),
  },
  {
    title: 'I sent money to someone, I have been debited but the person has not received the money.',
    content: (
      <div className="fw400 body-details font-size-15">
        Kindly reach out to any of our support channels with the details of the transaction and the exact beneficiary account number that payment was made to.
      </div>
    ),
  },
  {
    title: 'How can I get my statement of account?',
    content: (
      <div className="fw400 body-details font-size-15">
        To download your statement of account, click on History, click on the funnel icon, click on statement period, input the duration for which you want the statement of account then click on confirm. A statement of account will be sent to your registered email.
      </div>
    ),
  },
  {
    title: 'I can’t see where to upload my KYC documents on the app.',
    content: <div className="fw400 body-details font-size-15">Kindly update your app to the latest version and retry. Alternatively, you can send the documents to our support email (support@vpay.africa).</div>,
  },
  {
    title: 'I can’t open my app after updating,  It is not loading and is stuck on the RED page with VPpay in the middle.',
    content: <div className="fw400 body-details font-size-15">Kindly delete the app and re-download it from the App store.</div>,
  },
  {
    title: 'Why am I not getting SMS notifications/alerts?',
    content: <div className="fw400 body-details font-size-15">VPay inbound transaction notifications come ONLY as in-app and email notifications. SMS is not supported.</div>,
  },
  {
    title: 'What are the VPay customer support platforms?',
    content: <div className="fw400 body-details font-size-15">You may reach us on Whatsapp +234 802 810 8892, Call 02 01 6339125, Email: support@vpay.africa, Instagram @myvpay.africa or chat with us live at www.vpay.africa</div>,
  },
];
