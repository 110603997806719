import ImageBackground from '../image_background/image_background';
import './circle_avatar.css';

export default function CircleAvatar({ height, backgroundColor, width, img, size, children, className }) {
  return (
    <ImageBackground style={{ height: size || height, width: size || width, backgroundColor, backgroundSize: 'contain' }} img={img} className={(className || '') + ' circle-avatar'}>
      {children}
    </ImageBackground>
  );
}
