import { useCallback, useState } from 'react';
import { modifyPhoneNumber } from '../utils/formatNumber';

export const useInput = (val, type, error, setError, isNumber) => {
  const [value, setValue] = useState(val);
  const onChange = useCallback((e) => {
    const {
      target: { value, name },
    } = e;
    let newValue = value;
    if (isNumber) {
      if (isNaN(Number(newValue))) return;
    }
    if (type === 'tel') {
      newValue = modifyPhoneNumber(value);
    }
    setValue(newValue);
    error && setError((error) => ({ ...error, [name]: '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = () => setValue('');

  return {
    type: type || 'text',
    value,
    onChange,
    error,
    reset: reset || '',
  };
};
