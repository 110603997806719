import { createSlice } from '@reduxjs/toolkit';

const customInbound = createSlice({
  name: 'customInbound',
  initialState: {
    customer_name: '',
    amount: 0,
    short_desc: '',
    tnx_date: '',
    product: '',
    quantity: 0,
    payment_type: '',
    confirmCustomInbound: false,
    showProductPopup: false,
  },
  reducers: {
    resetCustomInbound: (state) => {
      state.customer_name = '';
      state.amount = '';
      state.short_desc = '';
      state.tnx_date = '';
      state.product = '';
      state.quantity = '';
      state.payment_type = '';
    },
    setConfirmCustomInbound: (state) => {
      state.confirmCustomInbound = true;
    },
    removeConfirmCustomInbound: (state) => {
      state.confirmCustomInbound = false;
    },
    setAllInfo: (state, { payload }) => {
      state.customer_name = payload.customer_name;
      state.amount = payload.amount;
      state.short_desc = payload.description;
      state.tnx_date = payload.date;
      state.product = payload.product;
      state.payment_type = payload.paymentMethod;
      state.quantity = payload.count;
    },
    setProdctPopup: (state, { payload }) => {
      state.showProdctPopup = payload;
    },
  },
});

export const { setConfirmCustomInbound, setAllInfo, resetCustomInbound, removeConfirmCustomInbound, setProdctPopup } = customInbound.actions;
const customInboundReducer = customInbound.reducer;
export default customInboundReducer;
