import { Spacer } from '../layout/layout';
import { FEATURES } from './feature';

import full_dasboard_with_iPhone from '../../assets/images/full-dasboard-with-iPhone.png';

import './merchant_features.css';

const MerchantFeatures = () => {
  return (
    <div className="merchant-features-background full-width full-height container head-padding-body d-flex flex-one flex-vertical j-center">
      <h1 className="no-margin text-color fw500 feature-title text-center">VPay Merchant App Features</h1>
      <Spacer height={35} />
      <div className="d-grid merchant-feature-grid-temp-col grid-col-gap50">
        <h1 className="no-margin text-color font-weight-500 mobile-feature-title text-center">VPay Merchant App Features</h1>
        <Features features={FEATURES} />
      </div>
      <Spacer height={80} />
      <div className="d-flex j-center flex-one">
        <img src={full_dasboard_with_iPhone} className="full-width" alt="vpay desktop app" />
      </div>
    </div>
  );
};

export const Features = ({ features }) => {
  return features.map((props, idx) => <MerchantFeature {...props} key={idx} />);
};

export const MerchantFeature = ({ image, title, content }) => (
  <div className="d-flex flex-vertical m-feature-align ">
    <img src={image} alt="send and receieve money" className="image-icon" />
    <Spacer height={20} />
    <p className="feature-text-font text-color fw400 text-bold-700 no-margin">{title}</p>
    <Spacer height={10} />
    <p className="feature-content-font text-color m-feature-text fw400 no-margin feature-max-width">{content}</p>
    <Spacer height={20} />
  </div>
);

export default MerchantFeatures;
