import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export function useSetPin() {
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const [shouldSetPin, setShouldSetPin] = useState(false);
  useEffect(() => {
    if (!merchantDetails.issetpin && !merchantDetails.pin) {
      setShouldSetPin(true);
    } else {
      setShouldSetPin(false);
    }
  }, [merchantDetails]);
  return { shouldSetPin, onSetPin: (e) => setShouldSetPin(e) };
}
