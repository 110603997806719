export const customInboundValidate = (values) => {
  const errors = {};
  if (!values.customer_name) {
    errors.customer_name = 'Customer Name Should Not Be Empty.';
  }
  if (!values.amount) {
    errors.amount = 'Amount Should Not Be Empty.';
  }
  if (!values.description) {
    errors.description = 'Description Should Not Be Empty.';
  }
  if (!values.date) {
    errors.date = 'Date Should Not Be Empty.';
  }
  if (!values.product) {
    errors.product = 'Product Should Not Be Empty.';
  }
  if (!values.count) {
    errors.count = 'Count Should Not Be Empty.';
  }
  if (!values.paymentMethod) {
    errors.paymentMethod = 'Payment Method Should Not Be Empty.';
  }
  return errors;
};

export const addProductValidate = (values) => {
  const errors = {};
  if (!values.categoryName) {
    errors.categoryName = 'Category Name Cannot Be Empty';
  }
  return errors;
};
