import Countdown from 'react-countdown';

export const Completionist = () => <span>Time Up!!!</span>;

export const TimeCountdown = ({ onStop }) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  return <Countdown date={Date.now() + 10000} onComplete={onStop} renderer={renderer} />;
};
