import { createContext, useContext, useState } from 'react';

const TransactionIdContext = createContext();

export const useTransactionId = () => useContext(TransactionIdContext);

export function TransactionIdProvider({ children }) {
  const [transactionId, setTransactionId] = useState('');

  const updateTransactionId = (id) => {
    setTransactionId(id);
  };

  return <TransactionIdContext.Provider value={{ transactionId, updateTransactionId }}>{children}</TransactionIdContext.Provider>;
}

export default TransactionIdContext;
