import { useMemo } from 'react';
import pdfIcon from '../../assets/images/pdf-icon.png';
import { Spacer } from '../layout/layout';
import { CircleAdd } from '../merchant_widgets/merchant_widgets';
import './image_upload.css';

export default function ImageUpload({ fileProps, className, src, clickable, imgText }) {
  return (
    <div className={`${className || ''} image-upload-area d-flex align-center`}>
      <CustomInputFile clickable={clickable} fileProps={fileProps} className="j-center">
        <img src={src} alt="user profile selector" />
      </CustomInputFile>
      <p className="no-margin text-bold primary-color img-text-upload">{imgText || 'Upload profile picture'}</p>
    </div>
  );
}

export const CustomInputFile = ({ className, fileProps, children, clickable, disabled, name }) => {
  const { src = null } = fileProps || {};
  // eslint-disable-next-line no-unused-vars

  const getSrc = (src) => {
    if (src && src.includes('image/')) {
      return src;
    } else if (src && src.includes('application/pdf')) {
      return pdfIcon;
    } else {
      return '';
    }
  };

  const memoizedSrc = useMemo(() => getSrc(src), [src]);
  const { uploadedDocs = [], setUploadedDocs, ...otherProps } = fileProps;
  return (
    <>
      <div className={`clickable-area d-flex p-relative align-center ${src ? 'uploaded-image-height' : ''} ${className || ''}`} style={{ backgroundImage: `url(${memoizedSrc})` }}>
        {!src && children}
        {clickable && <input type="file" accept="image/*, .pdf" disabled={disabled} name={name} className="custom-file-input p-absolute" {...otherProps} />}
        {/* <div className="p-absolute src-spinner">
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </div> */}
      </div>
      {src && <Spacer height={10} />}
      {/* {src && <AppButton className='upload-button primary-color fw500' name='Upload'  />} */}
    </>
  );
};

export const UploadExcelFile = ({ clickable, onChange }) => {
  return (
    <>
      <div className={`clickable-area d-flex p-relative align-center uploaded-image-height`}>
        {clickable && <input type="file" accept=".xlsx" className="custom-file-input p-absolute c-pointer" onChange={onChange} />}
      </div>
      <Spacer height={10} />
    </>
  );
};

export const DocumentTypeChooser = ({ text, onClick, svg }) => {
  return (
    <div onClick={onClick} className="clickable-area c-pointer d-flex p-relative align-center  full-width document-upload">
      <div className="d-flex align-center full-width" style={{ flexDirection: svg ? 'row-reverse' : 'row', justifyContent: !svg ? 'space-between' : 'flex-end', columnGap: svg ? '10px' : '0px' }}>
        <p className="text-color fw500">{text}</p>
        {svg ?? <CircleAdd />}
      </div>
    </div>
  );
};
