import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { sendCrashReportService } from '../apiSevices';

export const SEND_CRASH_REPORT_QUERY_KEY = 'send_crash_report';

export const useSendCrashReportMutation = () => {
  const request = useCallback(async (data) => {
    const response = await sendCrashReportService(data);
    return response.data;
  }, []);

  return useMutation({
    mutationFn: request,
    mutationKey: SEND_CRASH_REPORT_QUERY_KEY,
  });
};
