import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadDataPlansFor, loadService } from '../redux/merchant/bill.features';
import { loadTransactions } from '../redux/merchant/cashier.transactions';
import { loadCablePlans, loadUtilityPlans, resetUser } from '../redux/merchant/utility.features';

export default function useBillService(dispatch, providers) {
  useEffect(() => {
    if (!providers || !providers.length) {
      dispatch(loadService());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useBillData(dispatch, network) {
  useEffect(() => {
    if (network) {
      dispatch(loadDataPlansFor(network));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);
}

export function useCableData(dispatch, cablePlans) {
  useEffect(() => {
    if (!cablePlans || !cablePlans.length) {
      dispatch(loadCablePlans());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useUtiltiyData(dispatch, utilityPlans) {
  useEffect(() => {
    if (!utilityPlans || !utilityPlans.length) {
      dispatch(loadUtilityPlans());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useCashierTransaction(dispatch) {
  const { cashpointid } = useSelector((state) => state.user.user) || {};
  useEffect(() => {
    dispatch(loadTransactions(cashpointid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashpointid]);
}

export const useResetUtilUser = (dispatch, type) => {
  useEffect(() => {
    dispatch(resetUser(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
