import { createSlice } from '@reduxjs/toolkit';
import { aggregatorDownline, bonusBalance, bonusTransactionsService } from '../../services/apiSevices';

const bonusFeatureSlice = createSlice({
  name: 'bonusFeature',
  initialState: {
    bonusBalance: {},
    aggregatorDownline: {},
    bonusTransactions: [],
    loading: false,
    error: null,
  },
  reducers: {
    setBonusBalance: (state, { payload }) => {
      state.bonusBalance = payload;
    },
    setAggregatorDownlines: (state, { payload }) => {
      state.aggregatorDownline = payload;
    },
    setBonusTransactions: (state, { payload }) => {
      state.bonusTransactions = payload;
    },
    bonusLoading: (state, { payload }) => {
      state.loading = payload;
    },
    bonusError: (state, { payload }) => {
      state.error = payload;
    },
  },
});
const bonusFeatureReducer = bonusFeatureSlice.reducer;

export const { setBonusBalance, setAggregatorDownlines, setBonusTransactions, bonusLoading, bonusError } = bonusFeatureSlice.actions;
const apply =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(bonusLoading(true));
    try {
      const { data } = await remote();

      if (data) {
        dispatch(action(data));
        dispatch(bonusLoading(false));
      } else {
        dispatch(bonusError(data?.message));
        dispatch(bonusLoading(false));
      }
    } catch (err) {
      console.log({ err });
      dispatch(bonusError(err.response?.data?.message));
      dispatch(bonusLoading(false));
    }
  };

export const fetchBonusBalance = (type, key) => async (dispatch) => {
  await apply({ remote: () => bonusBalance(type, key), action: setBonusBalance })(dispatch);
};

export const fetchAggregatorDownlines = (key) => async (dispatch) => {
  await apply({ remote: () => aggregatorDownline(key), action: setAggregatorDownlines })(dispatch);
};

export const fetchBonusTransactions = (key, pageNumber, paymentType) => async (dispatch) => {
  await apply({ remote: () => bonusTransactionsService(key, pageNumber, paymentType), action: setBonusTransactions })(dispatch);
};

export const bonusFeatureSelector = (state) => state.bonusFeature;
export default bonusFeatureReducer;
